import React, { useCallback, useEffect, useRef, useState } from "react";
import { Hidden } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { Button, Icon } from "@components";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setActiveTab } from "@store/header/HeaderActions";
import Avatar from "@components/Avatar";
import { createChat } from "@store/chat/ChatActions";
import {
  createMessage,
  getUserMessages,
  seenAllMessages,
} from "@store/message/MessageActions";
import Loader from "@components/Loader";
import { getLastTimeDuration } from "@helpers/timeDateUtils";

function Messenger() {
  const { t } = useTranslation();
  const [newMessage, setNewMessage] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const limit = 50;
  const userId = localStorage.getItem("userId");

  const userName = useSelector(
    ({ EnglishCafe }) => EnglishCafe.chat.chatUserName
  );
  const userImage = useSelector(
    ({ EnglishCafe }) => EnglishCafe.chat.chatUserImage
  );
  const recieverId = useSelector(
    ({ EnglishCafe }) => EnglishCafe.chat.chatRecieverId
  );
  const userChatId = useSelector(({ EnglishCafe }) => EnglishCafe.chat.chatId);

  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const totalPages = useSelector(
    ({ EnglishCafe }) => EnglishCafe.message.totalPages
  );
  const userMessages = useSelector(
    ({ EnglishCafe }) => EnglishCafe.message.messages
  );
  const messagesLoading = useSelector(
    ({ EnglishCafe }) => EnglishCafe.message.loading
  );

  useEffect(() => {
    setNewMessage("");
    setCurrentPageNo(1);
    let objDiv = document.getElementById("msgsContainer");
    objDiv.scrollTop = objDiv.scrollHeight;
  }, [userChatId, recieverId]);

  useEffect(() => {
    if (userChatId) {
      dispatch(getUserMessages({ chatId: userChatId, page: 1, limit }));
    } else {
      dispatch(getUserMessages({ recieverId, page: 1, limit }));
    }
  }, [dispatch, userChatId, recieverId]);

  const sendMessageHandler = () => {
    if (newMessage !== "") {
      if (userChatId) {
        dispatch(createMessage(userChatId, recieverId, newMessage));
      } else {
        const recieverUserType = "Teacher";
        dispatch(createChat(recieverId, recieverUserType, newMessage));
      }
      setNewMessage("");
      setCurrentPageNo(1);
      setHasMore(true);
      let objDiv = document.getElementById("msgsContainer");
      objDiv.scrollTop = objDiv.scrollHeight;
    }
  };

  useEffect(() => {
    if (userChatId) {
      dispatch(seenAllMessages(userId, recieverId, userChatId));
    }
  }, [dispatch, userId, recieverId, userChatId]);

  useEffect(() => {
    if (currentPageNo >= totalPages) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
  }, [currentPageNo, totalPages]);

  const observer = useRef();

  const lastMessageElementRef = useCallback(
    (node) => {
      if (messagesLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          if (userChatId) {
            dispatch(
              getUserMessages({
                chatId: userChatId,
                page: currentPageNo + 1,
                limit,
              })
            );
          } else {
            dispatch(
              getUserMessages({ recieverId, page: currentPageNo + 1, limit })
            );
          }
          setCurrentPageNo(currentPageNo + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore, messagesLoading, currentPageNo, dispatch, recieverId, userChatId]
  );

  return (
    <>
      <Loader loading={messagesLoading} />
      <div className="relative z-40 w-full  bg-profileCard-gray flex flex-col  md:rounded-3xl md:ml-6 overflow-hidden justify-between">
        <Hidden only={["md", "lg", "xl"]}>
          <Icon
            classNames="absolute left-4 top-4 w-5 z-10"
            type="backArrow"
            onClick={() => {
              dispatch(setActiveTab("Messages"));
              if (localStorage.getItem("userType") === "Teacher") {
                history.push("/teacherProfile");
              } else {
                history.push("/studentProfile");
              }
            }}
          />
        </Hidden>
        <div className="absolute flex items-center justify-center w-full">
          <div className="flex justify-center self-center bg-white shadow-xl p-2 rounded-b-lg">
            <Avatar
              uri={userImage}
              name={userName}
              textSize="text-md"
              classNames="w-6 h-6"
            />
            <p className="text-center ml-2">{userName && userName}</p>
          </div>
        </div>
        <div
          id="msgsContainer"
          className="flex flex-col-reverse overflow-y-scroll hideScrollbar py-6 mt-3 px-4 md:px-16 mr-1"
        >
          {userMessages?.length > 0 &&
            userMessages.map((msg, index) => {
              return (
                <div
                  className={`${
                    msg.senderId === userId ? "self-end" : "self-start"
                  } w-60 mt-2`}
                  ref={
                    userMessages.length === index + 1
                      ? lastMessageElementRef
                      : null
                  }
                  key={msg?._id}
                >
                  <p className="text-xs">
                    {getLastTimeDuration(msg.createdAt)}
                  </p>
                  <p className="bg-white rounded-lg shadow-md text-sm md:text-md p-3 mt-2">
                    {msg?.message}
                  </p>
                </div>
              );
            })}
        </div>
        <form
          className="flex  relative bg-white p-2 md:p-4 my-4 mx-4 rounded-full"
          onSubmit={(event) => {
            event.preventDefault();
            sendMessageHandler();
          }}
          style={{ boxShadow: "0px 2px 15px rgb(0 0 0 / 15%)" }}
        >
          <input
            name="message"
            id="message"
            onChange={(event) => {
              setNewMessage(event.target.value);
            }}
            value={newMessage}
            type="text"
            placeholder={t("profile_message_placeholder")}
            className="text-sm pl-2 md:pl-0 md:text-md w-full outline-none"
          />
          <Button
            classNames="ml-2 rounded-full"
            type="submit"
            color="secondary"
          >
            {t("general_submit")}
          </Button>
        </form>
      </div>
    </>
  );
}

export default Messenger;
