import React from "react";

function MultiCheckbox({ items, setItems, onChange }) {
  return (
    <div className="flex flex-wrap items-center justify-center border-checkbox-main px-2 pb-2 border-2">
      {items.map((e) => {
        return (
          <div
            className={`${
              e.value ? "bg-secondary-main text-white" : "bg-checkbox-main"
            } mr-2 mt-2 rounded-xl p-2 cursor-pointer`}
            key={e.label}
            onClick={() => {
              const newItems = items.map((item) => {
                if (e.label === item.label) {
                  return {
                    ...item,
                    value: !item.value,
                  };
                } else {
                  return item;
                }
              });
              setItems(newItems);
              onChange(newItems);
            }}
          >
            {e.label}{" "}
          </div>
        );
      })}
    </div>
  );
}

export default MultiCheckbox;
