import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";

import { Icon, Map } from "@components";
import {
  DayOfTheWeek,
  DayOfTheWeekSpanish,
} from "@modules/TeacherProfile/helper/Data";
// import { getFormattedDate } from "@helpers/utils";

function MoreInformation({ lesson }) {
  const [weekDay, setWeekDay] = useState();

  const currentLanguageCode = cookies.get("i18next") || "en";

  const { dayOfTheWeek: day } = lesson;

  useEffect(() => {
    let index = DayOfTheWeek.findIndex((d) => d.value === day);
    if (index === -1) {
      index = DayOfTheWeekSpanish.findIndex((d) => d.value === day);
    }
    if (index >= 0) {
      if (currentLanguageCode === "es") {
        setWeekDay(DayOfTheWeekSpanish[index].value);
      } else {
        setWeekDay(DayOfTheWeek[index].value);
      }
    }
  }, [currentLanguageCode, day]);

  const { t } = useTranslation();
  return (
    <div className="flex flex-col">
      <p className="font-bold text-xl">{t("lesson_detail_more_information")}</p>
      {((lesson?.dates && lesson?.dates?.length > 0) ||
        lesson?.dayOfTheWeek) && (
        <div className="flex items-center mt-2">
          <Icon type="calendarIcon2" classNames="w-6 h-6 mr-2" />
          <p>
            {t("lesson_detail_schedule") + ": "}
            {lesson?.dayOfTheWeek ? (
              <span className="bg-black rounded-xl px-2 py-1 text-white ml-1 text-sm">
                {weekDay}
              </span>
            ) : (
              lesson?.dates.map((date) => {
                return (
                  <span className="bg-black rounded-xl px-2 py-1 text-white ml-1 text-sm">
                    {date + " "}
                  </span>
                );
              })
            )}
          </p>
        </div>
      )}

      {/* {lesson?.dateRange?.startDate && lesson?.dateRange?.endDate && (
        <div className="flex items-center mt-2">
          <Icon type="calendarIcon2" classNames="w-6 h-6 mr-2" />
          <p >
            {t("lesson_detail_schedule") + ": "}
            <span className="bg-black rounded-xl px-2 py-1 text-white ml-1 text-sm">
              {t("lesson_detail_start_date") +
                ": " +
                getFormattedDate(new Date(lesson?.dateRange?.startDate))}
            </span>
            <span className="bg-black rounded-xl px-2 py-1 text-white ml-1 text-sm">
              {t("lesson_detail_end_date") +
                ": " +
                getFormattedDate(new Date(lesson?.dateRange?.endDate))}
            </span>
          </p>
        </div>
      )} */}

      {lesson?.level && lesson?.level?.length > 0 && (
        <div className="flex items-center mt-2">
          <Icon type="levelIcon" classNames="w-6 h-6 mr-2" />
          <p>
            {t("lesson_detail_level") + ": "}
            {lesson?.level.map((level) => {
              return (
                <span className="bg-secondary-main rounded-xl px-2 py-1 text-white ml-1 text-sm">
                  {level + " "}
                </span>
              );
            })}
          </p>
        </div>
      )}
      {lesson?.startTime && (
        <div className="flex items-center mt-2">
          <Icon type="clockIcon" classNames="w-6 h-6 mr-2" />
          <p>{t("lesson_detail_start_time") + ": " + lesson.startTime}</p>
        </div>
      )}
      {lesson?.endTime && (
        <div className="flex items-center mt-2">
          <Icon type="clockIcon" classNames="w-6 h-6 mr-2" />
          <p>{t("lesson_detail_end_time") + ": " + lesson.endTime}</p>
        </div>
      )}
      {lesson?.duration && (
        <div className="flex items-center mt-2">
          <Icon type="clockIcon" classNames="w-6 h-6 mr-2" />
          <p>{t("lesson_detail_duration") + ": " + lesson.duration + "h"}</p>
        </div>
      )}
      {lesson?.classType === "face-to-face" && lesson?.city && (
        <div className="flex items-center mt-2">
          <Icon type="cityIcon" classNames="w-6 h-6 mr-2" />
          <p>{t("lesson_detail_city") + ": " + lesson.city}</p>
        </div>
      )}
      {lesson?.classType === "face-to-face" && lesson?.address && (
        <div className="flex items-center mt-2">
          <Icon type="addressIcon" classNames="w-6 h-6 mr-2" />
          <p>{t("lesson_detail_address") + ": " + lesson.address}</p>
        </div>
      )}
      {lesson?.classType === "face-to-face" && lesson?.coordinates?.latitude && (
        <div className="mt-4 w-full md:customLessonFormWidth h-40">
          <Map
            latitude={lesson?.coordinates?.latitude}
            longitude={lesson?.coordinates?.longitude}
            zoom={12}
            isMarkerShown={true}
            isDraggable={false}
          />
        </div>
      )}
    </div>
  );
}

export default MoreInformation;
