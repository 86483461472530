import React from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

import { Icon } from "@components";
import { numberWithCommas } from "@helpers/utils";

function TeacherCard({ classNames, teacher, lessonRef }) {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <div
      ref={lessonRef}
      className={`flex flex-col cursor-pointer ${classNames}`}
      onClick={() => {
        const teacherId = teacher?._id;
        history.push("/teacherPublicProfile/" + teacherId);
      }}
    >
      <div className="h-44 w-auto md:h-64">
        {teacher?.image ? (
          <img
            alt="lessonImage"
            className="rounded-xl w-full h-full object-cover"
            src={teacher?.image}
          />
        ) : (
          <div className="rounded-xl w-full h-full bg-secondary-main flex items-center justify-center">
            <p className="text-white font-medium text-6xl capitalize">
              {teacher?.name?.charAt(0)}
            </p>
          </div>
        )}
      </div>
      <div className="flex flex-col">
        {teacher?.reviews > 0 && (
          <div className="flex items-center mt-4">
            <div>
              <Icon type="star" classNames="rounded-xl" />
            </div>
            <p className="text-sm font-semibold pl-2">
              {teacher?.avgRating && parseFloat(teacher?.avgRating).toFixed(1)}{" "}
              ({numberWithCommas(teacher?.reviews) + " " + t("lessons_votes")})
            </p>
          </div>
        )}
        <p className="text-sm font-bold mt-2">
          {teacher?.name + " " + teacher?.surname}
        </p>
      </div>
    </div>
  );
}

export default TeacherCard;
