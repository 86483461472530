import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Loader, LessonList } from "@components";
import { getLessonsByTeacherId } from "@store/lesson/LessonActions";

function ViewLesson() {
  const dispatch = useDispatch();
  const lessonsData = useSelector(
    ({ EnglishCafe }) => EnglishCafe.lesson.lessons
  );
  const [tab, setTab] = useState("group");

  const totalPages = useSelector(
    ({ EnglishCafe }) => EnglishCafe.lesson.totalPages
  );
  const loading = useSelector(({ EnglishCafe }) => EnglishCafe.lesson.loading);
  const [hasMore, setHasMore] = useState(true);
  const [currentPageNo, setCurrentPageNo] = useState(1);

  useEffect(() => {
    if (currentPageNo >= totalPages) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
  }, [currentPageNo, totalPages]);

  const observer = useRef();

  const lastLessonElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setCurrentPageNo(currentPageNo + 1);
          dispatch(
            getLessonsByTeacherId(
              localStorage.getItem("userId"),
              currentPageNo + 1,
              10,
              tab
            )
          );
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore, loading, currentPageNo, dispatch, tab]
  );

  useEffect(() => {
    dispatch(getLessonsByTeacherId(localStorage.getItem("userId"), 1, 10, tab));
  }, [dispatch, tab]);

  const tabChangeHandler = (tab) => {
    setTab(tab);
    setCurrentPageNo(1);
  };

  return (
    <div className="flex  w-full  md:px-16 pt-4 md:pb-4">
      <Loader loading={loading}></Loader>

      <LessonList
        tab={tab}
        tabChange={tabChangeHandler}
        lastLessonElementRef={lastLessonElementRef}
        lessonsData={lessonsData}
        isAll={true}
      />
    </div>
  );
}

export default ViewLesson;
