import { Icon } from "@components";
import React from "react";

function WhatsappLink() {
  return (
    <div className="fixed bottom-6 right-6 z-50">
      <div
        className="h-10 w-10 px-1 py-1 md:h-14 md:w-14 md:px-1.5 md:py-1.5 rounded-full shadow-lg"
        style={{
          backgroundColor: "#25d366",
        }}
      >
        <a
          href="https://api.whatsapp.com/send?phone=34653630324&text="
          target="_blank"
          rel="noreferrer"
        >
          <Icon type="whatsappIcon" />
        </a>
      </div>
    </div>
  );
}

export default WhatsappLink;
