import { capitalizeFirstLetter } from "@helpers/utils";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

function FilterCheckboxes(props) {
  const { items, selected, onChange, languageHandler } = props;

  const { t } = useTranslation();

  return (
    <div className="flex flex-col space-y-1">
      {items?.map((item) => (
        <FormControlLabel
          key={item}
          control={
            <Checkbox
              checked={selected?.includes(item) ? true : false}
              onChange={() => onChange(item)}
              name="checkedB"
              color="primary"
            />
          }
          // label={
          //   item &&
          //   capitalizeFirstLetter(item) + " (" + item?.count + ")"
          // }
          label={languageHandler ? t(item) : capitalizeFirstLetter(item)}
        />
      ))}
    </div>
  );
}

export default FilterCheckboxes;
