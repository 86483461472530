import { logout } from "@store/auth/AuthActions";
import firebase from "config/firebase-config";
import S3 from "react-aws-s3";
import moment from "moment";
import ReactGA from "react-ga";

export const isVideo = (type) => {
  const mimeTypes = [
    "video/mp4",
    "video/x-flv",
    "video/MP2T",
    "	video/3gpp",
    "video/quicktime",
    "	video/x-msvideo",
    "	video/x-ms-wmv",
  ];
  return mimeTypes.includes(type);
};
export const isImage = (type) => {
  const mimeTypes = ["image/gif", "image/jpeg", "image/png"];
  return mimeTypes.includes(type);
};

export const checkImageExist = (imageUrl) => {
  let request = new XMLHttpRequest();

  request.open("HEAD", imageUrl, false);
  request.send();
  return request.status !== 404;
};

export const socialMediaAuth = (provider) => {
  return firebase
    .auth()
    .signInWithPopup(provider)
    .then((result) => {
      return result.user;
      // ...
    })
    .catch((error) => {
      return error;
    });
};

export const formatDate = (date) => {
  const formattedDate = new Date(date);
  const month = formattedDate.getFullYear();
  const year = formattedDate.getMonth() + 1;
  return month + "/" + year;
};

export const logoutUser = (history, dispatch) => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("userId");
  const userType = localStorage.getItem("userType");
  dispatch(logout());
  localStorage.removeItem("userType");
  history.push("/login?type=" + userType);
};

export const S3ImageUploader = (file, dirname, setLoadingFunction) => {
  const config = {
    bucketName: "englishcafe-production",
    dirName: dirname,
    region: "eu-west-3",
    accessKeyId: process.env.REACT_APP_AWS_AccessKeyId,
    secretAccessKey: process.env.REACT_APP_AWS_SecretAccessKey,
  };
  setLoadingFunction(true);
  const ReactS3Client = new S3(config);
  const filename = Date.now() + file.name;

  return new Promise((resolve, reject) => {
    return ReactS3Client.uploadFile(file, filename)
      .then((res) => {
        setLoadingFunction(false);
        return resolve(res.location);
      })
      .catch((error) => {
        setLoadingFunction(false);
        console.log("error", error);
        return reject("Something went Wrong!");
      });
  });
};

export const S3ImageDelete = (filename, dirname) => {
  const config = {
    bucketName: "englishcafe-production",
    dirName: dirname,
    region: "eu-west-3",
    accessKeyId: process.env.REACT_APP_AWS_AccessKeyId,
    secretAccessKey: process.env.REACT_APP_AWS_SecretAccessKey,
  };
  const ReactS3Client = new S3(config);
  ReactS3Client.deleteFile(filename)
    .then((response) => console.log(response))
    .catch((err) => console.error(err));
};

export const getFormattedDate = (current_datetime) => {
  let formatted_date =
    current_datetime.getDate() +
    "/" +
    (current_datetime.getMonth() + 1) +
    "/" +
    current_datetime.getFullYear();
  return formatted_date;
};

export const percentageValue = (percent = 80, value) => {
  return parseFloat((percent / 100) * value).toFixed(2);
};

export const getCardImage = (card) => {
  let cardImage;
  switch (card) {
    case "Visa": {
      cardImage = "visaCardIcon";
      break;
    }
    case "MasterCard": {
      cardImage = "masterCardIcon";
      break;
    }
    case "American Express": {
      cardImage = "americanExpressCardIcon";
      break;
    }
    default: {
      cardImage = "otherCard";
      break;
    }
  }

  return cardImage;
};

export const numberWithCommas = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const getYears = (startYear) => {
  let currentYear = new Date().getFullYear();
  const years = [];
  startYear = startYear || 1980;
  while (startYear <= currentYear) {
    let year = startYear++;
    years.push({ label: year, value: year });
  }
  return years;
};

export const youtube_parser = (url) => {
  const regExp =
    /^https?:\/\/(?:www\.youtube(?:-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|watch\?(?:.*&)?vi?=|&vi?=|\?(?:.*&)?vi?=)([^#&?\n/<>"']*)/i;
  const match = url.match(regExp);
  return match && match[1].length === 11 ? match[1] : false;
};

export const compareDateWithCurrentDate = (date) => {
  date = moment(date, "DD/MM/YYYY").toDate();
  const now = new Date();

  if (date > now) {
    return true;
  } else {
    return false;
  }
};

export const getNewLessonRenewalDate = (date) => {
  const firstRenewalDate = moment(date, "DD/MM/YYYY").add(1, "M").toDate();
  const monthsToAdd = moment(firstRenewalDate).diff(moment(), "months");
  return moment(date, "DD/MM/YYYY").add(monthsToAdd, "M").format("DD/MM/YYYY");
};

export const getClassDate = (day, date, monthToAdd) => {
  let resultDate = moment(date && date);
  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  let dayINeed = days.indexOf(day);

  if (dayINeed === -1) {
    const daysSpanish = [
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
      "Domingo",
    ];
    dayINeed = daysSpanish.indexOf(day);
  }

  dayINeed = dayINeed + 1;

  const today = moment(date && date).isoWeekday();
  resultDate = resultDate.isoWeekday(dayINeed);

  if (!(today <= dayINeed)) {
    resultDate = resultDate.add(1, "weeks");
  }
  if (monthToAdd) {
    resultDate.add(monthToAdd, "month");
  }

  return getFormattedDate(resultDate.toDate());
};

export const getGroupNextClassDate = (startDate, dayOfTheWeek, monthToAdd) => {
  const date =
    new Date(startDate)?.setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0)
      ? getClassDate(dayOfTheWeek, new Date(startDate), monthToAdd)
      : getClassDate(dayOfTheWeek, undefined, monthToAdd);

  return date;
};

export const pageAnalytics = (window) => {
  if (!window.location.href.includes("localhost")) {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
