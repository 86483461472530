import React from "react";

const Loader = ({ loading, className }) => {
  return (
    <>
      {loading && (
        <div
          className={`loaderOverlay top-0 left-0 fixed w-full h-full flex justify-center items-center z-50 ${className}`}
        >
          <div className="loader"></div>
        </div>
      )}
    </>
  );
};
export default Loader;
