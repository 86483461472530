import {
  getUserMessagesUrl,
  seenAllMessagesUrl,
  readOneMessageUrl,
  createMessageUrl,
} from "@api/Endpoint";
import { axiosInstance as axios } from "@api/axios";
import { MessageActionTypes } from "../redux/actionTypes";

export const getUserMessages = ({ chatId, recieverId, page, limit }) => {
  return (dispatch) => {
    dispatch({
      type: MessageActionTypes.GET_MESSAGES_START,
      payload: {
        currentPage: page,
      },
    });
    const url = getUserMessagesUrl(chatId, recieverId, page, limit);

    axios
      .get(url)
      .then((res) => {
        let { data } = res.data;
        if (data) {
          getUserMessagesSuccess(dispatch, data);
        } else {
          getUserMessagesFail(dispatch, "There was an error connection");
        }
      })
      .catch((error) => {
        getUserMessagesFail(dispatch, "There was an error connection2");
      });
  };
};
const getUserMessagesFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: MessageActionTypes.GET_MESSAGES_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const getUserMessagesSuccess = (dispatch, data) => {
  dispatch({
    type: MessageActionTypes.GET_MESSAGES_SUCCESS,
    payload: data,
  });
};
export const seenAllMessages = (userId, recieverId, chatId) => {
  return (dispatch) => {
    dispatch({
      type: MessageActionTypes.SEEN_ALL_MESSAGES_START,
    });
    const url = seenAllMessagesUrl();

    const req = {
      userId,
      recieverId,
      chatId,
    };
    axios
      .put(url, req)
      .then((res) => {
        let { data } = res;
        if (data) {
          seenAllMessagesSuccess(dispatch, data);
        } else {
          seenAllMessagesFail(dispatch, "There was an error connection");
        }
      })
      .catch((error) => {
        seenAllMessagesFail(dispatch, "There was an error connection2");
      });
  };
};
const seenAllMessagesFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: MessageActionTypes.SEEN_ALL_MESSAGES_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const seenAllMessagesSuccess = (dispatch, data) => {
  dispatch({
    type: MessageActionTypes.SEEN_ALL_MESSAGES_SUCCESS,
    payload: data,
  });
};

export const readOneMessage = (userId, recieverId, messageId) => {
  return (dispatch) => {
    dispatch({
      type: MessageActionTypes.READ_ONE_MESSAGE_START,
    });
    const url = readOneMessageUrl();

    const req = {
      userId,
      recieverId,
      messageId,
    };
    axios
      .put(url, req)
      .then((res) => {
        let { data } = res;
        if (data) {
          readOneMessageSuccess(dispatch, data);
        } else {
          readOneMessageFail(dispatch, "There was an error connection");
        }
      })
      .catch((error) => {
        readOneMessageFail(dispatch, "There was an error connection2");
      });
  };
};
const readOneMessageFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: MessageActionTypes.READ_ONE_MESSAGE_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const readOneMessageSuccess = (dispatch, data) => {
  dispatch({
    type: MessageActionTypes.READ_ONE_MESSAGE_SUCCESS,
    payload: data,
  });
};

export const createMessage = (chatId, recieverId, message) => {
  return (dispatch) => {
    dispatch({
      type: MessageActionTypes.CREATE_MESSAGE_START,
    });
    const request = {
      chatId,
      recieverId,
      message,
      userType: localStorage.getItem("userType"),
    };
    const url = createMessageUrl();
    axios
      .post(url, request)
      .then((res) => {
        let { data } = res.data;
        if (data.result) {
          createMessageSuccess(dispatch, data, recieverId);
        } else {
          createMessageFail(dispatch, "There was an error connection");
        }
      })
      .catch((error) => {
        console.log(error.message);
        createMessageFail(dispatch, "There was an error connection2");
      });
  };
};
const createMessageFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: MessageActionTypes.CREATE_MESSAGE_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const createMessageSuccess = (dispatch, data, recieverId) => {
  dispatch({
    type: MessageActionTypes.CREATE_MESSAGE_SUCCESS,
    payload: data,
  });
  dispatch(
    getUserMessages({
      chatId: data.result.chatId,
      recieverId,
      page: 1,
      limit: 50,
    })
  );
};
