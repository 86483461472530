import React from "react";
import { Hidden } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Icon from "./Icon";
import { HeaderContainer } from "@components";
import Loader from "./Loader";

function AuthPageContainer({ children, userType, isLogin }) {
  const { t } = useTranslation();
  const { loading } = useSelector(({ EnglishCafe }) => EnglishCafe.auth);
  return (
    <>
      <Loader loading={loading} />
      <HeaderContainer />
      <div className="flex flex-col justify-between">
        <div className="flex justify-around md:py-10 my-10">
          <div className="flex flex-col bg-card-main mx-4 rounded-xl py-6 px-12 md:w-3/12 md:px-12 md:py-10">
            {children}
          </div>
          <Hidden smDown>
            {userType === "Teacher" ? (
              <div className="self-center w-4/12">
                <Icon type="teacherAuthIcon" />
              </div>
            ) : (
              <div className="self-center w-4/12">
                <Icon type="studentAuthIcon" />
              </div>
            )}
          </Hidden>
        </div>
        <Hidden smDown>
          <div className="w-full">
            <p className="text-sm text-center">{t("footer_rights_reserved")}</p>
          </div>
        </Hidden>
      </div>
    </>
  );
}

export default AuthPageContainer;
