import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Hidden } from "@material-ui/core";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

import {
  Input,
  Button,
  BackButton,
  AuthPageContainer,
  Footer,
  SocialAuth,
  WhatsappLink,
} from "@components";
import { useForm } from "@customeHooks";
import {
  VALIDATOR_REQUIRE,
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH,
  VALIDATOR_NONE,
} from "@helpers/validators";
import TermAndConditons from "./components/TermsAndConditions";
import { submitUserSignup } from "@store/auth/AuthActions";
import { pageAnalytics } from "@helpers/utils";

function Signup(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [confirmPassValid, setConfirmPassValid] = useState(false);

  const params = Object.fromEntries(
    new URLSearchParams(props?.location?.search)
  );
  const { type: userType } = params;

  const [checked, setChecked] = useState(false);
  const [formState, inputHandler] = useForm(
    {
      firstName: {
        value: "",
        isValid: false,
      },
      lastName: {
        value: "",
        isValid: false,
      },
      email: {
        value: "",
        isValid: false,
      },
      password: {
        value: "",
        isValid: false,
      },
      confirmPassword: {
        value: "",
        isValid: false,
      },
    },
    false
  );
  const password = formState.inputs.password.value;
  const confirmPassword = formState.inputs.confirmPassword.value;
  useEffect(() => {
    if (password === confirmPassword) {
      setConfirmPassValid(true);
    } else {
      setConfirmPassValid(false);
    }
  }, [password, confirmPassword]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    pageAnalytics(window);
  }, []);

  return (
    <>
      <AuthPageContainer userType={userType}>
        <div className="flex w-full items-center">
          <BackButton classNames="md:-ml-8" />
          <p
            className="self-center font-bold text-xl"
            style={{ margin: "0 auto" }}
          >
            {userType === "Teacher"
              ? t("general_teacher")
              : t("general_student")}
            {" " + t("general_signup")}
          </p>
        </div>
        <div className="flex w-full items-center mt-2 md:mt-4">
          <p className="ml-2 font-thin text-sm">
            {userType === "Teacher"
              ? t("auth_signup_title_teacher")
              : t("auth_signup_title_student")}
          </p>
        </div>

        <SocialAuth userType={userType} />

        <div className="flex items-center md:mt-2">
          <div
            style={{
              borderBottom: "1px solid #000000",
              opacity: "14%",
              flex: "0.5",
            }}
          ></div>
          <span style={{ margin: "0px 4px", color: "#dddcdc" }}>
            {t("auth_or")}
          </span>
          <div
            style={{
              borderBottom: "1px solid #000000",
              opacity: "14%",
              flex: "0.5",
            }}
          ></div>
        </div>
        <form
          className="flex flex-col"
          onSubmit={(event) => {
            event.preventDefault();
            dispatch(
              submitUserSignup(
                {
                  name: formState.inputs.firstName.value,
                  surname: formState.inputs.lastName.value,
                  email: formState.inputs.email.value,
                  password: formState.inputs.confirmPassword.value,
                },
                history,
                userType
              )
            );
          }}
          autoComplete="off"
        >
          <Input
            id="firstName"
            label={t("auth_first_name_label")}
            placeholder="Micheal"
            onInput={inputHandler}
            validators={[VALIDATOR_REQUIRE()]}
            classNames="w-full mt-4 md:mt-6 bg-white"
            size="small"
          />
          <Input
            id="lastName"
            label={t("auth_last_name_label")}
            placeholder="Brown"
            onInput={inputHandler}
            validators={[VALIDATOR_REQUIRE()]}
            classNames="w-full mt-4 md:mt-6 bg-white"
            size="small"
          />
          <Input
            id="email"
            label={t("auth_email_label")}
            placeholder="user@gmail.com"
            variant="outlined"
            onInput={inputHandler}
            validators={[VALIDATOR_EMAIL()]}
            helperText={t("auth_email_helper_text")}
            classNames="w-full mt-4 md:mt-6 bg-white"
            size="small"
          />
          <Input
            id="password"
            isPassword={true}
            label={t("auth_password_label")}
            onInput={inputHandler}
            validators={[VALIDATOR_MINLENGTH(6)]}
            helperText={t("auth_password_helper_text")}
            classNames="w-full mt-4 md:mt-6 bg-white"
            size="small"
          />
          <Input
            width="large"
            id="confirmPassword"
            isPassword={true}
            label={t("auth_confirm_password_label")}
            onInput={inputHandler}
            initialValid={true}
            validators={[VALIDATOR_NONE()]}
            confirmPassValid={confirmPassValid}
            helperText={t("auth_confirm_password_helper_text")}
            classNames="w-full mt-4 md:mt-6 bg-white"
            size="small"
          />
          <div className="flex md:mt-2">
            <TermAndConditons checked={checked} setChecked={setChecked} />
          </div>
          <div className="flex justify-center md:mt-2">
            <Button
              classNames="rounded-xl mb-2"
              isDisable={!formState.isValid || !checked || !confirmPassValid}
              type="submit"
            >
              {t("general_signup")}
            </Button>
          </div>
          <p className="text-sm text-center md:mt-2">
            {t("auth_already_registered") + " "}
            <span
              onClick={() => {
                history.push("/login?type=" + userType);
              }}
              className="underline cursor-pointer"
            >
              {t("general_login")}
            </span>
          </p>
        </form>
      </AuthPageContainer>
      <WhatsappLink />
      <Hidden only={["md", "lg", "xl"]}>
        <Footer />
      </Hidden>
    </>
  );
}

export default Signup;
