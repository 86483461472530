import { LessonActionTypes } from "../redux/actionTypes";
import moment from "moment";

const INITIAL_STATE: LessonState = {
  lessons: [],
  filters: {},
  loading: false,
  totalPages: 0,
  currentPage: 0,
  childrenOnetoOneLessons: [],
  experienceLessons: [],
  topLessons: [],
  lesson: {},
  filterActive: false,
  Lessonfilter: {},
  filterValues: {
    category: "group",
    language: "",
    level: "",
    type: "",
    city: "",
  },
  isFilterVisible: true,
  subscribers: [],
  teachers: [],
  openSuccessModal: false,
  topTeachers: [],
  topTeachersLoading: false,
  lessonCalendarDates: [],
  lessonCalendarDatesLoading: false,
};
interface Action {
  payload: any;
  type: string;
}
const LessonReducer = (
  state: LessonState = INITIAL_STATE,
  action: Action
): LessonState => {
  switch (action.type) {
    case LessonActionTypes.GET_LESSONS_START: {
      if (action.payload.currentPage === 1) {
        state.lessons = [];
        state.totalPages = 0;
        state.currentPage = 0;
        return { ...state, filters: {}, loading: true };
      } else {
        return { ...state, loading: true };
      }
    }
    case LessonActionTypes.GET_LESSONS_SUCCESS: {
      if (action.payload.currentPage === 1) {
        state.lessons = [];
        return {
          ...state,
          lessons: action.payload.lessons,
          totalPages: action.payload.totalPages,
          currentPage: action.payload.currentPage,
          loading: false,
          filters: action.payload.filters,
        };
      } else {
        return {
          ...state,
          lessons: [...state.lessons, ...action.payload.lessons],
          totalPages: action.payload.totalPages,
          currentPage: action.payload.currentPage,
          loading: false,
          filters: action.payload.filters,
        };
      }
    }
    case LessonActionTypes.GET_LESSONS_FAIL: {
      return { ...state, loading: false };
    }

    case LessonActionTypes.GET_HOME_SCREEN_LESSONS_START: {
      state.childrenOnetoOneLessons = [];
      state.experienceLessons = [];
      state.topLessons = [];
      return { ...state, loading: true };
    }
    case LessonActionTypes.GET_HOME_SCREEN_LESSONS_SUCCESS: {
      state.childrenOnetoOneLessons = [];
      state.experienceLessons = [];
      state.topLessons = [];
      return {
        ...state,
        childrenOnetoOneLessons: action.payload.childrenOnetoOneLessons,
        experienceLessons: action.payload.experienceLessons,
        topLessons: action.payload.topLessons,
        loading: false,
      };
    }
    case LessonActionTypes.GET_HOME_SCREEN_LESSONS_FAIL: {
      return { ...state, loading: false };
    }

    case LessonActionTypes.GET_LESSON_BY_ID_START: {
      state.lesson = {};
      return { ...state, loading: true };
    }
    case LessonActionTypes.GET_LESSON_BY_ID_SUCCESS: {
      state.lesson = {};
      return {
        ...state,
        lesson: action.payload.lesson,
        loading: false,
      };
    }
    case LessonActionTypes.GET_LESSON_BY_ID_FAIL: {
      return { ...state, loading: false };
    }

    case LessonActionTypes.CREATE_LESSON_START: {
      return { ...state, loading: true };
    }
    case LessonActionTypes.CREATE_LESSON_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case LessonActionTypes.CREATE_LESSON_FAIL: {
      return { ...state, loading: false };
    }

    case LessonActionTypes.GET_LESSONS_BY_TEACHER_ID_START: {
      if (action.payload.currentPage === 1) {
        state.lessons = [];
        state.totalPages = 0;
        state.currentPage = 0;
        return { ...state, loading: true };
      } else {
        return { ...state, loading: true };
      }
    }
    case LessonActionTypes.GET_LESSONS_BY_TEACHER_ID_SUCCESS: {
      if (action.payload.currentPage === 1) {
        state.lessons = [];
        return {
          ...state,
          lessons: action.payload.lessons,
          totalPages: action.payload.totalPages,
          currentPage: action.payload.currentPage,
          loading: false,
        };
      } else {
        return {
          ...state,
          lessons: [...state.lessons, ...action.payload.lessons],
          totalPages: action.payload.totalPages,
          currentPage: action.payload.currentPage,
          loading: false,
        };
      }
    }
    case LessonActionTypes.GET_LESSONS_BY_TEACHER_ID_FAIL: {
      return { ...state, loading: false };
    }

    case LessonActionTypes.SHOW_HIDE_LESSON_START: {
      return { ...state, loading: true };
    }
    case LessonActionTypes.SHOW_HIDE_LESSON_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case LessonActionTypes.SHOW_HIDE_LESSON_FAIL: {
      return { ...state, loading: false };
    }

    case LessonActionTypes.DELETE_LESSON_START: {
      return { ...state, loading: true };
    }
    case LessonActionTypes.DELETE_LESSON_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case LessonActionTypes.DELETE_LESSON_FAIL: {
      return { ...state, loading: false };
    }

    case LessonActionTypes.UPDATE_LESSON_START: {
      return { ...state, loading: true };
    }
    case LessonActionTypes.UPDATE_LESSON_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case LessonActionTypes.UPDATE_LESSON_FAIL: {
      return { ...state, loading: false };
    }

    case LessonActionTypes.SET_LESSON_ID: {
      return { ...state, lessonId: action.payload };
    }

    case LessonActionTypes.SET_LESSON_TYPE: {
      return { ...state, lessonType: action.payload };
    }

    case LessonActionTypes.SET_FILTER_ACTIVE: {
      return { ...state, filterActive: action.payload };
    }

    case LessonActionTypes.SET_IS_FILTER_VISIBLE: {
      return { ...state, isFilterVisible: action.payload };
    }

    case LessonActionTypes.SET_FILTER: {
      return { ...state, Lessonfilter: action.payload };
    }

    case LessonActionTypes.SET_FILTER_VALUES: {
      return {
        ...state,
        filterValues: {
          category: action.payload.category
            ? action.payload.category
            : state.filterValues.category,
          language: action.payload.language
            ? action.payload.language
            : state.filterValues.language,
          level: action.payload.level
            ? action.payload.level
            : state.filterValues.level,
          type: action.payload.type
            ? action.payload.type
            : state.filterValues.type,
          city: action.payload.city
            ? action.payload.city === "emptyString"
              ? ""
              : action.payload.city
            : state.filterValues.city,
        },
      };
    }

    case LessonActionTypes.GET_SUBSCRIBERS_START: {
      return { ...state, subscribers: [], loading: true };
    }
    case LessonActionTypes.GET_SUBSCRIBERS_SUCCESS: {
      return {
        ...state,
        subscribers: action.payload.users,
        loading: false,
      };
    }
    case LessonActionTypes.GET_SUBSCRIBERS_FAIL: {
      return { ...state, loading: false };
    }

    case LessonActionTypes.ADD_REVIEW_START: {
      return { ...state, loading: true };
    }
    case LessonActionTypes.ADD_REVIEW_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case LessonActionTypes.ADD_REVIEW_FAIL: {
      return { ...state, loading: false };
    }

    case LessonActionTypes.GET_TEACHERS_BY_STUDENT_ID_START: {
      if (action.payload.currentPage === 1) {
        state.lessons = [];
        state.totalPages = 0;
        state.currentPage = 0;
        return { ...state, loading: true };
      } else {
        return { ...state, loading: true };
      }
    }
    case LessonActionTypes.GET_TEACHERS_BY_STUDENT_ID_SUCCESS: {
      if (action.payload.currentPage === 1) {
        state.teachers = [];
        return {
          ...state,
          teachers: action.payload.teachers,
          totalPages: action.payload.totalPages,
          currentPage: action.payload.currentPage,
          loading: false,
        };
      } else {
        return {
          ...state,
          teachers: [...state.teachers, ...action.payload.teachers],
          totalPages: action.payload.totalPages,
          currentPage: action.payload.currentPage,
          loading: false,
        };
      }
    }
    case LessonActionTypes.GET_TEACHERS_BY_STUDENT_ID_FAIL: {
      return { ...state, loading: false };
    }

    case LessonActionTypes.OPEN_SUCCESS_MODAL: {
      return { ...state, openSuccessModal: action.payload };
    }

    case LessonActionTypes.GET_TOP_TEACHERS_START: {
      state.topTeachers = [];
      return { ...state, topTeachersLoading: true };
    }
    case LessonActionTypes.GET_TOP_TEACHERS_SUCCESS: {
      return {
        ...state,
        topTeachers: action.payload.teachers,
        topTeachersLoading: false,
      };
    }
    case LessonActionTypes.GET_TOP_TEACHERS_FAIL: {
      return { ...state, topTeachersLoading: false };
    }

    case LessonActionTypes.GET_CALENDAR_DATES_START: {
      state.lessonCalendarDates = [];
      return { ...state, lessonCalendarDatesLoading: true };
    }
    case LessonActionTypes.GET_CALENDAR_DATES_SUCCESS: {
      const events: any[] = [];
      const days = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ];
      action.payload.lessons.forEach((lesson) => {
        const time = moment(lesson.startTime, "HH:mm");
        if (lesson.type === "group") {
          const { startDate } = lesson.dateRange;
          const { dayOfTheWeek: day } = lesson;
          let { endDate } = lesson.dateRange;

          endDate = moment(endDate)
            .set({
              hour: time.get("hour"),
              minute: time.get("minute"),
              second: time.get("second"),
            })
            .toDate();

          const dayINeed = days.indexOf(day) + 1;
          const startDateDay = moment(startDate).isoWeekday();
          let firstDate;
          let i = 1;

          if (startDateDay <= dayINeed) {
            firstDate = moment(startDate)
              .isoWeekday(dayINeed)
              .set({
                hour: time.get("hour"),
                minute: time.get("minute"),
                second: time.get("second"),
              })
              .toDate();
          } else {
            firstDate = moment(startDate)
              .add(i, "weeks")
              .isoWeekday(dayINeed)
              .set({
                hour: time.get("hour"),
                minute: time.get("minute"),
                second: time.get("second"),
              })
              .toDate();
            i++;
          }

          events.push({ title: lesson.title, start: firstDate });
          while (true) {
            const nextDate = moment(firstDate)
              .add(i, "weeks")
              .set({
                hour: time.get("hour"),
                minute: time.get("minute"),
                second: time.get("second"),
              })
              .toDate();
            if (nextDate > endDate) {
              break;
            }
            events.push({ title: lesson.title, start: nextDate });
            i++;
          }
        } else {
          lesson.dates.forEach((lessonDate) => {
            const date = moment(lessonDate, "DD/MM/YYYY")
              .set({
                hour: time.get("hour"),
                minute: time.get("minute"),
                second: time.get("second"),
              })
              .toDate();
            events.push({ title: lesson.title, start: date });
          });
        }
      });
      return {
        ...state,
        lessonCalendarDates: events,
        lessonCalendarDatesLoading: false,
      };
    }
    case LessonActionTypes.GET_CALENDAR_DATES_FAIL: {
      return { ...state, lessonCalendarDatesLoading: false };
    }

    default: {
      return state;
    }
  }
};
export default LessonReducer;
