import {
  VALIDATOR_IS_URL,
  VALIDATOR_NONE,
  VALIDATOR_REQUIRE,
} from "@helpers/validators";

export const TeacherInputs = [
  {
    id: "phoneNo",
    placeholder: "+123 456 789 0123",
    heading: "user_phone_no_heading",
    value: "",
    validators: [VALIDATOR_REQUIRE()],
    validity: false,
    required: true,
  },
  {
    id: "province",
    placeholder: "Barcelona",
    heading: "user_province_heading",
    value: "",
    validators: [VALIDATOR_REQUIRE()],
    validity: false,
    required: true,
  },
  {
    id: "nationality",
    placeholder: "Spain",
    heading: "user_nationality_placeholder",
    value: "",
    validators: [VALIDATOR_REQUIRE()],
    validity: false,
    required: true,
  },
  {
    id: "languages",
    placeholder: "user_languages_placeholder",
    heading: "user_teacher_languages_heading",
    value: "",
    validators: [VALIDATOR_REQUIRE()],
    validity: false,
    required: true,
  },
  {
    id: "biography",
    placeholder: "user_biography_placeholder",
    heading: "user_biography_heading",
    value: "",
    validators: [VALIDATOR_REQUIRE()],
    validity: false,
    required: true,
  },
  {
    id: "experiences",
    placeholder: "user_experience_placeholder",
    heading: "user_experience_heading",
    value: "",
    validators: [VALIDATOR_REQUIRE()],
    validity: false,
    required: true,
  },
  {
    id: "aboutClasses",
    placeholder: "user_about_classes_placeholder",
    heading: "user_about_classes_heading",
    value: "",
    validators: [VALIDATOR_REQUIRE()],
    validity: false,
    required: true,
  },
  {
    id: "videoLink",
    heading: "user_url_heading",
    placeholder: "user_url_placeholder",
    validators: [VALIDATOR_IS_URL(), VALIDATOR_NONE()],
    validity: true,
    errorMsg: "user_url_error_msg",
    value: "",
  },
  {
    id: "imageFile",
    heading: "user_image_heading",
    validity: true,
    value: "",
  },
];

export const StudentInputs = [
  {
    id: "phoneNo",
    placeholder: "+123 456 789 0123",
    heading: "user_phone_no_heading",
    value: "",
    validators: [VALIDATOR_REQUIRE()],
    validity: false,
    required: true,
  },
  {
    id: "province",
    placeholder: "Barcelona",
    heading: "user_province_heading",
    value: "",
    validators: [VALIDATOR_REQUIRE()],
    validity: false,
    required: true,
  },
  {
    id: "languages",
    placeholder: "user_languages_placeholder",
    heading: "user_student_languages_heading",
    value: "",
    validators: [VALIDATOR_REQUIRE()],
    validity: false,
    required: true,
  },
  {
    id: "profession",
    placeholder: "user_profession_placeholder",
    heading: "user_profession_heading",
    value: "",
    validators: [VALIDATOR_REQUIRE()],
    validity: false,
    required: true,
  },
  {
    id: "imageFile",
    heading: "user_image_heading",
    value: "",
  },
];

export const CustomLanguages = [
  {
    label: "Spanish",
    value: false,
  },
  {
    label: "English",
    value: false,
  },
  {
    label: "French",
    value: false,
  },
  {
    label: "German",
    value: false,
  },
  {
    label: "Dutch",
    value: false,
  },
  {
    label: "Finnish",
    value: false,
  },
  {
    label: "Swedish",
    value: false,
  },
  {
    label: "Australian",
    value: false,
  },
  {
    label: "Italian",
    value: false,
  },
  {
    label: "Tiwi",
    value: false,
  },
];
