import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

function Theme(props) {
  //will use later   const mainTheme = useSelector(({ Themechange }) => Themechange);
  let mainTheme = createMuiTheme({
    palette: {
      type: "light",
      primary: {
        main: "#000000",
      },
      secondary: {
        main: "#D9331F",
      },
      background: {
        default: "#fff",
      },
    },
    Button: {
      outlined: {
        "&:hover": {
          backgroundColor: "blue !important",
        },
      },
    },
    typography: {
      fontFamily: ["Circular Std", "-apple-system"].join(","),
    },
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: "1em",
        },
      },
    },
  });
  mainTheme = responsiveFontSizes(mainTheme);
  return <ThemeProvider theme={mainTheme}>{props.children}</ThemeProvider>;
}

export default Theme;
