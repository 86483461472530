import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Geocode from "react-geocode";

import {
  Footer,
  HeaderContainer,
  Loader,
  LessonsRow,
  WhatsappLink,
  // LessonCard,
} from "@components";
import FilteredLessons from "./components/FilteredLessons";
import TeachersRow from "./components/TeachersRow";
import {
  getHomeScreenLessons,
  getLessons,
  getTopTeachers,
  setFilterActive,
  setFilterValues,
} from "@store/lesson/LessonActions";
import { pageAnalytics } from "@helpers/utils";

Geocode.setApiKey(process.env.REACT_APP_MAP_API_KEY);
Geocode.setRegion("es");

function Lessons(props) {
  const { t } = useTranslation();
  const [addressLoading, setAddressLoading] = useState(false);
  const [filter, setFilter] = useState({});

  const params = Object.fromEntries(
    new URLSearchParams(props?.location?.search)
  );
  const { lessontype: category, language, type, city, level } = params;

  const filterActive = useSelector(
    ({ EnglishCafe }) => EnglishCafe.lesson.filterActive
  );

  useEffect(() => {
    pageAnalytics(window);
  }, []);

  const {
    topLessons,
    childrenOnetoOneLessons,
    experienceLessons,
    topTeachers,
    loading,
    topTeachersLoading,
  } = useSelector(({ EnglishCafe }) => EnglishCafe.lesson);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getHomeScreenLessons());
    dispatch(getTopTeachers());
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      try {
        let filterObj = {};
        let filterValuesObj = {};
        let flag = false;
        if (category && category !== "") {
          filterObj["category"] = category;
          filterValuesObj["category"] = category;
          flag = true;
        }
        //  else {
        //   filterObj["category"] = "group";
        //   filterValuesObj["category"] = "group";
        // }
        if (language && language !== "") {
          filterObj["language"] = language;
          filterValuesObj["language"] = language;
          flag = true;
        }
        if (level && level !== "") {
          filterValuesObj["level"] = level;
          if (level !== "All") {
            filterObj["levels"] = [level];
            flag = true;
          }
        }
        if (type && type !== "") {
          filterValuesObj["type"] = type;
          if (type !== "All") {
            filterObj["types"] = [type];
            flag = true;
          }
          if (type === "face-to-face" && city && city !== "") {
            flag = true;
            setAddressLoading(true);
            try {
              const response = await Geocode.fromAddress(city);
              const { lat, lng } = response.results[0].geometry.location;
              filterObj["city"] = { lat, lng };
              filterValuesObj["city"] = city;
            } catch (err) {
              filterObj["city"] = { lat: "", lng: "" };
              filterValuesObj["city"] = city;
              console.log("No city found!");
            }
            setAddressLoading(false);
          }
        }
        if (flag === true) {
          dispatch(getLessons(1, 10, filterObj));
          dispatch(setFilterActive(true));
          setFilter({ ...filterObj });
          dispatch(setFilterValues({ ...filterValuesObj }));
        } else {
          setFilter({});
          dispatch(setFilterActive(false));
          dispatch(
            setFilterValues({
              category: "",
              language: "",
              level: "",
              type: "",
              city: "",
            })
          );
          dispatch(getHomeScreenLessons());
          dispatch(getTopTeachers());
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [category, language, type, city, level, dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HeaderContainer />
      <div className="flex flex-col">
        <Loader loading={loading || addressLoading || topTeachersLoading} />
        <FilteredLessons
          filterActive={filterActive}
          filter={filter}
          setFilter={setFilter}
          setAddressLoading={setAddressLoading}
        />
        {!filterActive && (
          <div className="relative">
            <p className="mx-7 font-bold md:mx-36 mt-8 md:mt-10 md:text-xl">
              {t("lessons_top_lessons")}
            </p>
            <div className="mt-4 md:mt-8">
              <LessonsRow lessonsData={topLessons} />
            </div>
            <p className="mx-7 font-bold md:mx-36 mt-4 md:mt-24 md:text-xl">
              {t("lessons_top_teacher")}
            </p>
            <div className="mt-4 md:mt-8">
              <TeachersRow teachersData={topTeachers} />
            </div>
            <p className="mx-7 font-bold md:mx-36 mt-4 md:mt-24 md:text-xl">
              {t("lessons_news")}
            </p>
            <div className="mt-4 md:mt-8">
              <LessonsRow lessonsData={childrenOnetoOneLessons} />
            </div>
            <p className="mx-7 font-bold md:mx-36 mt-4 md:mt-24 md:text-xl">
              {t("lessons_next_experiences")}
            </p>
            <div className="mt-4 md:mt-8">
              <LessonsRow lessonsData={experienceLessons} />
            </div>
            {/* <p className="mx-7 font-bold md:mx-36 mt-4 md:mt-24 md:text-xl">
              {t("lessons_more_lessons")}
            </p>
            <div
              className="grid md:grid-cols-3 grid-cols-1 gap-2 px-4 md:px-40 mt-4 md:mt-8"
              style={{ margin: "0 auto" }}
            >
              {freeLessons?.length > 0 &&
                freeLessons.map((lesson, index) => {
                  return (
                    <LessonCard
                      key={lesson._id}
                      classNames="mt-4 md:mr-6 md:mt-10 md:mr-3"
                      lesson={lesson}
                    />
                  );
                })}
            </div> */}
          </div>
        )}
        <WhatsappLink />
        <div className="mt-10">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Lessons;
