import React from "react";
import { Dialog } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { Icon, Button } from "@components";

function ConfirmModal({
  onClose,
  open,
  headerText,
  mainText,
  onConfirm,
  notCancellable,
}) {
  const { t } = useTranslation();
  const handleClose = () => {
    onClose();
  };

  const handleConfirm = () => {
    onConfirm();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <div className="flex flex-col justify-center items-center px-8 py-6 md:w-96">
        <Icon
          type="closeIconBlack"
          classNames="self-end cursor-pointer -mr-3"
          onClick={handleClose}
        />
        <Icon type="confirmIcon" classNames="my-6" />
        <p className="text-xl font-medium mb-2 text-center">{headerText}</p>
        {mainText && <p className="text-sm mb-6 text-justify">{mainText}</p>}
        <div className="flex justify-center">
          <Button onClick={handleConfirm}>{t("confirm_modal_confirm")}</Button>
          {!notCancellable && (
            <Button color="secondary" classNames="ml-2" onClick={handleClose}>
              {t("confirm_modal_cancel")}
            </Button>
          )}
        </div>
      </div>
    </Dialog>
  );
}

export default ConfirmModal;
