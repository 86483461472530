import React, { useState, useReducer, useEffect } from "react";
import { InputAdornment, TextField } from "@material-ui/core";

import { validate } from "@helpers/validators";
import { Icon } from "@components";

const inputReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE": {
      return {
        ...state,
        value: action.val,
        isValid: validate(action.val, action.validators),
      };
    }
    case "TOUCH": {
      return {
        ...state,
        isTouched: true,
      };
    }
    default:
      return state;
  }
};

function Input(props) {
  const [inputState, dispatch] = useReducer(inputReducer, {
    value: props.initialValue || "",
    isTouched: false,
    isValid: props.initialValid || false,
  });
  const { value, isValid } = inputState;
  const { id, onInput } = props;
  useEffect(() => {
    onInput(id, value, isValid);
  }, [id, value, isValid, onInput]);

  const changeHandler = (event) => {
    dispatch({
      type: "CHANGE",
      val: event.target.value,
      validators: props.validators,
    });
  };

  const touchHandler = () => {
    dispatch({
      type: "TOUCH",
    });
  };

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  return (
    <>
      {props.isPassword ? (
        <TextField
          size={props.size ? props.size : "medium"}
          // color={props.color ? props.color : "primary"}
          type={showPassword ? "text" : "password"}
          label={props.label || "Password"}
          placeholder="**************"
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {showPassword ? (
                  <div className="w-8 cursor-pointer">
                    <Icon
                      type="visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    />
                  </div>
                ) : (
                  <div className="w-8 cursor-pointer">
                    <Icon
                      type="visibilityOff"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    />
                  </div>
                )}
              </InputAdornment>
            ),
          }}
          className={`${props.classNames} ${
            props.id === "confirmPassword"
              ? props.confirmPassValid === false &&
                inputState.isTouched &&
                "bg-card-main"
              : !inputState.isValid && inputState.isTouched && "bg-card-main"
          }`}
          onChange={changeHandler}
          onBlur={touchHandler}
          value={inputState.value}
          error={
            props.id === "confirmPassword"
              ? props.confirmPassValid === false && inputState.isTouched
                ? true
                : false
              : !inputState.isValid && inputState.isTouched
              ? true
              : false
          }
          helperText={
            props.id === "confirmPassword"
              ? props.confirmPassValid === false &&
                inputState.isTouched &&
                props.helperText
              : !inputState.isValid && inputState.isTouched && props.helperText
          }
        />
      ) : !props.customInput ? (
        <TextField
          size={props.size ? props.size : "medium"}
          // color={props.color ? props.color : "primary"}
          id={props.type}
          label={props.label}
          placeholder={props.placeholder}
          variant="outlined"
          className={`${props.classNames} ${
            !inputState.isValid && inputState.isTouched && "bg-card-main"
          }`}
          onChange={changeHandler}
          onBlur={touchHandler}
          value={inputState.value}
          error={!inputState.isValid && inputState.isTouched ? true : false}
          helperText={
            !inputState.isValid && inputState.isTouched && props.helperText
          }
        />
      ) : (
        <input
          className={`${props.classNames}`}
          onChange={changeHandler}
          onBlur={touchHandler}
          value={inputState.value}
          placeholder={props.placeholder}
          style={props.style}
        />
      )}
    </>
  );
}

export default Input;
