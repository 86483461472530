import { getChatsUrl, createChatUrl } from "@api/Endpoint";
import { axiosInstance as axios } from "@api/axios";
import { ChatActionTypes } from "../redux/actionTypes";
import { getUserMessages } from "@store/message/MessageActions";

export const getChats = () => {
  return (dispatch) => {
    dispatch({
      type: ChatActionTypes.GET_CHATS_START,
    });
    const url = getChatsUrl();
    axios
      .get(url)
      .then((res) => {
        let { data } = res.data;
        if (data) {
          getChatsSuccess(dispatch, data);
        } else {
          getChatsFail(dispatch, "There was an error connection");
        }
      })
      .catch((error) => {
        getChatsFail(dispatch, "There was an error connection2");
      });
  };
};
const getChatsFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: ChatActionTypes.GET_CHATS_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const getChatsSuccess = (dispatch, data) => {
  dispatch({
    type: ChatActionTypes.GET_CHATS_SUCCESS,
    payload: data,
  });
};

export const createChat = (recieverId, recieverUserType, message) => {
  return (dispatch) => {
    dispatch({
      type: ChatActionTypes.CREATE_CHAT_START,
    });

    const request = {
      recieverId,
      senderUserType: localStorage.getItem("userType"),
      recieverUserType,
      message,
    };
    const url = createChatUrl();
    axios
      .post(url, request)
      .then((res) => {
        let { data } = res.data;
        if (data.result) {
          createChatSuccess(dispatch, data, recieverId);
        } else {
          createChatFail(dispatch, "There was an error connection");
        }
      })
      .catch((error) => {
        console.log(error.message);
        createChatFail(dispatch, "There was an error connection2");
      });
  };
};
const createChatFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: ChatActionTypes.CREATE_CHAT_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const createChatSuccess = (dispatch, data, recieverId) => {
  dispatch({
    type: ChatActionTypes.CREATE_CHAT_SUCCESS,
    payload: data,
  });
  dispatch(
    getUserMessages({
      chatId: data.result.chatId,
      recieverId,
      page: 1,
      limit: 50,
    })
  );
};

export const setUserChatDetails = (data) => {
  return (dispatch) => {
    dispatch({
      type: ChatActionTypes.SET_USER_CHAT_DETAILS,
      payload: data,
    });
  };
};
