import React, { useEffect } from "react";
// import Chart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
// import { useTranslation } from "react-i18next";
// import moment from "moment";
import { DataGrid } from "@material-ui/data-grid";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
// import "@fullcalendar/daygrid/main.css";

import { Loader, ProfileChatList, LessonList } from "@components";
import {
  getCalendarDatesByTeacherId,
  getLessonsByTeacherId,
} from "@store/lesson/LessonActions";
import { getTeacherRevenueList } from "@store/auth/AuthActions";
// import { getTeacherMonthlyRevenue } from "@store/auth/AuthActions";

// The commented code is the graph feature which was omitted later.
// But the code is still here if needed in future.

// let monthNames = [];
// let getAllMonths = [];
// let m = new Date();
// m = m.getMonth() + 1;
// m = 12 - m;

// for (let i = 11; i >= 0; i--) {
//   monthNames.push(
//     moment().subtract(i, "months").add(m, "months").format("MMMM")
//   );
//   getAllMonths.push(
//     moment().subtract(i, "months").add(m, "months").format("MM")
//   );
// }

const defaultMonthlyData = [];
for (let i = 0; i <= 11; i++) {
  // defaultMonthlyData.push({
  //   month: moment(getAllMonths[i], "MM").format("MMMM"),
  //   total_amount: 0,
  // });
  defaultMonthlyData.push(0);
}

function TeacherDashboard() {
  // const { t } = useTranslation();
  // const [yearlyGraphData, setYearlyGraphData] = useState([]);
  // const [graphData, setGraphData] = useState([]);
  // const [year, setYear] = useState("2021");
  // const [totalYears, setTotalYears] = useState([]);
  // const [revenueLoading, setRevenueLoading] = useState(false);

  // const series = [
  //   {
  //     name: "Euros",
  //     data: graphData,
  //   },
  // ];
  // const options = {
  //   chart: {
  //     type: "line",
  //     zoom: {
  //       enabled: false,
  //     },
  //   },
  //   colors: ["#D9331F"],
  //   dataLabels: {
  //     enabled: false,
  //   },
  //   stroke: {
  //     curve: "smooth",
  //   },
  //   title: {
  //     text: t("general_revenue"),
  //     align: "left",
  //   },
  //   grid: {
  //     row: {
  //       colors: ["#EFEFEF", "transparent"],
  //       opacity: 0.5,
  //     },
  //   },
  //   xaxis: {
  //     categories: monthNames,
  //   },
  // };

  const dispatch = useDispatch();
  const lessonsData = useSelector(
    ({ EnglishCafe }) => EnglishCafe.lesson.lessons
  );
  const loading = useSelector(({ EnglishCafe }) => EnglishCafe.lesson.loading);
  const AuthLoading = useSelector(
    ({ EnglishCafe }) => EnglishCafe.auth.loading
  );

  const { lessonCalendarDatesLoading, lessonCalendarDates: events } =
    useSelector(({ EnglishCafe }) => EnglishCafe.lesson);

  // const revenueData = useSelector(
  //   ({ EnglishCafe }) => EnglishCafe.auth.revenueData
  // );

  useEffect(() => {
    dispatch(getLessonsByTeacherId(localStorage.getItem("userId"), 1, 2));
    dispatch(getCalendarDatesByTeacherId());
  }, [dispatch]);

  // useEffect(() => {
  //   dispatch(getTeacherMonthlyRevenue());
  // }, [dispatch]);

  // useEffect(() => {
  //   if (revenueData.length > 0) {
  //     setRevenueLoading(true);
  //     const yearlyData = revenueData.map((yearlyData) => {
  //       const data = JSON.parse(JSON.stringify(defaultMonthlyData));
  //       yearlyData.yearlyItems.forEach((monthlyData) => {
  //         const [, month] = monthlyData.month.split("-");
  //         const monthLocation = getAllMonths.indexOf(month.toString());
  //         data[monthLocation] = monthlyData.total_amount;
  //       });
  //       return {
  //         year: yearlyData._id,
  //         data,
  //       };
  //     });
  //     setYearlyGraphData(yearlyData);
  //     setGraphData(yearlyData[0].data);
  //     setRevenueLoading(false);
  //   }
  // }, [revenueData]);

  // useEffect(() => {
  //   const years = revenueData.map((yearlyData) => {
  //     return {
  //       label: yearlyData._id,
  //       value: yearlyData._id,
  //     };
  //   });
  //   setTotalYears(years);
  // }, [revenueData]);

  // const [width, setWidth] = useState(window.innerWidth);
  // const handleWindowSizeChange = () => {
  //   setWidth(window.innerWidth);
  // };
  // useEffect(() => {
  //   window.addEventListener("resize", handleWindowSizeChange);
  //   return () => window.removeEventListener("resize", handleWindowSizeChange);
  // }, []);

  // const yearChangeHandler = (event) => {
  //   setYear(event.target.value);
  //   let data;
  //   yearlyGraphData.forEach((e) => {
  //     if (e.year.toString() === event.target.value.toString()) {
  //       data = e.data;
  //     }
  //   });
  //   if (!data) {
  //     data = defaultMonthlyData;
  //   }
  //   setGraphData(data);
  // };
  useEffect(() => {
    dispatch(getTeacherRevenueList(1, 5));
  }, [dispatch]);

  const { revenueList: rowData } = useSelector(
    ({ EnglishCafe }) => EnglishCafe.auth
  );

  const columns = [
    { field: "id", headerName: "ID", width: 100, hide: true },
    {
      field: "studentName",
      headerName: "Student Name",
      flex: 0.25,
    },
    {
      field: "lessonTitle",
      headerName: "Lesson Title",
      flex: 0.25,
    },
    {
      field: "lessonType",
      headerName: "Lesson Type",
      flex: 0.25,
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 0.18,
      //   type: "number",
    },
    {
      field: "date",
      headerName: "Date",
      flex: 0.17,
    },
  ];

  return (
    <>
      <Loader
        loading={loading || AuthLoading || lessonCalendarDatesLoading}
      ></Loader>
      <div className="styledScrollbar overflow-auto pl-16 mr-1 pb-4 mb-1">
        <div className="flex flex-col ">
          {/* <div
          className="mt-6 flex border border-border-gray bg-white p-4 rounded-md relative"
          style={{ width: "fit-content" }}
        >
          <Chart
            options={options}
            series={series}
            type="line"
            width={width - 400}
            height="500"
          />
          <div className="right-20 absolute w-40">
            <Select
              placeholder={t("general_select_year")}
              onChange={yearChangeHandler}
              name="year"
              value={year}
              options={totalYears}
            />
          </div>
        </div> */}
          <div className="my-6 border border-border-gray bg-white p-4 md:mr-4 rounded-md relative">
            <FullCalendar
              locale="local"
              defaultView="dayGridMonth"
              className="my-6 flex border border-border-gray bg-white p-4 rounded-md relative"
              plugins={[dayGridPlugin]}
              events={events}
              eventTimeFormat={{
                hour: "numeric",
                minute: "2-digit",
                meridiem: false,
              }}
            />
          </div>
          {rowData.length > 0 && (
            <div
              className="pr-16"
              style={{
                width: "calc(100vw - 15rem)",
                height: "375px",
              }}
            >
              <DataGrid
                rows={rowData}
                columns={columns}
                disableSelectionOnClick
                rowsPerPageOptions={[5]}
                loading={AuthLoading}
              />
            </div>
          )}
          <div className="flex mt-10">
            <div className="flex">
              {lessonsData && lessonsData.length > 0 && (
                <LessonList lessonsData={lessonsData} />
              )}
            </div>
            <div className="ml-6">
              <ProfileChatList />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TeacherDashboard;
