import { toast } from "react-toastify";

import {
  loginTeacherUrl,
  registerTeacherUrl,
  loginStudentUrl,
  registerStudentUrl,
  getTeacherByIdUrl,
  getStudentByIdUrl,
  onBoardTeacherUrl,
  onBoardStudentUrl,
  studentSocialAuthenticateUrl,
  teacherSocialAuthenticateUrl,
  updateTeacherUrl,
  updateStudentUrl,
  getTeacherPublicProfileDetailsUrl,
  studentForgotPasswordUrl,
  teacherForgotPasswordUrl,
  studentUpdatePasswordUrl,
  teacherUpdatePasswordUrl,
  studentEditPasswordUrl,
  teacherEditPasswordUrl,
  getTeacherMonthlyRevenueUrl,
  getTeacherRevenueListUrl,
} from "@api/Endpoint";
import { axiosInstance as axios } from "@api/axios";
import { AuthActionTypes } from "../redux/actionTypes";
import { User } from "@models/User";
import { getUserCards } from "@store/payment/PaymentActions";

export const submitUserLogin = (user: User, history: any, userType) => {
  return (dispatch) => {
    dispatch({
      type: AuthActionTypes.LOGIN_USER_START,
    });
    const request = {
      email: user.email,
      password: user.password,
    };
    let url = "";
    if (userType === "Teacher") {
      url = loginTeacherUrl();
    } else {
      url = loginStudentUrl();
    }
    axios
      .post(url, request)
      .then((res) => {
        let { data } = res.data;
        if (
          data.accessToken &&
          data.accessToken !== "undefined" &&
          data._id &&
          data._id !== "undefined"
        ) {
          loginUserSuccess(dispatch, data, data.message, userType, history);
        } else {
          toast.error("Something went wrong!");
          loginUserFail(dispatch, "There was an error connection");
        }
      })
      .catch((error) => {
        console.log(error.message);
        toast.error(error.response.data.message);
        loginUserFail(dispatch, "There was an error connection2");
      });
  };
};
const loginUserFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: AuthActionTypes.LOGIN_USER_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const loginUserSuccess = (dispatch, data, message, userType, history) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + data.accessToken;
  localStorage.setItem("access_token", data.accessToken);
  localStorage.setItem("userId", data._id);
  localStorage.setItem("userType", userType);

  dispatch({
    type: AuthActionTypes.LOGIN_USER_SUCCESS,
    payload: data,
  });
  dispatch(getUser(data._id, userType));
  dispatch(getUserCards());
  // toast.success(message);
  if (data?.onBoarded && data?.onBoarded === true) {
    if (userType === "Teacher") {
      history.push("/teacherProfile");
    } else {
      history.push("/lessons");
    }
  } else {
    history.push("/onBoarding");
  }
};

export const submitUserSignup = (user: User, history: any, userType) => {
  return (dispatch) => {
    dispatch({
      type: AuthActionTypes.CREATE_USER_START,
    });
    const request = {
      name: user.name,
      surname: user.surname,
      email: user.email,
      password: user.password,
    };
    let url = "";
    if (userType === "Teacher") {
      url = registerTeacherUrl();
    } else {
      url = registerStudentUrl();
    }
    axios
      .post(url, request)
      .then((res) => {
        let { data } = res.data;
        if (
          data.accessToken &&
          data.accessToken !== "undefined" &&
          data._id &&
          data._id !== "undefined"
        ) {
          registerUserSuccess(dispatch, data, data.message, userType, history);
        } else {
          registerUserFail(dispatch, "There was an error connection");
        }
      })
      .catch((error) => {
        console.log(error.message);
        toast.error(error.response.data.message);
        registerUserFail(dispatch, "There was an error connection2");
      });
  };
};

const registerUserFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: AuthActionTypes.CREATE_USER_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const registerUserSuccess = (dispatch, data, message, userType, history) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + data.accessToken;
  localStorage.setItem("access_token", data.accessToken);
  localStorage.setItem("userId", data._id);
  localStorage.setItem("userType", userType);
  dispatch({
    type: AuthActionTypes.CREATE_USER_SUCCESS,
    payload: data,
  });
  dispatch(getUser(data._id, userType));
  dispatch(getUserCards());
  //   toast.success(message);
  history.push("/onBoarding");
};

export const getUser = (id, userType) => {
  return (dispatch) => {
    dispatch({
      type: AuthActionTypes.GET_USER_START,
    });

    let url = "";
    if (userType === "Teacher") {
      url = getTeacherByIdUrl(id);
    } else {
      url = getStudentByIdUrl(id);
    }

    axios
      .get(url)
      .then((res) => {
        let { data } = res.data;
        if (data) {
          getUserSuccess(dispatch, data);
        } else {
          getUserFail(dispatch, "There was an error connection");
        }
      })
      .catch((error) => {
        console.log(error.message);
        getUserFail(dispatch, "There was an error connection2");
      });
  };
};
const getUserFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: AuthActionTypes.GET_USER_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const getUserSuccess = (dispatch, data) => {
  dispatch({
    type: AuthActionTypes.GET_USER_SUCCESS,
    payload: data,
  });
};

export const socialAuthenticate = (userData, history, userType) => {
  return (dispatch) => {
    dispatch({
      type: AuthActionTypes.SOCIAL_AUTH_START,
    });
    let url = "";
    if (userType === "Teacher") {
      url = teacherSocialAuthenticateUrl();
    } else {
      url = studentSocialAuthenticateUrl();
    }
    const request = {
      email: userData.email,
      name: userData.name,
      surname: userData.surname,
      socialId: userData.socialId,
    };
    axios
      .post(url, request)
      .then((res) => {
        let { data } = res.data;
        if (
          data.accessToken &&
          data.accessToken !== "undefined" &&
          data._id &&
          data._id !== "undefined"
        ) {
          socialAuthenticateSuccess(dispatch, data, userType, history);
        } else {
          socialAuthenticateFail(dispatch, "There was an error connection");
        }
      })
      .catch((error) => {
        console.log(error.message);
        socialAuthenticateFail(dispatch, "There was an error connection2");
      });
  };
};
const socialAuthenticateFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: AuthActionTypes.SOCIAL_AUTH_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const socialAuthenticateSuccess = (dispatch, data, userType, history) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + data.accessToken;
  localStorage.setItem("access_token", data.accessToken);
  localStorage.setItem("userId", data._id);
  localStorage.setItem("userType", userType);
  dispatch({
    type: AuthActionTypes.SOCIAL_AUTH_SUCCESS,
    payload: data,
  });
  dispatch(getUser(data._id, userType));
  dispatch(getUserCards());
  if (data?.onBoarded && data?.onBoarded === true) {
    if (userType === "Teacher") {
      history.push("/teacherProfile");
    } else {
      history.push("/lessons");
    }
  } else {
    history.push("/onBoarding");
  }
};

export const onBoardUser = (data, history, userType) => {
  return (dispatch) => {
    dispatch({
      type: AuthActionTypes.ONBOARD_USER_START,
    });

    let url = "";
    if (userType === "Teacher") {
      url = onBoardTeacherUrl();
    } else {
      url = onBoardStudentUrl();
    }
    axios
      .put(url, data)
      .then((res) => {
        let { data } = res.data;
        if (
          data.onBoarded &&
          data.onBoarded !== "undefined" &&
          data.onBoarded !== false
        ) {
          onBoardUserSuccess(dispatch, data, history, userType);
        } else {
          onBoardUserFail(dispatch, "There was an error connection");
        }
      })
      .catch((error) => {
        console.log(error.message);
        onBoardUserFail(dispatch, "There was an error connection2");
      });
  };
};
const onBoardUserFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: AuthActionTypes.ONBOARD_USER_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const onBoardUserSuccess = (dispatch, data, history, userType) => {
  dispatch({
    type: AuthActionTypes.ONBOARD_USER_SUCCESS,
    payload: data,
  });
  if (userType === "Teacher") {
    history.push("/teacherProfile");
  } else {
    history.push("/lessons");
  }
  dispatch(
    getUser(localStorage.getItem("userId"), localStorage.getItem("userType"))
  );
};

export const logout = () => {
  return (dispatch) => [
    dispatch({
      type: AuthActionTypes.LOGOUT,
    }),
  ];
};

export const updateUserProfile = (data) => {
  return (dispatch) => {
    dispatch({
      type: AuthActionTypes.UPDATE_USER_START,
    });
    const userType = localStorage.getItem("userType");
    let url = "";
    if (userType === "Teacher") {
      url = updateTeacherUrl();
    } else {
      url = updateStudentUrl();
    }
    axios
      .put(url, data)
      .then((res) => {
        let { data } = res.data;
        if (data.result) {
          updateUserProfileSuccess(dispatch, data, res.data.message);
        } else {
          updateUserProfileFail(dispatch, "There was an error connection");
        }
      })
      .catch((error) => {
        console.log(error.message);
        updateUserProfileFail(dispatch, "There was an error connection2");
      });
  };
};
const updateUserProfileFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: AuthActionTypes.UPDATE_USER_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const updateUserProfileSuccess = (dispatch, data, message) => {
  dispatch({
    type: AuthActionTypes.UPDATE_USER_SUCCESS,
    payload: data,
  });
  toast.success(message);
  dispatch(
    getUser(localStorage.getItem("userId"), localStorage.getItem("userType"))
  );
};

export const getTeacherPublicProfileDetails = (teacherId) => {
  return (dispatch) => {
    dispatch({
      type: AuthActionTypes.GET_TEACHER_PROFILE_START,
    });

    let url = getTeacherPublicProfileDetailsUrl(
      teacherId,
      localStorage.getItem("userId")
    );

    axios
      .get(url)
      .then((res) => {
        let { data } = res.data;
        if (data) {
          getTeacherPublicProfileDetailsSuccess(dispatch, data);
        } else {
          getTeacherPublicProfileDetailsFail(
            dispatch,
            "There was an error connection"
          );
        }
      })
      .catch((error) => {
        console.log(error.message);
        getTeacherPublicProfileDetailsFail(
          dispatch,
          "There was an error connection2"
        );
      });
  };
};
const getTeacherPublicProfileDetailsFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: AuthActionTypes.GET_TEACHER_PROFILE_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const getTeacherPublicProfileDetailsSuccess = (dispatch, data) => {
  dispatch({
    type: AuthActionTypes.GET_TEACHER_PROFILE_SUCCESS,
    payload: data,
  });
};

export const forgotPassword = (email, userType) => {
  return (dispatch) => {
    dispatch({
      type: AuthActionTypes.FORGOT_PASSWORD_START,
    });
    let url = "";
    if (userType === "Teacher") {
      url = teacherForgotPasswordUrl();
    } else {
      url = studentForgotPasswordUrl();
    }
    const request = {
      email,
    };
    axios
      .post(url, request)
      .then((res) => {
        let { data } = res.data;
        forgotPasswordSuccess(dispatch, data, res.data.message);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(error.message);
        forgotPasswordFail(dispatch, "There was an error connection2");
      });
  };
};
const forgotPasswordFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: AuthActionTypes.FORGOT_PASSWORD_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const forgotPasswordSuccess = (dispatch, data, message) => {
  dispatch({
    type: AuthActionTypes.FORGOT_PASSWORD_SUCCESS,
    payload: data,
  });
  toast.success(message);
};

export const updatePassword = (email, password, otp, history, userType) => {
  return (dispatch) => {
    dispatch({
      type: AuthActionTypes.UPDATE_PASSWORD_START,
    });

    let url = "";
    if (userType === "Teacher") {
      url = teacherUpdatePasswordUrl();
    } else {
      url = studentUpdatePasswordUrl();
    }
    const request = {
      email,
      newPassword: password,
      otp,
    };
    axios
      .put(url, request)
      .then((res) => {
        let { data } = res.data;
        if (
          data.accessToken &&
          data.accessToken !== "undefined" &&
          data._id &&
          data._id !== "undefined"
        ) {
          updatePasswordSuccess(dispatch, data, userType, history);
        } else {
          updatePasswordFail(dispatch, "There was an error connection");
        }
      })
      .catch((error) => {
        console.log(error.message);
        toast.error(error.response.data.message);
        updatePasswordFail(dispatch, "There was an error connection2");
      });
  };
};
const updatePasswordFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: AuthActionTypes.UPDATE_PASSWORD_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const updatePasswordSuccess = (dispatch, data, userType, history) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + data.accessToken;
  localStorage.setItem("access_token", data.accessToken);
  localStorage.setItem("userId", data._id);
  localStorage.setItem("userType", userType);
  dispatch({
    type: AuthActionTypes.UPDATE_PASSWORD_SUCCESS,
    payload: data,
  });
  dispatch(getUser(data._id, userType));
  dispatch(getUserCards());
  if (data?.onBoarded && data?.onBoarded === true) {
    if (userType === "Teacher") {
      history.push("/teacherProfile");
    } else {
      history.push("/lessons");
    }
  } else {
    history.push("/onBoarding");
  }
};

export const getTeacherMonthlyRevenue = () => {
  return (dispatch) => {
    dispatch({
      type: AuthActionTypes.GET_MONTHLY_REVENUE_START,
    });
    let url = getTeacherMonthlyRevenueUrl();
    axios
      .get(url)
      .then((res) => {
        let { data } = res.data;
        if (data) {
          getTeacherMonthlyRevenueSuccess(dispatch, data);
        } else {
          getTeacherMonthlyRevenueFail(
            dispatch,
            "There was an error connection"
          );
        }
      })
      .catch((error) => {
        console.log(error.message);
        toast.error(error.response.data.message);
        getTeacherMonthlyRevenueFail(
          dispatch,
          "There was an error connection2"
        );
      });
  };
};

const getTeacherMonthlyRevenueFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: AuthActionTypes.GET_MONTHLY_REVENUE_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const getTeacherMonthlyRevenueSuccess = (dispatch, data) => {
  dispatch({
    type: AuthActionTypes.GET_MONTHLY_REVENUE_SUCCESS,
    payload: data,
  });
};

export const getTeacherRevenueList = (page, limit) => {
  return (dispatch) => {
    dispatch({
      type: AuthActionTypes.GET_TEACHER_REVENUE_LIST_START,
      payload: {
        currentPage: page,
      },
    });
    let url = getTeacherRevenueListUrl(page, limit);
    axios
      .get(url)
      .then((res) => {
        let { data } = res.data;
        if (data) {
          getTeacherRevenueListSuccess(dispatch, data);
        } else {
          getTeacherRevenueListFail(dispatch, "There was an error connection");
        }
      })
      .catch((error) => {
        console.log(error.message);
        toast.error(error.response.data.message);
        getTeacherRevenueListFail(dispatch, "There was an error connection2");
      });
  };
};

const getTeacherRevenueListFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: AuthActionTypes.GET_TEACHER_REVENUE_LIST_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const getTeacherRevenueListSuccess = (dispatch, data) => {
  dispatch({
    type: AuthActionTypes.GET_TEACHER_REVENUE_LIST_SUCCESS,
    payload: data,
  });
};

export const editPassword = (password, userType) => {
  return (dispatch) => {
    dispatch({
      type: AuthActionTypes.UPDATE_PASSWORD_START,
    });
    let url = "";
    if (userType === "Teacher") {
      url = teacherEditPasswordUrl();
    } else {
      url = studentEditPasswordUrl();
    }
    const request = {
      newPassword: password,
    };
    axios
      .put(url, request)
      .then((res) => {
        let { data } = res.data;
        if (data) {
          editPasswordSuccess(dispatch, data, res.data.message);
        } else {
          editPasswordFail(dispatch, "There was an error connection");
        }
      })
      .catch((error) => {
        console.log(error.message);
        editPasswordFail(dispatch, "There was an error connection2");
      });
  };
};
const editPasswordFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: AuthActionTypes.UPDATE_PASSWORD_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const editPasswordSuccess = (dispatch, data, message) => {
  dispatch({
    type: AuthActionTypes.UPDATE_PASSWORD_SUCCESS,
    payload: data,
  });
  toast.success(message);
};
