import React, { useEffect } from "react";
import { Hidden } from "@material-ui/core";

import DesktopStudentProfile from "./Desktop/DesktopStudentProfile";
import MobileStudentProfile from "./Mobile/MobileStudentProfile";
import { pageAnalytics } from "@helpers/utils";

function StudentProfile(props) {
  useEffect(() => {
    pageAnalytics(window);
  }, []);
  return (
    <>
      <Hidden smDown>
        <DesktopStudentProfile {...props} />
      </Hidden>
      <Hidden only={["md", "lg", "xl"]}>
        <MobileStudentProfile {...props} />
      </Hidden>
    </>
  );
}

export default StudentProfile;
