import React from "react";
import { Dialog } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { Icon } from "@components";

function ContactInfoModal({ onClose, open, user }) {
  const { t } = useTranslation();
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <div className="flex flex-col justify-center items-center px-8 py-6 md:w-96">
        <Icon
          type="closeIconBlack"
          classNames="self-end cursor-pointer -mr-3"
          onClick={handleClose}
        />
        <Icon type="contactIcon" classNames="my-6" />
        <p className="text-xl font-medium mb-6 text-center">
          {t("general_contact_info")}
        </p>
        <div className="flex flex-col pb-8">
          <div className="flex items-center">
            <p className="font-medium">{t("general_email")}:</p>
            <p className="text-md ml-2">{user?.email}</p>
          </div>
          <div className="flex items-center">
            <p className="font-medium">{t("general_phoneNo")}:</p>
            <p className="text-md ml-2">{user?.phoneNo}</p>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default ContactInfoModal;
