import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  HeaderContainer,
  DesktopMessages,
  ProfileNavBar,
  EditProfile,
  PaymentMethods,
} from "@components";
import TeacherDashboard from "../components/TeacherDashboard";
import LessonForm from "../components/LessonForm";
import ViewLesson from "../components/ViewLesson";
import { teacherTabs } from "../helper/Data";
import { setActiveTab } from "@store/header/HeaderActions";
import PaymentHistory from "../components/PaymentHistory";
import ViewDraft from "../components/ViewDraft";

function DesktopTeacherProfile(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeTab = useSelector(
    ({ EnglishCafe }) => EnglishCafe.header.activeTab
  );
  const user = useSelector(({ EnglishCafe }) => EnglishCafe.auth.user);
  return (
    <>
      <HeaderContainer />
      <div className="flex w-full overflow-hidden" style={{ height: "92vh" }}>
        <ProfileNavBar user={user} tabs={teacherTabs} />
        <div className="flex flex-col bg-profileCard-lightgray w-full h-full">
          <p className="mt-10 ml-10 pb-6">
            <span
              className=" font-bold cursor-pointer"
              onClick={() => {
                dispatch(setActiveTab("My Profile"));
              }}
            >
              {t("general_my_account") + " "}
            </span>
            {t(teacherTabs.find((tab) => tab.value === activeTab)?.title)}
          </p>
          {activeTab === "Edit Profile" && <EditProfile user={user} />}
          {activeTab === "My Profile" && <TeacherDashboard />}
          {(activeTab === "Create Ads" ||
            activeTab === "Modify Ads" ||
            activeTab === "Modify Drafts") && <LessonForm />}
          {activeTab === "View Ads" && <ViewLesson />}
          {activeTab === "View Drafts" && <ViewDraft />}
          {activeTab === "Messages" && <DesktopMessages {...props} />}
          {activeTab === "Payment Methods" && <PaymentMethods />}
          {activeTab === "Payment History" && <PaymentHistory />}
        </div>
      </div>
    </>
  );
}

export default DesktopTeacherProfile;
