import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { getChats } from "@store/chat/ChatActions";
import { ChatCard } from "@components";
import { SubscribersListModal } from "@components/modals";
import { getStudentsByTeacherId } from "@store/lesson/LessonActions";

function MobileMessages(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [chatContacts, setChatContacts] = useState([]);
  const [subscribersModalOpen, setSubscribersModalOpen] = useState(false);

  const chatsList = useSelector(({ EnglishCafe }) => EnglishCafe.chat.chats);

  useEffect(() => {
    dispatch(getChats());
  }, [dispatch]);

  useEffect(() => {
    const allChatContacts = [];
    chatsList &&
      chatsList.length > 0 &&
      chatsList.forEach((chat) => {
        chat.participents.forEach((user) => {
          if (
            user._id.toString() !== localStorage.getItem("userId").toString()
          ) {
            allChatContacts.push({
              user,
              chatId: chat._id,
              lastMessage: chat.lastMessage,
            });
          }
        });
      });
    setChatContacts(allChatContacts);
  }, [chatsList]);
  return (
    <>
      <div className="flex flex-col mt-4">
        <div className="self-start">
          <p
            className="text-sm md:text-xs px-2 py-1 md:px-1 md:py-0.5 ml-2 bg-pink-100 rounded-md cursor-pointer "
            onClick={() => {
              setSubscribersModalOpen(true);
              dispatch(getStudentsByTeacherId());
            }}
          >
            {t("profile_message_start_conversation")}
          </p>
        </div>
        {chatContacts?.length > 0 &&
          chatContacts.map((chat) => {
            return <ChatCard chat={chat} key={chat.chatId} />;
          })}
      </div>

      <SubscribersListModal
        open={subscribersModalOpen}
        onClose={() => {
          setSubscribersModalOpen(false);
        }}
      />
    </>
  );
}

export default MobileMessages;
