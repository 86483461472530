import React, { useState } from "react";
import { Dialog } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { Icon, Button } from "@components";
import { compareDateWithCurrentDate } from "@helpers/utils";

function SelectDateModal({ onClose, open, dates, onSubmit, numberOfPlaces }) {
  const { t } = useTranslation();

  const [selectedDate, setSelectedDate] = useState("");

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = () => {
    onSubmit(selectedDate);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <div
        className="flex flex-col items-center px-8 py-6 md:w-96 overflow-y-auto styledScrollbar"
        style={{ maxHeight: "50vh" }}
      >
        <Icon
          type="closeIconBlack"
          classNames="self-end cursor-pointer -mr-3"
          onClick={handleClose}
        />
        <div className="flex items-center mb-6">
          <Icon type="calendarIcon2" classNames="w-8 h-8" />
          <p className="ml-3 text-xl font-medium">
            {t("lesson_detail_select_enrolment_date")}
          </p>
        </div>
        <div className="flex flex-wrap items-center justify-center">
          {dates &&
            dates.length > 0 &&
            dates.map((date) => {
              return (
                <div
                  className={`border border-border-gray text-sm rounded-md ml-2 mt-2 px-4 py-2 ${
                    date.count < numberOfPlaces &&
                    compareDateWithCurrentDate(date.date)
                      ? "cursor-pointer"
                      : "bg-profileCard-gray"
                  } ${
                    date.date === selectedDate && "bg-secondary-main text-white"
                  }`}
                  onClick={() => {
                    if (
                      date.count < numberOfPlaces &&
                      compareDateWithCurrentDate(date.date)
                    ) {
                      setSelectedDate(date?.date);
                    }
                  }}
                >
                  <p>{date?.date}</p>
                </div>
              );
            })}
        </div>
        <Button
          classNames="mt-10 mb-4"
          onClick={handleSubmit}
          isDisable={selectedDate === ""}
        >
          {t("general_submit")}
        </Button>
      </div>
    </Dialog>
  );
}

export default SelectDateModal;
