import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import StarRatings from "react-star-ratings";

import { numberWithCommas } from "@helpers/utils";
import { ReviewListModal } from "@components/modals";
import { Review } from "@components";

function TeacherInfo({ teacherProfile }) {
  const { t } = useTranslation();
  const [openReviewsModal, setOpenReviewsModal] = useState(false);

  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => window.removeEventListener("resize", handleWindowSizeChange);
  }, []);
  const isMobile = width <= 600;

  return (
    <div className="flex flex-col" style={{ width: !isMobile && "60vw" }}>
      <p className="mt-6 capitalize font-bold text-xl md:w-5/12">
        {teacherProfile?.name + " " + teacherProfile?.surname}
      </p>
      {teacherProfile?.avgRating && (
        <div className="flex items-center">
          <p className="mr-2 mt-1">
            {parseFloat(teacherProfile?.avgRating).toFixed(1)}
          </p>
          <StarRatings
            starRatedColor="red"
            starDimension={"20px"}
            starSpacing={"1px"}
            rating={teacherProfile?.avgRating}
          />
        </div>
      )}
      <div className="flex mt-6 md:w-7/12">
        {/* <div className="flex flex-col mr-6">
          <p className=""> {t("teacher_profile_total_students_label")}</p>
          <p className="font-bold text-xl">
            {teacherProfile?.enrolments &&
              numberWithCommas(teacherProfile?.enrolments)}
          </p>
        </div> */}
        <div className="flex flex-col">
          <p className="">{t("teacher_profile_reviews_label")}</p>
          <p className="font-bold text-xl">
            {teacherProfile?.reviews &&
              numberWithCommas(teacherProfile?.reviews?.length)}
          </p>
        </div>
      </div>
      {/* <div className="flex flex-col mt-6 md:w-7/12">
        <p className="font-bold text-lg">
          {t("teacher_profile_phone_number_label")}
        </p>
        <p className="text-lg">{"+" + teacherProfile?.phoneNo}</p>
      </div> */}
      <div className="flex flex-col mt-6 md:w-7/12">
        <p className="font-bold text-lg">
          {t("teacher_profile_province_label")}
        </p>
        <p className="text-lg">{teacherProfile?.province}</p>
      </div>
      <div className="flex flex-col mt-6 w-full md:w-10/12">
        <p className="font-bold text-lg">
          {t("teacher_profile_biography_label")}
        </p>
        <p className="text-lg text-justify">{teacherProfile?.biography}</p>
      </div>
      <div className="flex flex-col mt-6 md:w-10/12">
        <p className="font-bold text-lg">
          {t("teacher_profile_experience_label")}
        </p>
        <p className="text-lg text-justify">{teacherProfile?.experiences}</p>
      </div>
      <div className="flex flex-col mt-6 md:w-10/12">
        <p className="font-bold text-lg">
          {t("teacher_profile_about_classes_label")}
        </p>
        <p className="text-lg text-justify">{teacherProfile?.aboutClasses}</p>
      </div>
      {teacherProfile?.reviews && teacherProfile?.reviews?.length > 0 && (
        <div className="flex flex-col mt-6 border border-border-gray rounded-md px-6 py-6 md:w-10/12">
          <div className="flex justify-between">
            <p className="font-bold">{t("teacher_profile_reviews_label")}</p>
            <p
              className="font-bold cursor-pointer"
              onClick={() => {
                setOpenReviewsModal(true);
              }}
            >
              {t("general_view_all")}
            </p>
          </div>
          <p className="test-sm mt-4">
            {teacherProfile?.enrolments &&
              numberWithCommas(teacherProfile?.enrolments) +
                " " +
                t("teacher_profile_courses")}
          </p>
          <p className="test-sm">
            {teacherProfile?.reviews &&
              numberWithCommas(teacherProfile?.reviews?.length) +
                " " +
                t("teacher_profile_reviews")}
          </p>
          <div className="flex flex-col mt-6">
            {teacherProfile?.reviews &&
              teacherProfile?.reviews?.length > 0 &&
              teacherProfile?.reviews?.slice(0, 2).map((review) => {
                return (
                  <>
                    <Review review={review} />
                  </>
                );
              })}
          </div>
        </div>
      )}
      <ReviewListModal
        open={openReviewsModal}
        onClose={() => {
          setOpenReviewsModal(false);
        }}
        reviews={teacherProfile?.reviews}
      />
    </div>
  );
}

export default TeacherInfo;
