import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Button,
  ProfileChatList,
  LessonList,
  Avatar,
  Loader,
} from "@components";
import { getSubscribedLessons } from "@store/lesson/LessonActions";
import { setActiveTab } from "@store/header/HeaderActions";

function Profile({ user }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const lessonsData = useSelector(
    ({ EnglishCafe }) => EnglishCafe.lesson.lessons
  );

  const loading = useSelector(({ EnglishCafe }) => EnglishCafe.lesson.loading);

  useEffect(() => {
    dispatch(getSubscribedLessons(1, 2));
  }, [dispatch]);

  return (
    <>
      <Loader loading={loading} />
      <div className="flex flex-col bg-profileCard-gray p-6 rounded-xl mt-20 relative">
        <div className="absolute left-0 bottom-20 flex justify-center w-full z-10">
          <Avatar uri={user?.image} name={user?.name} classNames="w-20 h-20" />
        </div>
        <Button
          variant="outlined"
          classNames="self-center mt-8"
          onClick={() => {
            dispatch(setActiveTab("Edit Profile"));
          }}
        >
          {t("profile_tab_edit_profile")}
        </Button>
      </div>
      <div className="flex mt-4">
        <LessonList lessonsData={lessonsData} />
      </div>
      <div className="flex mt-4">
        <ProfileChatList />
      </div>
    </>
  );
}

export default Profile;
