import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  HeaderContainer,
  EditProfile,
  MobileMessages,
  PaymentMethods,
} from "@components";
import Profile from "./Profile";
import ViewLesson from "../components/ViewLesson";
import LessonForm from "../components/LessonForm";
import { teacherTabs } from "../helper/Data";
import { setActiveTab } from "@store/header/HeaderActions";
import PaymentHistory from "../components/PaymentHistory";
import ViewDraft from "../components/ViewDraft";

function MobileTeacherProfile(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeTab = useSelector(
    ({ EnglishCafe }) => EnglishCafe.header.activeTab
  );
  const user = useSelector(({ EnglishCafe }) => EnglishCafe.auth.user);

  return (
    <>
      <HeaderContainer />
      <div className="flex flex-col mt-4 px-4 py-4">
        <p>
          <span
            className="font-bold"
            onClick={() => {
              dispatch(setActiveTab("My Profile"));
            }}
          >
            {t("general_my_account") + " "}
          </span>
          {t(teacherTabs.find((tab) => tab.value === activeTab)?.title)}
        </p>
        {activeTab === "My Profile" && <Profile user={user} />}
        {activeTab === "Edit Profile" && <EditProfile user={user} />}
        {(activeTab === "Create Ads" ||
          activeTab === "Modify Ads" ||
          activeTab === "Modify Drafts") && <LessonForm />}
        {activeTab === "View Ads" && <ViewLesson />}
        {activeTab === "View Drafts" && <ViewDraft />}
        {activeTab === "Messages" && <MobileMessages {...props} />}
        {activeTab === "Payment Methods" && <PaymentMethods />}
        {activeTab === "Payment History" && <PaymentHistory />}
      </div>
    </>
  );
}

export default MobileTeacherProfile;
