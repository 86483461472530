import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";

import { Icon, LessonCard } from "@components";

import SwiperCore, { Pagination, Navigation } from "swiper/core";

SwiperCore.use([Pagination, Navigation]);

function LessonsRow({ lessonsData }) {
  const prevRef = React.useRef();
  const nextRef = React.useRef();

  return (
    <div className="flex relative">
      <div
        className="w-10 absolute cursor-pointer top-16 md:top-24 md:w-16 z-20"
        ref={prevRef}
        style={{ left: "6.5%" }}
      >
        <Icon type="leftCircle" />
      </div>
      <div
        className="w-10 absolute flex  cursor-pointer top-16 md:top-24 md:w-16 z-20"
        ref={nextRef}
        style={{ right: "6.5%" }}
      >
        <Icon type="rightCircle" />
      </div>
      <Swiper
        breakpoints={{
          0: {
            slidesPerView: 1.25,
            spaceBetween: 10,
          },
          426: {
            slidesPerView: 1.25,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
        }}
        loop={false}
        pagination={{
          clickable: true,
        }}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        onBeforeInit={(swiper) => {
          swiper.params.navigation.prevEl = prevRef.current;
          swiper.params.navigation.nextEl = nextRef.current;
        }}
        className="mySwiper h-72 md:h-96 w-5/6"
      >
        {lessonsData?.length > 0 &&
          lessonsData.map((lesson, index) => {
            return (
              <SwiperSlide key={lesson._id}>
                <LessonCard lesson={lesson} />
              </SwiperSlide>
            );
          })}
      </Swiper>
    </div>
  );
}

export default LessonsRow;
