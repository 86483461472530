import React, { useEffect } from "react";

import { HeaderContainer, Messenger } from "@components";
import { pageAnalytics } from "@helpers/utils";

function MobileMessenger(props) {
  const { chatId, recieverId, userImage, userName } =
    (props.location && props.location.state) || {};

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    pageAnalytics(window);
  }, []);

  return (
    <>
      <HeaderContainer />
      <div className="flex" style={{ height: "92vh" }}>
        <Messenger
          userName={userName}
          userImage={userImage}
          recieverId={recieverId}
          userChatId={chatId}
        />
      </div>
    </>
  );
}

export default MobileMessenger;
