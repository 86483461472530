import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { setActiveTab } from "@store/header/HeaderActions";
import { getChats } from "@store/chat/ChatActions";
import { ChatCard } from "@components";
import Loader from "./Loader";

function ProfileChatList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [chatContacts, setChatContacts] = useState([]);

  const { loading } = useSelector(({ EnglishCafe }) => EnglishCafe.chat);
  const chatsList = useSelector(({ EnglishCafe }) => EnglishCafe.chat.chats);

  useEffect(() => {
    dispatch(getChats());
  }, [dispatch]);

  useEffect(() => {
    const allChatContacts = [];
    chatsList &&
      chatsList.length > 0 &&
      chatsList.forEach((chat) => {
        chat.participents.forEach((user) => {
          if (
            user._id.toString() !== localStorage.getItem("userId").toString()
          ) {
            allChatContacts.push({
              user,
              chatId: chat._id,
              lastMessage: chat.lastMessage,
            });
          }
        });
      });
    setChatContacts(allChatContacts);
  }, [chatsList]);

  return (
    <>
      <Loader loading={loading} />
      <div className="flex flex-col border border-border-gray bg-white p-4 md:p-6 rounded-md w-96">
        <div className="flex justify-between items-center mb-6">
          <p className="font-bold text-lg">{t("profile_message_my_inbox")}</p>
          <p
            className="text-xs cursor-pointer"
            onClick={() => dispatch(setActiveTab("Messages"))}
          >
            {t("general_view_all")}
          </p>
        </div>

        {chatContacts?.length > 0 &&
          chatContacts.slice(0, 2).map((chat) => {
            return (
              <ChatCard key={chat.chatId} isProfilePage={true} chat={chat} />
            );
          })}
      </div>
    </>
  );
}

export default ProfileChatList;
