export const teacherTabs = [
  { title: "profile_tab_dashboard", value: "My Profile", icon: "userIcon" },
  {
    title: "profile_tab_create_ads",
    value: "Create Ads",
    icon: "createAdIcon",
  },
  {
    title: "profile_tab_view_drafts",
    value: "View Drafts",
    icon: "viewDraftIcon",
  },
  { title: "profile_tab_view_ads", value: "View Ads", icon: "viewAdIcon" },
  { title: "profile_tab_messages", value: "Messages", icon: "messageIcon" },
  {
    title: "profile_tab_payment_methods",
    value: "Payment Methods",
    icon: "paymentMethodIcon",
  },
  {
    title: "profile_tab_payment_history",
    value: "Payment History",
    icon: "paymentHistory",
  },
];
export const LessonInputData = {
  type: {
    value: "group",
  },
  language: {
    value: "",
  },
  classType: {
    value: "",
  },
  city: {
    value: "",
  },
  postalCode: {
    value: "",
  },
  address: {
    value: "",
  },
  coordinates: {
    value: { latitude: 17.308688, longitude: 7.03125 },
  },
  title: {
    value: "",
  },
  description: {
    value: "",
  },
  level: {
    value: [],
  },
  duration: {
    value: "",
  },
  startTime: {
    value: "",
  },
  endTime: {
    value: "",
  },
  dayOfTheWeek: {
    value: "",
  },
  dateRange: {
    value: { startDate: "", endDate: "" },
  },
  dates: {
    value: [],
  },
  numberOfPlaces: {
    value: "",
  },
  trialClass: {
    value: "",
  },
  price: {
    value: "",
  },
  discount: {
    value: "",
  },
  imageFiles: {
    value: [],
  },
};

export const DayOfTheWeek = [
  { label: "Monday", value: "Monday" },
  { label: "Tuesday", value: "Tuesday" },
  { label: "Wednesday", value: "Wednesday" },
  { label: "Thursday", value: "Thursday" },
  { label: "Friday", value: "Friday" },
  { label: "Saturday", value: "Saturday" },
  { label: "Sunday", value: "Sunday" },
];

export const DayOfTheWeekSpanish = [
  { label: "Lunes", value: "Lunes" },
  { label: "Martes", value: "Martes" },
  { label: "Miércoles", value: "Miércoles" },
  { label: "Jueves", value: "Jueves" },
  { label: "Viernes", value: "Viernes" },
  { label: "Sábado", value: "Sábado" },
  { label: "Domingo", value: "Domingo" },
];

export const HoursPerWeek = [
  { label: "Five", value: 5 },
  { label: "Ten", value: 10 },
  { label: "Twenty", value: 20 },
  { label: "Thirty", value: 30 },
  { label: "Fourty", value: 40 },
];

export const OptionNumberGenerator = (n) => {
  if (n === 5) {
    return [{ label: n, value: n }];
  } else {
    return [...Array(parseInt(n))].map((e, i) => {
      return { label: i + 1, value: i + 1 };
    });
  }
};

export const Level = [
  { label: "A1", value: "A1" },
  { label: "A2", value: "A2" },
  { label: "B1", value: "B1" },
  { label: "B2", value: "B2" },
  { label: "C1", value: "C1" },
  { label: "C2", value: "C2" },
];

export const Discounts = [
  { label: 0, value: 0 },
  { label: 5, value: 5 },
  { label: 10, value: 10 },
  { label: 15, value: 15 },
  { label: 20, value: 20 },
  { label: 25, value: 25 },
  { label: 30, value: 30 },
  { label: 35, value: 35 },
  { label: 40, value: 40 },
  { label: 45, value: 45 },
  { label: 50, value: 50 },
];
