import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Button, Loader, PaymentCard } from "@components";
import { AddCardModal } from "@components/modals";

function PaymentMethods() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const loading = useSelector(({ EnglishCafe }) => EnglishCafe.payment.loading);
  const cards = useSelector(({ EnglishCafe }) => EnglishCafe.payment.cards);

  return (
    <>
      <Loader loading={loading} />
      <div className="flex flex-col items-center py-8 overflow-y-auto styledScrollbar">
        {cards?.length > 0 ? (
          <div className="grid grid-cols-1 gap-4 md:grid-cols-3 ">
            {cards.map((card) => {
              return <PaymentCard card={card} key={card.id} />;
            })}
          </div>
        ) : (
          <div
            className="flex items-center justify-center p-4"
            style={{ margin: "0 auto" }}
          >
            <p className="text-center">{t("payment_methods_no_cards_added")}</p>
          </div>
        )}
        <Button
          onClick={() => {
            setOpen(true);
          }}
          classNames="mt-20"
        >
          {cards?.length > 0
            ? t("general_add_new_card")
            : t("general_add_card_here")}
        </Button>
        <p className="text-xs px-40 mt-10">
          {t("payment_page_disclaimer1")}
          <a
            className="px-1 cursor-pointer underline"
            href="https://stripe.com"
            target="_blank"
            rel="noreferrer"
          >
            {t("payment_page_stripe")}
          </a>
          {t("payment_page_disclaimer2")}
        </p>
      </div>
      <AddCardModal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </>
  );
}

export default PaymentMethods;
