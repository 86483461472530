import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  HeaderContainer,
  EditProfile,
  MobileMessages,
  PaymentMethods,
} from "@components";
import Profile from "./Profile";
import ViewLesson from "../components/ViewLesson";
import { studentTabs } from "../helper/Data";
import { setActiveTab } from "@store/header/HeaderActions";
import ViewTeacher from "../components/ViewTeacher";

function MobileStudentProfile(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeTab = useSelector(
    ({ EnglishCafe }) => EnglishCafe.header.activeTab
  );
  const user = useSelector(({ EnglishCafe }) => EnglishCafe.auth.user);

  return (
    <>
      <HeaderContainer />
      <div className="flex flex-col mt-4 px-4 py-4">
        <p>
          <span
            className="font-bold"
            onClick={() => {
              dispatch(setActiveTab("My Profile"));
            }}
          >
            {t("general_my_account") + " "}
          </span>
          {t(studentTabs.find((tab) => tab.value === activeTab)?.title)}
        </p>
        {activeTab === "My Profile" && <Profile user={user} />}
        {activeTab === "Edit Profile" && <EditProfile user={user} />}
        {activeTab === "View Lessons" && <ViewLesson />}
        {activeTab === "View Teachers" && <ViewTeacher />}
        {activeTab === "Messages" && <MobileMessages {...props} />}
        {activeTab === "Payment Methods" && <PaymentMethods />}
      </div>
    </>
  );
}

export default MobileStudentProfile;
