import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import StarRatings from "react-star-ratings";

import {
  HeaderContainer,
  Footer,
  Button,
  Loader,
  Icon,
  PaymentCard,
  AddCardForm,
  WhatsappLink,
} from "@components";
import { buyLesson } from "@store/payment/PaymentActions";
import { getGroupNextClassDate, pageAnalytics } from "@helpers/utils";
import { AddCardModal } from "@components/modals";

function Checkout(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [checked, setChecked] = useState(false);
  const [addCardOpen, setAddCardOpen] = useState(false);

  const { lesson, enrolmentDate } =
    (props.location && props.location.state) || {};

  const { cards, loading: paymentLoading } = useSelector(
    ({ EnglishCafe }) => EnglishCafe.payment
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [lesson]);

  useEffect(() => {
    pageAnalytics(window);
  }, []);

  let newClassDate;
  let newRenewalClassDate;
  let discountedPrice = "";
  if (lesson?.type === "group") {
    newClassDate = getGroupNextClassDate(
      lesson?.dateRange?.startDate,
      lesson?.dayOfTheWeek
    );

    newRenewalClassDate = getGroupNextClassDate(
      lesson?.dateRange?.startDate,
      lesson?.dayOfTheWeek,
      1
    );
    discountedPrice = (
      lesson?.price -
      (lesson?.price * lesson.discount) / 100
    ).toFixed(2);
  }

  return (
    <>
      <Loader loading={paymentLoading} />
      <HeaderContainer />
      <div className="flex flex-col">
        <div
          className="flex  bg-black mt-8  items-center"
          style={{ height: "20vh" }}
        >
          <Icon
            type="backButtonWhite"
            classNames="w-8 cursor-pointer ml-10"
            onClick={() => history.goBack()}
          />
          <p className="text-white text-3xl ml-10">{t("checkout_checkout")}</p>
        </div>
        <div className="flex flex-col md:flex-row md:px-40 my-20 justify-between items-center">
          <div className="flex flex-col items-start justify-center order-2 md:order-none">
            <div className="flex justify-center w-full">
              <div className="flex bg-black px-3 py-1 rounded-md">
                <p className="text-white">
                  {lesson?.type === "group"
                    ? t("checkout_class_start_date")
                    : t("checkout_class_date")}
                  :
                </p>

                <p className="text-white ml-2">
                  {lesson?.type === "group" ? newClassDate : enrolmentDate}
                </p>
              </div>
            </div>
            {lesson?.type === "group" && (
              <div className="flex mt-2 justify-center w-full">
                <div className="flex bg-black px-3 py-1 rounded-md">
                  <p className="text-white">{t("checkout_date_renewal")}:</p>
                  <p className="text-white ml-2">{newRenewalClassDate}</p>
                </div>
              </div>
            )}
            <div className="flex mt-8 justify-center w-full">
              {cards.length > 0 ? (
                <div className="flex flex-col">
                  {cards.map((card) => {
                    return <PaymentCard card={card} key={card.id} />;
                  })}
                  <div className="flex justify-center w-full mt-2">
                    <Button
                      color="secondary"
                      onClick={() => setAddCardOpen(true)}
                    >
                      {t("general_add_new_card")}
                    </Button>
                  </div>
                </div>
              ) : (
                <AddCardForm />
              )}
            </div>
            <div className="flex items-center mt-12">
              <Checkbox
                checked={checked}
                onChange={() => {
                  setChecked(!checked);
                }}
              />
              <p>{t("checkout_terms_conditons")}</p>
            </div>
            <div className="flex w-full justify-center border-t border-border-gray pt-2 mt-4">
              <Button
                type="submit"
                classNames="mt-4"
                color="secondary"
                isDisable={!cards.length > 0 || !checked}
                onClick={() => {
                  dispatch(
                    buyLesson(
                      lesson?._id,
                      lesson?.teacherId?._id,
                      newClassDate ? newClassDate : enrolmentDate,
                      history
                    )
                  );
                }}
              >
                {t("checkout_complete_payment")}
              </Button>
            </div>
          </div>
          <div className="flex flex-col md:w-96 border border-border-gray rounded-md mt-6 ml-10 p-4 order-1 md:order-none">
            <div className="flex border-b border-border-gray pb-2">
              <img
                src={lesson?.images[0]}
                alt="lessonImage"
                className="w-24 h-24 object-cover rounded-md"
              />
              <div className="flex flex-col ml-2 ">
                <p className="text-xs font-medium">{lesson?.title}</p>
                <div className="flex mt-1">
                  <p className="bg-secondary-main px-1.5 py-0.5 rounded-md text-white text-xs capitalize">
                    {lesson?.classType}
                  </p>
                  <p className="bg-secondary-main px-1.5 py-0.5 ml-0.5 rounded-md text-white text-xs capitalize">
                    {lesson?.language}
                  </p>
                  <p className="bg-secondary-main px-1.5 py-0.5 ml-0.5 rounded-md text-white text-xs capitalize">
                    {lesson?.type}
                  </p>
                </div>
                <div className="flex items-center">
                  {lesson?.avgRating && (
                    <div className="flex items-center">
                      <p
                        className="mr-1 mt-0.5 text-xs"
                        style={{ color: "red" }}
                      >
                        {parseFloat(lesson?.avgRating).toFixed(1)}
                      </p>
                      <StarRatings
                        starRatedColor="red"
                        starDimension={"8px"}
                        starSpacing={"0.5px"}
                        rating={lesson?.avgRating}
                      />
                    </div>
                  )}
                  {lesson?.reviews?.length > 0 && (
                    <p className="ml-1 mt-1 text-xs font-light capitalize">
                      ({lesson?.reviews?.length + " " + t("general_ratings")})
                    </p>
                  )}
                </div>
                {lesson?.totalStudents > 0 && (
                  <p className="mt-1 text-xs font-light capitalize">
                    {lesson?.totalStudents + " " + t("general_students")}
                  </p>
                )}
              </div>
            </div>
            <div className="flex flex-col mt-3">
              <p className="underline">{t("checkout_price_details")}</p>
              <div className="flex justify-between">
                <p className="font-bold">{t("checkout_total")}:</p>

                {lesson.discount ? (
                  <div className="flex">
                    <span className="line-through font-bold">
                      {"€ " + lesson?.price}
                    </span>
                    <span>
                      <p className="ml-2 font-bold">{"€ " + discountedPrice}</p>
                    </span>
                  </div>
                ) : (
                  <p className="font-bold">{"€ " + lesson?.price}</p>
                )}
              </div>
            </div>
            {lesson?.type === "group" && (
              <p className="text-xs text-justify mt-3 border-t border-border-gray pt-2">
                {t("checkout_group_desclaimer_text")}
              </p>
            )}
          </div>
        </div>
        <WhatsappLink />
        <Footer />
      </div>
      <AddCardModal
        open={addCardOpen}
        onClose={() => {
          setAddCardOpen(false);
        }}
      />
    </>
  );
}

export default Checkout;
