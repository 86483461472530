import { HeaderActionTypes } from "../redux/actionTypes";
const INITIAL_STATE: HeaderState = {
  activeTab: "My Profile",
};
interface Action {
  payload: any;
  type: string;
}
const HeaderReducer = (
  state: HeaderState = INITIAL_STATE,
  action: Action
): HeaderState => {
  switch (action.type) {
    case HeaderActionTypes.SET_ACTIVE_TAB: {
      return { ...state, activeTab: action.payload };
    }
    default: {
      return state;
    }
  }
};
export default HeaderReducer;
