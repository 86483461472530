import {
  getLessonsUrl,
  getHomeScreenLessonsUrl,
  getLessonbyIdUrl,
  createLessonUrl,
  getLessonsByTeacherIdUrl,
  showHideLessonUrl,
  deleteLessonUrl,
  updateLessonUrl,
  getSubscribedLessonsUrl,
  getSubscribersByLessonIdUrl,
  addReviewUrl,
  getTeachersByStudentIdUrl,
  unSubscribeLessonUrl,
  getStudentsByTeacherIdUrl,
  getTopTeachersUrl,
  saveLessonDraftUrl,
  getLessonDraftByTeacherIdUrl,
  deleteLessonDraftUrl,
  getDraftLessonbyIdUrl,
  updateLessonDraftUrl,
  getCalendarDatesByTeacherIdUrl,
} from "@api/Endpoint";
import { axiosInstance as axios } from "@api/axios";
import { LessonActionTypes } from "../redux/actionTypes";
import { toast } from "react-toastify";
import { setActiveTab } from "@store/header/HeaderActions";
import { getTeacherPublicProfileDetails } from "@store/auth/AuthActions";

export const getLessons = (page, limit, filter) => {
  return (dispatch) => {
    dispatch({
      type: LessonActionTypes.GET_LESSONS_START,
      payload: {
        currentPage: page,
      },
    });
    const url = getLessonsUrl(page, limit, filter);

    axios
      .get(url)
      .then((res) => {
        const { data } = res.data;
        if (data) {
          getLessonsSuccess(
            dispatch,
            data.lessons,
            data.totalPages,
            data.currentPage,
            data.filters
          );
        } else {
          getLessonsFail(dispatch, "There was an error connection");
        }
      })
      .catch((error) => {
        getLessonsFail(dispatch, "There was an error connection2");
      });
  };
};
const getLessonsFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: LessonActionTypes.GET_LESSONS_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const getLessonsSuccess = (
  dispatch,
  lessons,
  totalPages,
  currentPage,
  filters
) => {
  dispatch({
    type: LessonActionTypes.GET_LESSONS_SUCCESS,
    payload: {
      lessons,
      totalPages,
      currentPage,
      filters,
    },
  });
};

export const getHomeScreenLessons = () => {
  return (dispatch) => {
    dispatch({
      type: LessonActionTypes.GET_HOME_SCREEN_LESSONS_START,
      payload: {},
    });
    const url = getHomeScreenLessonsUrl();

    axios
      .get(url)
      .then((res) => {
        const { data } = res.data;
        if (data) {
          getHomeScreenLessonsSuccess(
            dispatch,
            data.topLessons,
            data.childrenOnetoOneLessons,
            data.experienceLessons
          );
        } else {
          getHomeScreenLessonsFail(dispatch, "There was an error connection");
        }
      })
      .catch((error) => {
        getHomeScreenLessonsFail(dispatch, "There was an error connection2");
      });
  };
};
const getHomeScreenLessonsFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: LessonActionTypes.GET_HOME_SCREEN_LESSONS_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const getHomeScreenLessonsSuccess = (
  dispatch,
  topLessons,
  childrenOnetoOneLessons,
  experienceLessons
) => {
  dispatch({
    type: LessonActionTypes.GET_HOME_SCREEN_LESSONS_SUCCESS,
    payload: {
      topLessons,
      childrenOnetoOneLessons,
      experienceLessons,
    },
  });
};

export const getLessonbyId = (lessonId) => {
  return (dispatch) => {
    dispatch({
      type: LessonActionTypes.GET_LESSON_BY_ID_START,
    });

    const url = getLessonbyIdUrl(lessonId);
    axios
      .get(url)
      .then((res) => {
        let { data } = res.data;
        if (data) {
          getLessonbyIdSuccess(dispatch, data);
        } else {
          getLessonbyIdFail(dispatch, "There was an error connection");
        }
      })
      .catch((error) => {
        getLessonbyIdFail(dispatch, "There was an error connection2");
      });
  };
};
const getLessonbyIdFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: LessonActionTypes.GET_LESSON_BY_ID_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const getLessonbyIdSuccess = (dispatch, lesson) => {
  dispatch({
    type: LessonActionTypes.GET_LESSON_BY_ID_SUCCESS,
    payload: { lesson },
  });
};

export const createLesson = (data, history, draftId) => {
  return (dispatch) => {
    dispatch({
      type: LessonActionTypes.CREATE_LESSON_START,
    });

    const url = createLessonUrl();
    data["draftId"] = draftId;
    axios
      .post(url, data)
      .then((res) => {
        let { data } = res.data;
        if (data.result) {
          createLessonSuccess(dispatch, data, res.data.message, history);
        } else {
          createLessonFail(dispatch, "There was an error connection");
        }
      })
      .catch((error) => {
        console.log(error.message);
        toast.error(error.response.data.message);
        createLessonFail(dispatch, "There was an error connection2");
      });
  };
};
const createLessonFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: LessonActionTypes.CREATE_LESSON_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const createLessonSuccess = (dispatch, data, message, history) => {
  dispatch({
    type: LessonActionTypes.CREATE_LESSON_SUCCESS,
    payload: data,
  });
  // toast.success(message);
  dispatch(setActiveTab("View Ads"));
  dispatch(setOpenSuccessModal(true));
  history.push("/teacherProfile");
};

export const getLessonsByTeacherId = (teacherId, page, limit, type) => {
  return (dispatch) => {
    dispatch({
      type: LessonActionTypes.GET_LESSONS_START,
      payload: {
        currentPage: page,
      },
    });
    const url = getLessonsByTeacherIdUrl(teacherId, page, limit, type);

    axios
      .get(url)
      .then((res) => {
        const { data } = res.data;
        if (data) {
          getLessonsByTeacherIdSuccess(
            dispatch,
            data.lessons,
            data.totalPages,
            data.currentPage
          );
        } else {
          getLessonsByTeacherIdFail(dispatch, "There was an error connection");
        }
      })
      .catch((error) => {
        getLessonsByTeacherIdFail(dispatch, "There was an error connection2");
      });
  };
};
const getLessonsByTeacherIdFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: LessonActionTypes.GET_LESSONS_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const getLessonsByTeacherIdSuccess = (
  dispatch,
  lessons,
  totalPages,
  currentPage
) => {
  dispatch({
    type: LessonActionTypes.GET_LESSONS_SUCCESS,
    payload: {
      lessons,
      totalPages,
      currentPage,
    },
  });
};

export const showHideLesson = (lessonId) => {
  return (dispatch) => {
    dispatch({
      type: LessonActionTypes.SHOW_HIDE_LESSON_START,
    });

    const data = {
      lessonId,
    };

    const url = showHideLessonUrl();
    axios
      .put(url, data)
      .then((res) => {
        let { data } = res.data;
        if (data.result) {
          showHideLessonSuccess(dispatch, data);
        } else {
          showHideLessonFail(dispatch, "There was an error connection");
        }
      })
      .catch((error) => {
        console.log(error.message);
        showHideLessonFail(dispatch, "There was an error connection2");
      });
  };
};
const showHideLessonFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: LessonActionTypes.SHOW_HIDE_LESSON_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const showHideLessonSuccess = (dispatch, data) => {
  dispatch({
    type: LessonActionTypes.SHOW_HIDE_LESSON_SUCCESS,
    payload: data,
  });
};

export const deleteLesson = (lessonId, isAll, tab) => {
  return (dispatch) => {
    dispatch({
      type: LessonActionTypes.DELETE_LESSON_START,
    });

    const request = {
      data: { lessonId },
    };

    const url = deleteLessonUrl();
    axios
      .delete(url, request)
      .then((res) => {
        let { data } = res.data;
        deleteLessonSuccess(dispatch, data, isAll, res.data.message, tab);
      })
      .catch((error) => {
        console.log(error.message);
        deleteLessonFail(dispatch, "There was an error connection2");
      });
  };
};
const deleteLessonFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: LessonActionTypes.DELETE_LESSON_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const deleteLessonSuccess = (dispatch, data, isAll, message, tab) => {
  dispatch({
    type: LessonActionTypes.DELETE_LESSON_SUCCESS,
    payload: data,
  });
  dispatch(
    getLessonsByTeacherId(
      localStorage.getItem("userId"),
      1,
      isAll ? 10 : 5,
      tab
    )
  );
  toast.success(message);
};

export const updateLesson = (lessonId, data, history) => {
  return (dispatch) => {
    dispatch({
      type: LessonActionTypes.SHOW_HIDE_LESSON_START,
    });

    let request = data;
    request["lessonId"] = lessonId;

    const url = updateLessonUrl();
    axios
      .put(url, request)
      .then((res) => {
        let { data } = res.data;
        if (data.result) {
          updateLessonSuccess(dispatch, data, res.data.message, history);
        } else {
          updateLessonFail(dispatch, "There was an error connection");
        }
      })
      .catch((error) => {
        console.log(error.message);
        updateLessonFail(dispatch, "There was an error connection2");
      });
  };
};
const updateLessonFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: LessonActionTypes.SHOW_HIDE_LESSON_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const updateLessonSuccess = (dispatch, data, message, history) => {
  dispatch({
    type: LessonActionTypes.SHOW_HIDE_LESSON_SUCCESS,
    payload: data,
  });
  toast.success(message);
  dispatch(setActiveTab("View Ads"));
  history.push("/teacherProfile");
};

export const setLessonId = (lessonId) => {
  return (dispatch) => {
    dispatch({
      type: LessonActionTypes.SET_LESSON_ID,
      payload: lessonId,
    });
  };
};

export const setLessonType = (lessonType) => {
  return (dispatch) => {
    dispatch({
      type: LessonActionTypes.SET_LESSON_TYPE,
      payload: lessonType,
    });
  };
};

export const setFilterActive = (filterActive) => {
  return (dispatch) => {
    dispatch({
      type: LessonActionTypes.SET_FILTER_ACTIVE,
      payload: filterActive,
    });
  };
};

export const setFilter = (filter) => {
  return (dispatch) => {
    dispatch({
      type: LessonActionTypes.SET_FILTER,
      payload: filter,
    });
  };
};

export const setFilterValues = (filter) => {
  return (dispatch) => {
    dispatch({
      type: LessonActionTypes.SET_FILTER_VALUES,
      payload: filter,
    });
  };
};

export const setIsFilterVisible = (isVisible) => {
  return (dispatch) => {
    dispatch({
      type: LessonActionTypes.SET_IS_FILTER_VISIBLE,
      payload: isVisible,
    });
  };
};

export const getSubscribedLessons = (page, limit) => {
  return (dispatch) => {
    dispatch({
      type: LessonActionTypes.GET_LESSONS_START,
      payload: {
        currentPage: page,
      },
    });
    const url = getSubscribedLessonsUrl(page, limit);

    axios
      .get(url)
      .then((res) => {
        const { data } = res.data;
        if (data) {
          getSubscribedLessonsSuccess(
            dispatch,
            data.lessons,
            data.totalPages,
            data.currentPage
          );
        } else {
          getSubscribedLessonsFail(dispatch, "There was an error connection");
        }
      })
      .catch((error) => {
        getSubscribedLessonsFail(dispatch, "There was an error connection2");
      });
  };
};
const getSubscribedLessonsFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: LessonActionTypes.GET_LESSONS_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const getSubscribedLessonsSuccess = (
  dispatch,
  lessons,
  totalPages,
  currentPage
) => {
  dispatch({
    type: LessonActionTypes.GET_LESSONS_SUCCESS,
    payload: {
      lessons,
      totalPages,
      currentPage,
    },
  });
};

export const getSubscribersByLessonId = (lessonId) => {
  return (dispatch) => {
    dispatch({
      type: LessonActionTypes.GET_SUBSCRIBERS_START,
    });
    const url = getSubscribersByLessonIdUrl(lessonId);

    axios
      .get(url)
      .then((res) => {
        const { data } = res.data;
        if (data) {
          getSubscribersByLessonIdSuccess(dispatch, data);
        } else {
          getSubscribersByLessonIdFail(
            dispatch,
            "There was an error connection"
          );
        }
      })
      .catch((error) => {
        getSubscribersByLessonIdFail(
          dispatch,
          "There was an error connection2"
        );
      });
  };
};
const getSubscribersByLessonIdFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: LessonActionTypes.GET_SUBSCRIBERS_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const getSubscribersByLessonIdSuccess = (dispatch, data) => {
  dispatch({
    type: LessonActionTypes.GET_SUBSCRIBERS_SUCCESS,
    payload: data,
  });
};

export const addReview = (data) => {
  return (dispatch) => {
    dispatch({
      type: LessonActionTypes.ADD_REVIEW_START,
    });

    const url = addReviewUrl();
    const { type, teacherId } = data;
    axios
      .post(url, data)
      .then((res) => {
        let { data } = res.data;
        if (data.result) {
          addReviewSuccess(dispatch, data, type, teacherId, res.data.message);
        } else {
          addReviewFail(dispatch, "There was an error connection");
        }
      })
      .catch((error) => {
        console.log(error.message);
        toast.error(error.response.data.message);
        addReviewFail(dispatch, "There was an error connection2");
      });
  };
};
const addReviewFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: LessonActionTypes.ADD_REVIEW_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const addReviewSuccess = (dispatch, data, type, teacherId, message) => {
  dispatch({
    type: LessonActionTypes.ADD_REVIEW_SUCCESS,
    payload: data,
  });
  if (type === "lessson") {
    dispatch(getSubscribedLessons(1, 10));
  } else if (type === "teacher") {
    dispatch(getTeacherPublicProfileDetails(teacherId));
  }
  toast.success(message);
};

export const getTeachersByStudentId = (page, limit) => {
  return (dispatch) => {
    dispatch({
      type: LessonActionTypes.GET_LESSONS_START,
      payload: {
        currentPage: page,
      },
    });

    const url = getTeachersByStudentIdUrl(page, limit);

    axios
      .get(url)
      .then((res) => {
        const { data } = res.data;

        if (data) {
          getTeachersByStudentIdSuccess(
            dispatch,
            data.teachers,
            data.totalPages,
            data.currentPage
          );
        } else {
          getTeachersByStudentIdFail(dispatch, "There was an error connection");
        }
      })
      .catch((error) => {
        getTeachersByStudentIdFail(dispatch, "There was an error connection2");
      });
  };
};
const getTeachersByStudentIdFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: LessonActionTypes.GET_TEACHERS_BY_STUDENT_ID_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const getTeachersByStudentIdSuccess = (
  dispatch,
  teachers,
  totalPages,
  currentPage
) => {
  dispatch({
    type: LessonActionTypes.GET_TEACHERS_BY_STUDENT_ID_SUCCESS,
    payload: { teachers, totalPages, currentPage },
  });
};

export const unSubscribeLesson = (lessonId, isAll) => {
  return (dispatch) => {
    dispatch({
      type: LessonActionTypes.SHOW_HIDE_LESSON_START,
    });

    const request = { lessonId };

    const url = unSubscribeLessonUrl();
    axios
      .put(url, request)
      .then((res) => {
        let { data } = res.data;
        if (data) {
          unSubscribeLessonSuccess(dispatch, data, res.data.message, isAll);
        } else {
          unSubscribeLessonFail(dispatch, "There was an error connection");
        }
      })
      .catch((error) => {
        console.log(error.message);
        unSubscribeLessonFail(dispatch, "There was an error connection2");
      });
  };
};
const unSubscribeLessonFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: LessonActionTypes.SHOW_HIDE_LESSON_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const unSubscribeLessonSuccess = (dispatch, data, message, isAll) => {
  dispatch({
    type: LessonActionTypes.SHOW_HIDE_LESSON_SUCCESS,
    payload: data,
  });
  toast.success(message);
  if (isAll) {
    dispatch(getSubscribedLessons(1, 10));
  } else {
    dispatch(getSubscribedLessons(1, 2));
  }
};

export const setOpenSuccessModal = (open) => {
  return (dispatch) => {
    dispatch({
      type: LessonActionTypes.OPEN_SUCCESS_MODAL,
      payload: open,
    });
  };
};

export const getStudentsByTeacherId = () => {
  return (dispatch) => {
    dispatch({
      type: LessonActionTypes.GET_SUBSCRIBERS_START,
    });
    const url = getStudentsByTeacherIdUrl();

    axios
      .get(url)
      .then((res) => {
        const { data } = res.data;
        if (data) {
          getStudentsByTeacherIdSuccess(dispatch, data);
        } else {
          getStudentsByTeacherIdFail(dispatch, "There was an error connection");
        }
      })
      .catch((error) => {
        getStudentsByTeacherIdFail(dispatch, "There was an error connection2");
      });
  };
};
const getStudentsByTeacherIdFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: LessonActionTypes.GET_SUBSCRIBERS_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const getStudentsByTeacherIdSuccess = (dispatch, data) => {
  dispatch({
    type: LessonActionTypes.GET_SUBSCRIBERS_SUCCESS,
    payload: data,
  });
};

export const getTopTeachers = () => {
  return (dispatch) => {
    dispatch({
      type: LessonActionTypes.GET_TOP_TEACHERS_START,
      payload: {},
    });
    const url = getTopTeachersUrl();

    axios
      .get(url)
      .then((res) => {
        const { data } = res.data;
        if (data) {
          getTopTeachersSuccess(dispatch, data);
        } else {
          getTopTeachersFail(dispatch, "There was an error connection");
        }
      })
      .catch((error) => {
        getTopTeachersFail(dispatch, "There was an error connection2");
      });
  };
};
const getTopTeachersFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: LessonActionTypes.GET_TOP_TEACHERS_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const getTopTeachersSuccess = (dispatch, data) => {
  dispatch({
    type: LessonActionTypes.GET_TOP_TEACHERS_SUCCESS,
    payload: data,
  });
};

export const saveLessonDraft = (data, history) => {
  return (dispatch) => {
    dispatch({
      type: LessonActionTypes.CREATE_LESSON_START,
    });

    const url = saveLessonDraftUrl();
    axios
      .post(url, data)
      .then((res) => {
        let { data } = res.data;
        if (data.result) {
          saveLessonDraftSuccess(dispatch, data, res.data.message, history);
        } else {
          saveLessonDraftFail(dispatch, "There was an error connection");
        }
      })
      .catch((error) => {
        console.log(error.message);
        toast.error(error.response.data.message);
        saveLessonDraftFail(dispatch, "There was an error connection2");
      });
  };
};
const saveLessonDraftFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: LessonActionTypes.CREATE_LESSON_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const saveLessonDraftSuccess = (dispatch, data, message, history) => {
  dispatch({
    type: LessonActionTypes.CREATE_LESSON_SUCCESS,
    payload: data,
  });
  toast.success(message);
  dispatch(setActiveTab("View Drafts"));
  history.push("/teacherProfile");
};

export const getLessonDraftByTeacherId = (page, limit) => {
  return (dispatch) => {
    dispatch({
      type: LessonActionTypes.GET_LESSONS_START,
      payload: {
        currentPage: page,
      },
    });
    const url = getLessonDraftByTeacherIdUrl(page, limit);

    axios
      .get(url)
      .then((res) => {
        const { data } = res.data;
        if (data) {
          getLessonDraftByTeacherIdSuccess(
            dispatch,
            data.lessons,
            data.totalPages,
            data.currentPage
          );
        } else {
          getLessonDraftByTeacherIdFail(
            dispatch,
            "There was an error connection"
          );
        }
      })
      .catch((error) => {
        getLessonDraftByTeacherIdFail(
          dispatch,
          "There was an error connection2"
        );
      });
  };
};
const getLessonDraftByTeacherIdFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: LessonActionTypes.GET_LESSONS_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const getLessonDraftByTeacherIdSuccess = (
  dispatch,
  lessons,
  totalPages,
  currentPage
) => {
  dispatch({
    type: LessonActionTypes.GET_LESSONS_SUCCESS,
    payload: {
      lessons,
      totalPages,
      currentPage,
    },
  });
};

export const deleteLessonDraft = (lessonId) => {
  return (dispatch) => {
    dispatch({
      type: LessonActionTypes.DELETE_LESSON_START,
    });

    const url = deleteLessonDraftUrl(lessonId);
    axios
      .delete(url)
      .then((res) => {
        let { data } = res.data;
        deleteLessonDraftSuccess(dispatch, data, res.data.message);
      })
      .catch((error) => {
        console.log(error.message);
        deleteLessonDraftFail(dispatch, "There was an error connection2");
      });
  };
};
const deleteLessonDraftFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: LessonActionTypes.DELETE_LESSON_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const deleteLessonDraftSuccess = (dispatch, data, message) => {
  dispatch({
    type: LessonActionTypes.DELETE_LESSON_SUCCESS,
    payload: data,
  });

  toast.success(message);
  dispatch(getLessonDraftByTeacherId(1, 10));
};

export const getDraftLessonbyId = (lessonId) => {
  return (dispatch) => {
    dispatch({
      type: LessonActionTypes.GET_LESSON_BY_ID_START,
    });

    const url = getDraftLessonbyIdUrl(lessonId);
    axios
      .get(url)
      .then((res) => {
        let { data } = res.data;
        if (data) {
          getDraftLessonbyIdSuccess(dispatch, data);
        } else {
          getDraftLessonbyIdFail(dispatch, "There was an error connection");
        }
      })
      .catch((error) => {
        getDraftLessonbyIdFail(dispatch, "There was an error connection2");
      });
  };
};
const getDraftLessonbyIdFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: LessonActionTypes.GET_LESSON_BY_ID_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const getDraftLessonbyIdSuccess = (dispatch, lesson) => {
  dispatch({
    type: LessonActionTypes.GET_LESSON_BY_ID_SUCCESS,
    payload: { lesson },
  });
};

export const updateLessonDraft = (lessonId, data, history) => {
  return (dispatch) => {
    dispatch({
      type: LessonActionTypes.SHOW_HIDE_LESSON_START,
    });

    let request = data;

    const url = updateLessonDraftUrl(lessonId);
    axios
      .put(url, request)
      .then((res) => {
        let { data } = res.data;
        if (data.result) {
          updateLessonDraftSuccess(dispatch, data, res.data.message, history);
        } else {
          updateLessonDraftFail(dispatch, "There was an error connection");
        }
      })
      .catch((error) => {
        console.log(error.message);
        updateLessonDraftFail(dispatch, "There was an error connection2");
      });
  };
};
const updateLessonDraftFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: LessonActionTypes.SHOW_HIDE_LESSON_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const updateLessonDraftSuccess = (dispatch, data, message, history) => {
  dispatch({
    type: LessonActionTypes.SHOW_HIDE_LESSON_SUCCESS,
    payload: data,
  });
  toast.success(message);
  dispatch(setActiveTab("View Drafts"));
  history.push("/teacherProfile");
};

export const getCalendarDatesByTeacherId = () => {
  return (dispatch) => {
    dispatch({
      type: LessonActionTypes.GET_CALENDAR_DATES_START,
    });

    const url = getCalendarDatesByTeacherIdUrl();
    axios
      .get(url)
      .then((res) => {
        let { data } = res.data;
        if (data.lessons) {
          getCalendarDatesByTeacherIdSuccess(dispatch, data.lessons);
        } else {
          getCalendarDatesByTeacherIdFail(
            dispatch,
            "There was an error connection"
          );
        }
      })
      .catch((error) => {
        getCalendarDatesByTeacherIdFail(
          dispatch,
          "There was an error connection2"
        );
      });
  };
};
const getCalendarDatesByTeacherIdFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: LessonActionTypes.GET_CALENDAR_DATES_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const getCalendarDatesByTeacherIdSuccess = (dispatch, lessons) => {
  dispatch({
    type: LessonActionTypes.GET_CALENDAR_DATES_SUCCESS,
    payload: { lessons },
  });
};
