import { combineReducers } from "redux";

import auth from "../auth/AuthReducers";
import lesson from "../lesson/LessonReducers";
import chat from "../chat/ChatReducers";
import message from "../message/MessageReducers";
import header from "../header/HeaderReducers";
import payment from "../payment/PaymentReducers";

const EnglishCafe = combineReducers({
  auth,
  lesson,
  chat,
  message,
  header,
  payment,
});

export default EnglishCafe;
