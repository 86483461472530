import { toast } from "react-toastify";

import {
  addCardUrl,
  removeCardUrl,
  getUserCardsUrl,
  makeDefaultCardUrl,
  makePaymentUrl,
  buyLessonUrl,
  getLessonPriceForTeacherUrl,
} from "@api/Endpoint";
import { axiosInstance as axios } from "@api/axios";
import { PaymentActionTypes } from "../redux/actionTypes";
import { setActiveTab } from "@store/header/HeaderActions";

export const addCard = (cardToken) => {
  return (dispatch) => {
    dispatch({
      type: PaymentActionTypes.ADD_CARD_START,
    });
    const request = {
      cardToken,
      userType: localStorage.getItem("userType"),
    };
    let url = addCardUrl();
    axios
      .post(url, request)
      .then((res) => {
        let { data } = res.data;
        if (data) {
          addCardSuccess(dispatch, data, res.data.message);
        } else {
          addCardFail(dispatch, "There was an error connection");
        }
      })
      .catch((error) => {
        console.log(error.message);
        toast.error(error.response.data.message);
        addCardFail(dispatch, "There was an error connection2");
      });
  };
};

const addCardFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: PaymentActionTypes.ADD_CARD_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const addCardSuccess = (dispatch, data, message) => {
  dispatch(getUserCards());
  dispatch({
    type: PaymentActionTypes.ADD_CARD_SUCCESS,
    payload: data,
  });
  toast.success(message);
};

export const removeCard = (cardId) => {
  return (dispatch) => {
    dispatch({
      type: PaymentActionTypes.REMOVE_CARD_START,
    });
    const request = {
      data: { cardId, userType: localStorage.getItem("userType") },
    };
    let url = removeCardUrl();
    axios
      .delete(url, request)
      .then((res) => {
        let { data } = res.data;
        if (data) {
          removeCardSuccess(dispatch, data, res.data.message);
        } else {
          removeCardFail(dispatch, "There was an error connection");
        }
      })
      .catch((error) => {
        console.log(error.message);
        toast.error(error.response.data.message);
        removeCardFail(dispatch, "There was an error connection2");
      });
  };
};

const removeCardFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: PaymentActionTypes.REMOVE_CARD_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const removeCardSuccess = (dispatch, data, message) => {
  dispatch(getUserCards());
  dispatch({
    type: PaymentActionTypes.REMOVE_CARD_SUCCESS,
    payload: data,
  });
  toast.success(message);
};

export const getUserCards = () => {
  return (dispatch) => {
    dispatch({
      type: PaymentActionTypes.GET_USER_CARDS_START,
    });

    let url = getUserCardsUrl(localStorage.getItem("userType"));
    axios
      .get(url)
      .then((res) => {
        let { data } = res.data;
        if (data) {
          getUserCardsSuccess(dispatch, data);
        } else {
          getUserCardsFail(dispatch, "There was an error connection");
        }
      })
      .catch((error) => {
        console.log(error.message);
        toast.error(error?.response?.data?.message);
        getUserCardsFail(dispatch, "There was an error connection2");
      });
  };
};

const getUserCardsFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: PaymentActionTypes.GET_USER_CARDS_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const getUserCardsSuccess = (dispatch, data) => {
  dispatch({
    type: PaymentActionTypes.GET_USER_CARDS_SUCCESS,
    payload: data,
  });
};

export const makeDefaultCard = (cardId) => {
  return (dispatch) => {
    dispatch({
      type: PaymentActionTypes.MAKE_DEFAULT_CARD_START,
    });
    const request = {
      cardId,
      userType: localStorage.getItem("userType"),
    };
    let url = makeDefaultCardUrl();
    axios
      .put(url, request)
      .then((res) => {
        let { data } = res.data;
        if (data) {
          makeDefaultCardSuccess(dispatch, data, res.data.message);
        } else {
          makeDefaultCardFail(dispatch, "There was an error connection");
        }
      })
      .catch((error) => {
        console.log(error.message);
        toast.error(error.response.data.message);
        makeDefaultCardFail(dispatch, "There was an error connection2");
      });
  };
};

const makeDefaultCardFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: PaymentActionTypes.MAKE_DEFAULT_CARD_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const makeDefaultCardSuccess = (dispatch, data, message) => {
  dispatch(getUserCards());
  dispatch({
    type: PaymentActionTypes.MAKE_DEFAULT_CARD_SUCCESS,
    payload: data,
  });
  toast.success(message);
};

export const makePayment = (payment) => {
  return (dispatch) => {
    dispatch({
      type: PaymentActionTypes.MAKE_PAYMENT_START,
    });
    const request = {
      amount: payment.amount,
      userType: localStorage.getItem("userType"),
    };
    let url = makePaymentUrl();
    axios
      .post(url, request)
      .then((res) => {
        let { data } = res.data;
        if (data) {
          makePaymentSuccess(dispatch, data, res.data.message);
        } else {
          makePaymentFail(dispatch, "There was an error connection");
        }
      })
      .catch((error) => {
        console.log(error.message);
        toast.error(error.response.data.message);
        makePaymentFail(dispatch, "There was an error connection2");
      });
  };
};

const makePaymentFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: PaymentActionTypes.MAKE_PAYMENT_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const makePaymentSuccess = (dispatch, data, message) => {
  dispatch(getUserCards());
  dispatch({
    type: PaymentActionTypes.MAKE_PAYMENT_SUCCESS,
    payload: data,
  });
  toast.success(message);
};

export const buyLesson = (lessonId, teacherId, enrolmentDate, history) => {
  return (dispatch) => {
    dispatch({
      type: PaymentActionTypes.BUY_LESSON_START,
    });

    const data = {
      lessonId,
      teacherId,
      enrolmentDate,
    };

    const url = buyLessonUrl();
    axios
      .post(url, data)
      .then((res) => {
        let { data } = res.data;
        buyLessonSuccess(dispatch, data, res.data.message, history);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(error.message);
        buyLessonFail(dispatch, "There was an error connection2");
      });
  };
};
const buyLessonFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: PaymentActionTypes.BUY_LESSON_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const buyLessonSuccess = (dispatch, data, message, history) => {
  dispatch({
    type: PaymentActionTypes.BUY_LESSON_SUCCESS,
    payload: data,
  });
  dispatch(setActiveTab("View Lessons"));
  history.push("/studentProfile");
  toast.success(message);
};

export const getLessonPriceForTeacher = () => {
  return (dispatch) => {
    dispatch({
      type: PaymentActionTypes.GET_LESSON_PRICE_TEACHER_START,
    });
    let url = getLessonPriceForTeacherUrl();
    axios
      .get(url)
      .then((res) => {
        let { data } = res.data;
        if (data) {
          getLessonPriceForTeacherSuccess(dispatch, data);
        } else {
          getLessonPriceForTeacherFail(
            dispatch,
            "There was an error connection"
          );
        }
      })
      .catch((error) => {
        console.log(error.message);
        toast.error(error.response.data.message);
        getLessonPriceForTeacherFail(
          dispatch,
          "There was an error connection2"
        );
      });
  };
};

const getLessonPriceForTeacherFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: PaymentActionTypes.GET_LESSON_PRICE_TEACHER_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const getLessonPriceForTeacherSuccess = (dispatch, data) => {
  dispatch({
    type: PaymentActionTypes.GET_LESSON_PRICE_TEACHER_SUCCESS,
    payload: data,
  });
};
