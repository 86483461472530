import React from "react";
import { Dialog } from "@material-ui/core";
import { Icon, AddCardForm } from "@components";
import { useTranslation } from "react-i18next";

function AddCardModal({ onClose, open }) {
  const { t } = useTranslation();
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <div className="flex flex-col justify-center items-center px-8 py-6">
        <Icon
          type="closeIconBlack"
          classNames="self-end cursor-pointer -mr-3"
          onClick={handleClose}
        />
        <p className="text-xl font-medium mb-4">{t("general_add_card_here")}</p>
        <Icon type="creditCardIcon" />
        <AddCardForm handleClose={handleClose} />
      </div>
    </Dialog>
  );
}

export default AddCardModal;
