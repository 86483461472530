import React from "react";
import { Dialog } from "@material-ui/core";

import { Icon } from "@components";

function SuccessModal({ onClose, open, mainText, headerText }) {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <div className="flex flex-col justify-center items-center px-8 py-6 md:w-96">
        <Icon
          type="closeIconBlack"
          classNames="self-end cursor-pointer -mr-3"
          onClick={handleClose}
        />
        <Icon type="successIcon" classNames="my-6" />
        <p className="text-xl font-medium mb-6 text-center">{headerText}</p>
        <div className="flex justify-center">
          <p className="text-md">{mainText}</p>
        </div>
      </div>
    </Dialog>
  );
}

export default SuccessModal;
