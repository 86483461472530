import React from "react";
import { Dialog } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { Icon, Review } from "@components";

function ReviewListModal({ onClose, open, reviews }) {
  const { t } = useTranslation();
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <div
        className="flex flex-col items-center px-8 py-6 md:w-96 overflow-y-auto styledScrollbar"
        style={{ height: "50vh" }}
      >
        <Icon
          type="closeIconBlack"
          classNames="self-end cursor-pointer -mr-3"
          onClick={handleClose}
        />
        <div className="flex items-center mb-6">
          <Icon type="ratingIcon" classNames="w-8 h-8" />
          <p className="ml-3 text-xl font-medium">
            {t("teacher_profile_all_reviews")}
          </p>
        </div>
        <div className="flex flex-col">
          {reviews &&
            reviews.length > 0 &&
            reviews.map((review) => {
              return (
                <Review
                  review={review}
                  key={review?._id}
                  classNames="border-dotted border-b-2 mb-4"
                />
              );
            })}
        </div>
      </div>
    </Dialog>
  );
}

export default ReviewListModal;
