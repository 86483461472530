import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ProfileLessonCard from "./ProfileLessonCard";
import { setLessonId, setLessonType } from "@store/lesson/LessonActions";
import { setActiveTab } from "@store/header/HeaderActions";
import { lessonCategories } from "@helpers/data";

function LessonList({
  lastLessonElementRef,
  lessonsData,
  isAll,
  tab,
  tabChange,
}) {
  const { t } = useTranslation();
  const [width, setWidth] = useState(window.innerWidth);

  const history = useHistory();
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => window.removeEventListener("resize", handleWindowSizeChange);
  }, []);
  const isMobile = width <= 600;
  const dispatch = useDispatch();
  const userType = localStorage.getItem("userType");
  return (
    <div
      className={`flex flex-col border border-border-gray bg-white py-4 rounded-md w-full`}
    >
      <div className="flex justify-between items-center mb-4 px-4 md:px-10 ">
        {userType === "Teacher" && isAll ? (
          <div className="flex justify-between w-full">
            {lessonCategories.map((category) => {
              return (
                <p
                  className={`capitalize text-sm md:text-md px-1 py-1  md:px-4 md:py-3 rounded-md ${
                    tab === category
                      ? "bg-black text-white"
                      : "duration-300 hover:bg-secondary-main hover:text-white  ease-in cursor-pointer"
                  }`}
                  key={category}
                  onClick={() => {
                    tabChange(category);
                  }}
                >
                  {category}
                </p>
              );
            })}
          </div>
        ) : (
          <p className="font-bold text-lg">
            {isAll
              ? t("general_all")
              : userType === "Teacher"
              ? t("profile_lesson_my_ads")
              : t("profile_lesson_my_lessons")}
          </p>
        )}
        {!isAll && (
          <p
            className="text-xs cursor-pointer"
            onClick={() => {
              if (userType === "Teacher") {
                dispatch(setActiveTab("View Ads"));
              } else {
                dispatch(setActiveTab("View Lessons"));
              }
            }}
          >
            {t("general_view_all")}
          </p>
        )}
      </div>
      <div
        className={`flex flex-col md:p-6 mr-1 ${
          isAll && "overflow-y-auto styledScrollbar"
        } px-4`}
        style={{ height: isAll && !isMobile && "calc(92vh - 13.5rem)" }}
      >
        {lessonsData && lessonsData.length > 0 ? (
          isAll ? (
            lessonsData.map((lesson, index) => {
              return (
                <ProfileLessonCard
                  lastLessonElementRef={lastLessonElementRef}
                  lesson={lesson}
                  isAll={isAll}
                  key={lesson.enrolmentId ? lesson.enrolmentId : lesson._id}
                  tab={tab}
                />
              );
            })
          ) : (
            lessonsData.slice(0, 2).map((lesson) => {
              return (
                <ProfileLessonCard
                  lastLessonElementRef={lastLessonElementRef}
                  lesson={lesson}
                  isAll={isAll}
                  key={lesson.enrolmentId ? lesson.enrolmentId : lesson._id}
                  tab={tab}
                />
              );
            })
          )
        ) : (
          <div className="flex items-center justify-center p-4 md:h-full">
            <p className="text-sm md:text-xl">
              {`${
                localStorage.getItem("userType") === "Teacher"
                  ? t("profile_lesson_no_lessons_to_show")
                  : t("profile_lesson_no_lessons_subscribed")
              } `}
              <span
                className="cursor-pointer underline"
                onClick={() => {
                  if (localStorage.getItem("userType") === "Teacher") {
                    dispatch(setActiveTab("Create Ads"));
                    dispatch(setLessonId(undefined));
                    dispatch(setLessonType(tab));
                    history.push("/teacherProfile");
                  } else {
                    history.push("/lessons");
                  }
                }}
              >
                {`${
                  localStorage.getItem("userType") === "Teacher"
                    ? t("profile_lesson_want_create_one")
                    : t("profile_lesson_want_subscribe_to_one")
                } `}
              </span>
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default LessonList;
