import { ChatActionTypes } from "../redux/actionTypes";
const INITIAL_STATE: ChatState = {
  chats: [],
  chatRecieverId: null,
  chatId: null,
  chatUserName: null,
  chatUserImage: null,
  chatBoxOpened: false,
  loading: false,
};
interface Action {
  payload: any;
  type: string;
}
const ChatReducer = (
  state: ChatState = INITIAL_STATE,
  action: Action
): ChatState => {
  switch (action.type) {
    case ChatActionTypes.GET_CHATS_START: {
      return { ...state, loading: true };
    }
    case ChatActionTypes.GET_CHATS_SUCCESS: {
      return {
        ...state,
        chats: action.payload.chats,
        loading: false,
      };
    }
    case ChatActionTypes.GET_CHATS_FAIL: {
      return {
        ...state,
        chats: action.payload,
        loading: false,
      };
    }

    case ChatActionTypes.CREATE_CHAT_START: {
      return { ...state, loading: true };
    }
    case ChatActionTypes.CREATE_CHAT_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case ChatActionTypes.CREATE_CHAT_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case ChatActionTypes.SET_USER_CHAT_DETAILS: {
      return {
        ...state,
        chatRecieverId: action.payload.recieverId,
        chatId: action.payload?.chatId && action.payload.chatId,
        chatUserName: action.payload.userName,
        chatUserImage: action.payload.userImage,
        chatBoxOpened: action.payload.chatOpened && action.payload.chatOpened,
      };
    }

    default: {
      return state;
    }
  }
};
export default ChatReducer;
