import React, { useState } from "react";
import { Divider, Drawer, Hidden, List } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

import { Icon } from "@components";
import { logoutUser } from "@helpers/utils";
import { setActiveTab } from "@store/header/HeaderActions";
import { languages } from "@helpers/data";
import {
  setFilter,
  setFilterActive,
  setFilterValues,
  setLessonId,
  setLessonType,
} from "@store/lesson/LessonActions";

function SideDrawer({ userType, isAuthenticated, user }) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [openDrawer, setOpenDrawer] = useState();
  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };
  return (
    <Hidden only={["md", "lg", "xl"]}>
      <div>
        <div
          className="self-center w-4 ml-2 mt-2"
          onClick={() => {
            setOpenDrawer(!openDrawer);
          }}
        >
          <Icon type="menuIcon" />
        </div>
        <Drawer onClose={handleDrawerClose} anchor="right" open={openDrawer}>
          <div
            style={{ width: "55vw" }}
            className="flex flex-col justify-center items-center"
          >
            <List>
              <div
                className="flex flex-col items-center mb-5"
                onClick={() => {
                  if (user?.onBoarded || !user) {
                    if (userType === "Student" || !userType) {
                      dispatch(
                        setFilterValues({
                          category: "",
                          language: "",
                          type: "",
                          city: "",
                        })
                      );
                      dispatch(setFilter({}));
                      dispatch(setFilterActive(false));
                      history.push("/lessons");
                    } else {
                      history.push("/teacherProfile");
                    }
                  }
                  handleDrawerClose();
                }}
              >
                <div className="flex items-center justify-center w-full my-3">
                  <Icon classNames="w-32" type="logo" />
                </div>
                <p className="text-sm font-bold ml-2 mb-2">
                  {t("header_language")}
                </p>
                <div className="flex">
                  {languages?.map((language) => {
                    return (
                      <div
                        onClick={() => {
                          i18next.changeLanguage(language?.code);
                        }}
                        key={language?.code}
                      >
                        <Icon classNames="ml-2 w-5 h-5" type={language?.icon} />
                      </div>
                    );
                  })}
                </div>
              </div>
              <Divider className="mb-6" />
              {!isAuthenticated ? (
                <div
                  className="flex items-center mb-5"
                  onClick={() => {
                    history.push("/signup?type=Student");
                    handleDrawerClose();
                  }}
                >
                  <Icon classNames="w-3" type="signupIcon" />
                  <p className="ml-4 text-md font-medium">
                    {t("general_signup")}
                  </p>
                </div>
              ) : (
                <>
                  {user?.onBoarded && (
                    <>
                      <div
                        className="flex items-center mb-5"
                        onClick={() => {
                          dispatch(setActiveTab("My Profile"));
                          if (userType === "Teacher") {
                            history.push("/teacherProfile");
                          } else {
                            history.push("/studentProfile");
                          }
                          handleDrawerClose();
                        }}
                      >
                        <Icon classNames="w-3" type="userIcon" />
                        <p className="ml-4 text-md font-medium">
                          {t("navbar_my_profile")}
                        </p>
                      </div>
                      <div
                        className="flex items-center mb-5"
                        onClick={() => {
                          dispatch(setActiveTab("Edit Profile"));
                          if (userType === "Teacher") {
                            history.push("/teacherProfile");
                          } else {
                            history.push("/studentProfile");
                          }
                          handleDrawerClose();
                        }}
                      >
                        <Icon classNames="w-3" type="editIconBlack" />
                        <p className="ml-4 text-md font-medium">
                          {t("navbar_edit_profile")}
                        </p>
                      </div>
                      {userType === "Teacher" && (
                        <div
                          className="flex items-center mb-5"
                          onClick={() => {
                            dispatch(setActiveTab("Create Ads"));
                            dispatch(setLessonId(undefined));
                            dispatch(setLessonType(undefined));
                            history.push("/teacherProfile");
                            handleDrawerClose();
                          }}
                        >
                          <Icon classNames="w-3" type="createAdIcon" />
                          <p className="ml-4 text-md font-medium">
                            {t("navbar_create_ads")}
                          </p>
                        </div>
                      )}
                      <div
                        className="flex items-center mb-5"
                        onClick={() => {
                          if (userType === "Teacher") {
                            dispatch(setActiveTab("View Ads"));
                            history.push("/teacherProfile");
                          } else {
                            dispatch(setActiveTab("View Lessons"));
                            history.push("/studentProfile");
                          }
                          handleDrawerClose();
                        }}
                      >
                        <Icon classNames="w-3" type="viewAdIcon" />
                        <p className="ml-4 text-md font-medium">
                          {userType === "Teacher"
                            ? t("navbar_view_ads")
                            : t("navbar_view_lessons")}
                        </p>
                      </div>
                      {userType === "Teacher" && (
                        <div
                          className="flex items-center mb-5"
                          onClick={() => {
                            dispatch(setActiveTab("View Drafts"));
                            history.push("/teacherProfile");

                            handleDrawerClose();
                          }}
                        >
                          <Icon classNames="w-3" type="viewDraftIcon" />
                          <p className="ml-4 text-md font-medium">
                            {t("profile_tab_view_drafts")}
                          </p>
                        </div>
                      )}
                      <div
                        className="flex items-center mb-5"
                        onClick={() => {
                          dispatch(setActiveTab("Messages"));
                          if (userType === "Teacher") {
                            history.push("/teacherProfile");
                          } else {
                            history.push("/studentProfile");
                          }
                          handleDrawerClose();
                        }}
                      >
                        <Icon classNames="w-3" type="messageIcon" />
                        <p className="ml-4 text-md font-medium">
                          {t("navbar_messages")}
                        </p>
                      </div>
                      <div
                        className="flex items-center mb-5"
                        onClick={() => {
                          dispatch(setActiveTab("Payment Methods"));
                          if (userType === "Teacher") {
                            history.push("/teacherProfile");
                          } else {
                            history.push("/studentProfile");
                          }
                          handleDrawerClose();
                        }}
                      >
                        <Icon classNames="w-3" type="paymentMethodIcon" />
                        <p className="ml-4 text-md font-medium">
                          {t("navbar_payment_methods")}
                        </p>
                      </div>
                      {userType === "Teacher" && (
                        <div
                          className="flex items-center mb-5"
                          onClick={() => {
                            dispatch(setActiveTab("Payment History"));
                            if (userType === "Teacher") {
                              history.push("/teacherProfile");
                            }
                            handleDrawerClose();
                          }}
                        >
                          <Icon classNames="w-3" type="paymentMethodIcon" />
                          <p className="ml-4 text-md font-medium">
                            {t("profile_tab_payment_history")}
                          </p>
                        </div>
                      )}
                    </>
                  )}
                  <div
                    className="flex items-center mb-5"
                    onClick={() => {
                      logoutUser(history, dispatch);
                      handleDrawerClose();
                    }}
                  >
                    <Icon classNames="w-3" type="logoutIcon2" />
                    <p className="ml-4 text-md font-medium">
                      {t("general_logout")}
                    </p>
                  </div>
                </>
              )}
              <a
                href="https://www.englishcafe.es/nuestro-blog"
                target="_blank"
                rel="noreferrer"
                className="flex items-center mb-5"
              >
                <Icon classNames="w-3" type="blogIcon" />
                <p className="ml-4 font-semibold cursor-pointer whitespace-nowrap text-sm ">
                  {t("header_blog")}
                </p>
              </a>
              <a
                href="https://www.englishcafe.es/como-funciona/"
                target="_blank"
                rel="noreferrer"
                className="flex items-center mb-5"
              >
                <Icon classNames="w-3" type="blogIcon" />
                <p className="ml-4 font-semibold cursor-pointer whitespace-nowrap text-sm ">
                  {t("header_how_does_it_work")}
                </p>
              </a>
            </List>
          </div>
        </Drawer>
      </div>
    </Hidden>
  );
}

export default SideDrawer;
