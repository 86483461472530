import React from "react";
import { useDispatch } from "react-redux";
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import { Button } from "@components";
import { addCard } from "@store/payment/PaymentActions";
import Icon from "@components/Icon";
import { Hidden } from "@material-ui/core";

function StripeAddCardForm({ handleClose }) {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardNumberElement);

    stripe
      .createToken(cardElement)
      .then((res) => {
        const cardToken = res.token;
        dispatch(addCard(cardToken));
        if (handleClose) {
          handleClose();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex w-56 md:w-80 border border-border-gray rounded-md px-4 py-3 relative">
        <div className="w-full mt-1">
          <CardNumberElement />
        </div>
        <Hidden smDown>
          <div className="flex justify-center items-center">
            <Icon classNames="w-10" type="visaCardIcon" />
            <Icon classNames="w-10 ml-1" type="masterCardIcon" />
          </div>
        </Hidden>
      </div>
      <div className="flex mt-2">
        <div className="w-28 md:w-40 border border-border-gray rounded-md px-4 py-3">
          <CardExpiryElement />
        </div>
        <div className="w-28 ml-1 md:w-40 border border-border-gray rounded-md px-4 py-3">
          <CardCvcElement />
        </div>
      </div>
      <Button classNames="mt-6 w-20" isDisable={!stripe} onClick={handleSubmit}>
        Add
      </Button>
    </div>
  );
}

export default StripeAddCardForm;
