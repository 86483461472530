import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Geocode from "react-geocode";

import { Button } from "@components";
import {
  categories,
  lessonTypes,
  search_Languages_list,
  search_levels_list,
} from "@helpers/data";
import { setFilterActive, setFilterValues } from "@store/lesson/LessonActions";
import { Hidden } from "@material-ui/core";

Geocode.setApiKey(process.env.REACT_APP_MAP_API_KEY);

function FilterSearchForm() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleFilterSubmit = async () => {
    let filterObj = {};
    if (
      filterValues.category &&
      filterValues.category !== "" &&
      filterValues.category !== "All"
    ) {
      filterObj["lessontype"] = filterValues.category;
    }
    // if (
    //   filterValues.language &&
    //   filterValues.language !== "" &&
    //   filterValues.language !== "All"
    // ) {
    //   filterObj["language"] = filterValues.language;
    // }
    if (
      filterValues.level &&
      filterValues.level !== "" &&
      filterValues.level !== "All"
    ) {
      filterObj["level"] = filterValues.level;
    }

    if (
      filterValues.type &&
      filterValues.type !== "" &&
      filterValues.type !== "All"
    ) {
      filterObj["type"] = filterValues.type;
      if (
        filterValues.type === "face-to-face" &&
        filterValues.city &&
        filterValues.city !== ""
      ) {
        filterObj["city"] = filterValues.city;
      }
    }
    let queryString = Object.keys(filterObj)
      .map((key) => key + "=" + filterObj[key])
      .join("&");

    if (queryString.length > 0) {
      history.push("/lessons?" + queryString);
      dispatch(setFilterActive(true));
    } else {
      history.push("/lessons");
      dispatch(setFilterActive(false));
    }
  };

  const { filterValues } = useSelector(({ EnglishCafe }) => EnglishCafe.lesson);

  const inputHandler = (event) => {
    const obj = {};
    const key = event.target.name;
    let value = event.target.value;
    if (key === "city") {
      value = value ? value : "emptyString";
    }
    obj[`${key}`] = value;
    dispatch(setFilterValues(obj));
    if (key === "type" && value !== "face-to-face") {
      dispatch(setFilterValues({ city: "emptyString" }));
    }
  };

  return (
    <>
      <Hidden smDown>
        <form
          className={`flex w-full items-center justify-between relative `}
          onSubmit={(event) => {
            event.preventDefault();
            handleFilterSubmit();
          }}
        >
          <div
            className="flex flex-col bg-white shadow-md border-black px-6 py-4 rounded-xl border"
            style={{ flex: "0.25" }}
          >
            <p className="text-xs mb-1"> {t("general_browse_category")}</p>

            <select
              name="category"
              className={`capitalize outline-none text-md border border-black rounded-md px-2 py-1.5`}
              value={filterValues.category}
              onChange={inputHandler}
            >
              {categories.map(({ label: option, value }) => (
                <option
                  className="capitalize bg-white text-black rounded"
                  value={value}
                  key={value}
                >
                  {t(option)}
                </option>
              ))}
            </select>
          </div>
          {/* <div
            className="flex flex-col bg-white shadow-md border-black px-6 py-4 rounded-xl border ml-4"
            style={{ flex: "0.25" }}
          >
            <p className="text-xs mb-1"> {t("general_browse_language")}</p>
            <select
              name="language"
              className={`capitalize outline-none text-md border border-black rounded-md px-2 py-1.5`}
              value={filterValues.language}
              onChange={inputHandler}
            >
              {search_Languages_list.map(({ label: option, value }) => (
                <option
                  className="capitalize bg-white text-black rounded"
                  value={value}
                  key={value}
                >
                  {t(option)}
                </option>
              ))}
            </select>
          </div> */}
          <div
            className="flex flex-col bg-white shadow-md border-black px-6 py-4 rounded-xl border ml-4"
            style={{ flex: "0.25" }}
          >
            <p className="text-xs mb-1"> {t("general_browse_level")}</p>
            <select
              name="level"
              className={`capitalize outline-none text-md border border-black rounded-md px-6 py-1.5`}
              value={filterValues.level}
              onChange={inputHandler}
            >
              {search_levels_list.map(({ label: option, value }) => (
                <option
                  className="capitalize bg-white text-black rounded"
                  value={value}
                  key={value}
                >
                  {t(option)}
                </option>
              ))}
            </select>
          </div>
          <div
            className="flex flex-col bg-white shadow-md border-black px-6 py-4 rounded-xl border ml-4"
            style={{ flex: "0.25" }}
          >
            <p className="text-xs mb-1"> {t("general_browse_type")}</p>
            <select
              name="type"
              className={`capitalize outline-none text-md border border-black rounded-md px-2 py-1.5`}
              value={filterValues.type}
              onChange={inputHandler}
            >
              {lessonTypes.map(({ label: option, value }) => (
                <option
                  className="capitalize bg-white text-black rounded"
                  value={value}
                  key={value}
                >
                  {t(option)}
                </option>
              ))}
            </select>
          </div>
          <div
            className="flex flex-col bg-white shadow-md border-black px-6 py-4 rounded-xl border ml-4"
            style={{ flex: "0.25" }}
          >
            <p className="text-xs mb-1">{t("general_browse_city")}</p>
            <input
              name="city"
              className={`outline-none text-sm border border-black px-2 py-1.5 rounded-md`}
              placeholder={t("general_browse_city_placeholder")}
              size="small"
              disabled={filterValues.type !== "face-to-face"}
              value={filterValues.city}
              onChange={inputHandler}
            />
          </div>
          <div
            className="bg-secondary-main cursor-pointer px-6 py-8 rounded-xl shadow-md ml-4"
            onClick={() => {
              handleFilterSubmit();
            }}
          >
            <p className="text-white">{t("general_search_button")}</p>
          </div>
        </form>
      </Hidden>
      <Hidden only={["md", "lg", "xl"]}>
        <form
          className={`flex flex-col md:flex-row md:ml-16 items-center justify-between px-2 py-4 md:px-5 md:py-3 rounded-xl relative
           w-full`}
          onSubmit={(event) => {
            event.preventDefault();
            handleFilterSubmit();
          }}
        >
          <div className="flex flex-col">
            <div className="flex justify-between">
              <div className="flex flex-col">
                <p className="text-xs mb-1"> {t("general_browse_category")}</p>
                <select
                  name="category"
                  className={`capitalize outline-none text-md w-36 border rounded-md px-2 py-1.5 border-text-gray`}
                  value={filterValues.category}
                  onChange={inputHandler}
                >
                  {categories.map(({ label: option, value }) => (
                    <option
                      className="capitalize bg-white text-black rounded"
                      value={value}
                      key={value}
                    >
                      {t(option)}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col ml-2">
                <p className="text-xs mb-1"> {t("general_browse_language")}</p>
                <select
                  name="language"
                  className={`capitalize outline-none text-md w-36 border rounded-md px-2 py-1.5 border-text-gray`}
                  value={filterValues.language}
                  onChange={inputHandler}
                >
                  {search_Languages_list.map(({ label: option, value }) => (
                    <option
                      className="capitalize bg-white text-black rounded"
                      value={value}
                      key={value}
                    >
                      {t(option)}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex items-center mt-2 justify-between">
              <div className="flex flex-col">
                <p className="text-xs mb-1"> {t("general_browse_type")}</p>
                <select
                  name="type"
                  className={`capitalize outline-none text-md w-36 border rounded-md px-2 py-1.5 border-text-gray`}
                  value={filterValues.type}
                  onChange={inputHandler}
                >
                  {lessonTypes.map(({ label: option, value }) => (
                    <option
                      className="capitalize bg-white text-black rounded"
                      value={value}
                      key={value}
                    >
                      {t(option)}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col ml-2">
                <p className="text-xs mb-1">{t("general_browse_city")}</p>
                <input
                  name="city"
                  className={`outline-none text-sm w-36 border px-2 py-1.5 rounded-md border-text-gray`}
                  placeholder={t("general_browse_city_placeholder")}
                  size="small"
                  disabled={filterValues.type !== "face-to-face"}
                  value={filterValues.city}
                  onChange={inputHandler}
                />
              </div>
            </div>
          </div>
          <Button
            color="secondary"
            size="medium"
            classNames="rounded-full md:ml-6 mt-2 md-mt-0"
            type="submit"
          >
            {t("general_search_button")}
          </Button>
        </form>
      </Hidden>
    </>
  );
}

export default FilterSearchForm;
