import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { LinearProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import {
  Button,
  Icon,
  Loader,
  MultiCheckbox,
  CustomPhoneInput,
  HeaderContainer,
  Select,
} from "@components";
import { lighterImage } from "@helpers/imageCompression";
import {
  Provinces,
  Countries_List,
  Languages_list,
  // Professions,
} from "@helpers/data";
import { isImage, pageAnalytics, S3ImageUploader } from "@helpers/utils";
import { onBoardUser } from "@store/auth/AuthActions";
import { TeacherInputs, StudentInputs } from "./helper/Inputs";
import { validate } from "@helpers/validators";

function OnBoarding() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const loading = useSelector(({ EnglishCafe }) => EnglishCafe.auth.loading);

  let [progress, setProgress] = useState(0);
  let [inputIndex, setInputIndex] = useState(0);
  let [inputData, setInputData] = useState([]);
  let [languages, setLanguages] = useState(Languages_list);
  let [extraLanguage, setExtraLanguage] = useState("");
  let [value, setValue] = useState("");
  const [imageUploading, setImageUploading] = useState(false);

  const userType = localStorage.getItem("userType");

  useEffect(() => {
    if (userType === "Teacher") {
      setInputData(TeacherInputs);
    } else {
      setInputData(StudentInputs);
    }
  }, [userType]);

  useEffect(() => {
    pageAnalytics(window);
  }, []);

  const inputHandler = (event) => {
    let validity;
    if (inputData[inputIndex]?.validators) {
      validity = validate(
        event.target.value,
        inputData[inputIndex]?.validators
      );
    }
    setValue(event.target.value);
    const newData = inputData.map((item) => {
      if (item.id === inputData[inputIndex]?.id) {
        return {
          ...item,
          value: event.target.value,
          validity,
        };
      } else {
        return item;
      }
    });
    setInputData(newData);
  };

  const uploadFile = async (event) => {
    let file1 = event.target.files[0];
    if (file1.type.split("/")[0] === "image") {
      if (!isImage(file1.type)) {
        toast.error(t("general_image_format_error"));
        return;
      }
    } else {
      toast.error(t("general_file_format_error"));
      return;
    }
    if (Math.floor(file1.size / 1048576) > 10) {
      toast.error(t("general_image_size_error"));
      return;
    }
    file1 = await lighterImage(file1);
    setValue(file1);
    const newData = inputData.map((item) => {
      if (item.id === inputData[inputIndex]?.id) {
        return {
          ...item,
          value: file1,
        };
      } else {
        return item;
      }
    });
    setInputData(newData);
  };

  const languageChangeHandler = (inputValues) => {
    if (inputData[inputIndex]?.id === "languages") {
      const inputLanguages = [];
      inputValues.forEach((e) => {
        if (e.value === true) {
          inputLanguages.push(e.label);
        }
      });

      let validity = inputLanguages.length > 0;

      const newData = inputData.map((item) => {
        if (item.id === inputData[inputIndex]?.id) {
          return {
            ...item,
            value: inputLanguages,
            validity,
          };
        } else {
          return item;
        }
      });
      setInputData(newData);
    }
  };
  const submitHandler = async () => {
    try {
      let imageUrl;
      if (value !== "") {
        imageUrl = await S3ImageUploader(
          value,
          localStorage.getItem("userId"),
          setLoading
        );
      }
      let data = {};
      inputData.forEach((e) => {
        if (e.id === "imageFile") {
          data[`${e.id}`] = imageUrl;
        } else {
          data[`${e.id}`] = e.value;
        }
      });

      dispatch(onBoardUser(data, history, userType));
    } catch (error) {
      console.log("error", error);
      toast.error(t("general_something_went_wrong_error"));
    }
  };

  const setLoading = (loading) => {
    setImageUploading(loading);
  };

  const addExtraLanguageHandler = () => {
    let temp = languages;
    temp.push({ label: extraLanguage, value: true });
    setExtraLanguage("");
    languageChangeHandler([...temp]);
    setLanguages([...temp]);
  };

  return (
    <>
      <Loader loading={imageUploading || loading} />
      {/* <div
        className="flex justify-center items-center w-full"
        style={{ height: "8vh" }}
      >
        <div>
          <Icon type="logo" />
        </div>
      </div> */}
      <HeaderContainer />
      <div
        className="flex flex-col px-4 py-32 md:w-4/12 md:py-56"
        style={{ margin: "0 auto" }}
      >
        <div>
          <p className="font-bold text-text-gray mb-2">
            {t("onBoarding_progress_text") +
              (inputIndex + 1) +
              " / " +
              inputData.length}
          </p>
        </div>
        <LinearProgress
          color="secondary"
          variant="determinate"
          value={parseInt(progress)}
        />
        <div className="mt-10 flex flex-col md:mt-20 ">
          {inputData && inputData.length > 1 && (
            <>
              <p className="font-bold text-3xl text-center">
                {t(inputData[inputIndex]?.heading)}
              </p>
              {inputData[inputIndex]?.id === "videoLink" && (
                <div className="flex justify-center">
                  <Icon type="youtubeIcon" classNames="w-6 h-6" />
                  <Icon type="vimeoIcon" classNames="w-6 h-6" />
                </div>
              )}
            </>
          )}

          <div className="flex justify-center mt-20">
            {(inputData &&
              inputData.length > 1 &&
              inputData[inputIndex]?.id === "province") ||
            inputData[inputIndex]?.id === "nationality" ? (
              <div className="w-60 md:w-72">
                <Select
                  id={inputData[inputIndex]?.id}
                  onChange={inputHandler}
                  value={value}
                  options={
                    inputData[inputIndex]?.id === "province"
                      ? Provinces
                      : Countries_List
                  }
                />
              </div>
            ) : inputData[inputIndex]?.id === "phoneNo" ? (
              <div className="flex w-60 md:w-72 justify-center">
                <CustomPhoneInput
                  inputStyle={{
                    width: "inherit",
                    height: "35px",
                    fontSize: "13px",
                    paddingLeft: "48px",
                    borderRadius: "5px",
                  }}
                  name="phoneNo"
                  onChange={inputHandler}
                  value={value}
                />
              </div>
            ) : inputData[inputIndex]?.id === "biography" ||
              inputData[inputIndex]?.id === "experiences" ||
              inputData[inputIndex]?.id === "aboutClasses" ? (
              <textarea
                className="bg-white w-80 md:w-10/12 p-4 rounded-md outline-none"
                placeholder={t(inputData[inputIndex].placeholder)}
                value={value}
                rows={6}
                onChange={inputHandler}
                style={{ boxShadow: "0px 2px 15px rgb(0 0 0 / 15%)" }}
              />
            ) : inputData[inputIndex]?.id === "languages" ? (
              <div className="flex flex-col items-center justify-center">
                <MultiCheckbox
                  items={languages}
                  setItems={setLanguages}
                  onChange={languageChangeHandler}
                />
                <form
                  className="flex w-80 md:w-10/12 mt-8 -mb-16 justify-center"
                  onSubmit={(event) => {
                    event.preventDefault();
                    addExtraLanguageHandler();
                  }}
                >
                  <input
                    className="bg-white w-60 md:w-7/12 text-center  p-4 rounded-md outline-none"
                    placeholder={t(inputData[inputIndex].placeholder)}
                    value={extraLanguage}
                    onChange={(event) => {
                      setExtraLanguage(event.target.value);
                    }}
                    style={{ boxShadow: "0px 2px 15px rgb(0 0 0 / 15%)" }}
                  />
                  <Button
                    classNames="ml-2"
                    color="secondary"
                    isDisable={extraLanguage === ""}
                    type="submit"
                  >
                    {t("general_add")}
                  </Button>
                </form>
              </div>
            ) : inputData[inputIndex]?.id === "videoLink" ||
              inputData[inputIndex]?.id === "profession" ? (
              <input
                className="bg-white w-80 md:w-10/12 p-4 rounded-md outline-none"
                placeholder={t(inputData[inputIndex].placeholder)}
                value={value}
                onChange={inputHandler}
                style={{ boxShadow: "0px 2px 15px rgb(0 0 0 / 15%)" }}
              />
            ) : (
              <>
                <input
                  type="file"
                  id="uploadImage"
                  accept="image/*"
                  className="hidden"
                  value={""}
                  onChange={uploadFile}
                />
                <label htmlFor="uploadImage" className="cursor-pointer">
                  {value === "" ? (
                    <Icon type="uploadImageIcon" />
                  ) : (
                    <div className="w-40 h-40">
                      <img
                        alt="userImage"
                        className="w-full h-full object-cover"
                        style={{ borderRadius: "100%" }}
                        src={URL.createObjectURL(value)}
                      />
                    </div>
                  )}
                </label>
              </>
            )}
          </div>
          {!inputData[inputIndex]?.validity && (
            <p className="text-secondary-main mt-4 text-sm text-center">
              {t(inputData[inputIndex]?.errorMsg)}
            </p>
          )}
        </div>
        <div className="flex mt-32 justify-center">
          {inputIndex > 0 && (
            <Button
              color="secondary"
              classNames="bg-transparent text-black font-bold"
              onClick={() => {
                setValue(inputData[inputIndex - 1]?.value);
                setInputIndex(inputIndex - 1);
                setProgress(progress - parseInt(100 / inputData.length));
              }}
            >
              {"< " + t("onBoarding_go_back")}
            </Button>
          )}
          {inputIndex < inputData.length - 1 ? (
            <Button
              classNames="ml-2"
              color="secondary"
              isDisable={!inputData[inputIndex].validity}
              onClick={() => {
                setValue(inputData[inputIndex + 1]?.value);
                setInputIndex(inputIndex + 1);
                setProgress(progress + parseInt(100 / inputData.length));
              }}
            >
              {inputData[inputIndex]?.id === "videoLink" &&
              inputData[inputIndex]?.value === ""
                ? t("onBoarding_skip")
                : t("onBoarding_continue")}
            </Button>
          ) : (
            <Button classNames="ml-2" color="secondary" onClick={submitHandler}>
              {t("general_submit")}
            </Button>
          )}
        </div>
      </div>
    </>
  );
}

export default OnBoarding;
