import React from "react";
import Button from "@material-ui/core/Button";

function CustomButton({
  variant,
  color,
  isDisable,
  onClick,
  onMouseOver,
  onMouseOut,
  type,
  classNames,
  children,
  size,
  style,
}) {
  return (
    <Button
      variant={variant ? variant : "contained"}
      color={color ? color : "primary"}
      size={size ? size : "medium"}
      disableElevation
      disabled={isDisable}
      className={`capitalize outline-none ${classNames}`}
      onClick={onClick}
      style={style}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      type={type && type}
    >
      {children}
    </Button>
  );
}

export default CustomButton;
