import React, { useState } from "react";
import { Fade, Menu, MenuItem, withStyles } from "@material-ui/core";
import i18next from "i18next";
import cookies from "js-cookie";

import Icon from "@components/Icon";
import { languages } from "@helpers/data";

function ChangeLanguageDropDown({ isAuthenticated }) {
  const [anchorElLanguage, setAnchorElLanguage] = useState(null);

  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const handleLanguageMenuButtonClick = (event) => {
    setAnchorElLanguage(event.currentTarget);
  };
  const handleLanguageMenuClose = () => {
    setAnchorElLanguage(null);
  };
  const StyledMenu = withStyles({
    paper: {
      border: "1px solid #d3d4d5",
      width: anchorElLanguage?.offsetWidth,
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  ));
  return (
    <>
      <div
        className={`flex relative ${
          isAuthenticated ? "-mb-4" : "ml-4"
        } whitespace-nowrap items-center justify-center`}
      >
        <div
          className={`flex px-6 ${isAuthenticated ? "py-3" : "py-2"}  ${
            !Boolean(anchorElLanguage) && "rounded-full"
          } border border-boder-gray justify-center items-center cursor-pointer transition duration-200 ease-in-out`}
          onClick={handleLanguageMenuButtonClick}
        >
          <div className="w-7 h-7">
            <Icon type={currentLanguage?.icon} classNames="w-7 h-7" />
          </div>
          {/* <p className="ml-2">{currentLanguage?.name}</p> */}
          {/* <div className="ml-4">
            <Icon type="downArrow" />
          </div> */}
        </div>
      </div>
      <StyledMenu
        id="language-menu"
        anchorEl={anchorElLanguage}
        keepMounted
        open={Boolean(anchorElLanguage)}
        onClose={handleLanguageMenuClose}
        TransitionComponent={Fade}
        container={anchorElLanguage && anchorElLanguage.parentNode}
      >
        {languages?.map((language) => {
          return (
            <MenuItem
              onClick={() => {
                i18next.changeLanguage(language?.code);
                handleLanguageMenuClose();
              }}
              key={language?.code}
            >
              <Icon classNames="w-5 h-5" type={language?.icon} />
              {/* <span className="font-bold ml-3">{language?.name}</span> */}
            </MenuItem>
          );
        })}
      </StyledMenu>
    </>
  );
}

export default ChangeLanguageDropDown;
