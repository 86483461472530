import React from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";

function MultiSelect({
  options,
  name,
  id,
  isMulti,
  onChange,
  value,
  isDisabled,
  defaultValue,
  placeholder,
}) {
  const { t } = useTranslation();
  const customStyles = {
    option: (styles, { isFocused, isSelected }) => {
      return {
        ...styles,
        color: isFocused || isSelected ? "#ffffff" : null,
      };
    },
  };
  const handleChange = (value) => {
    let val = "";
    if (isMulti) {
      val = [];
      value.forEach((e) => {
        val.push(e.value);
      });
    } else {
      val = value.value;
    }

    const event = {
      target: {
        id,
        name,
        value: val,
      },
    };
    onChange(event);
  };

  return (
    <Select
      defaultValue={defaultValue && defaultValue}
      styles={customStyles}
      options={options}
      isDisabled={isDisabled}
      placeholder={placeholder ? placeholder : t("general_select")}
      value={
        value &&
        (isMulti
          ? value.map((e) => {
              return { value: e, label: e };
            })
          : { value: value, label: value })
      }
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          text: "#ffffff",
          primary25: "#D9331F",
          primary50: "#D9331F",
          primary: "#000000",
        },
      })}
      isMulti={isMulti}
      onChange={handleChange}
    />
  );
}

export default MultiSelect;
