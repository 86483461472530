import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { getCardImage } from "@helpers/utils";
import { Icon, Button } from "@components";
import { makeDefaultCard, removeCard } from "@store/payment/PaymentActions";
import { ConfirmModal } from "@components/modals";

function PaymentCard({ card }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const deleteCardHandler = (cardId) => {
    dispatch(removeCard(cardId));
  };
  const makeDefaultCardHandler = (cardId) => {
    dispatch(makeDefaultCard(cardId));
  };
  return (
    <>
      <div className="flex flex-col">
        <div
          className="flex items-center border border-black rounded-md p-4"
          style={{
            boxShadow: card.default && "0px 2px 15px rgb(217 51 31 / 50%)",
          }}
        >
          <Icon type={getCardImage(card.brand)} />
          <p className="text-lg font-medium ml-2">
            {"**** **** **** " + card.last4}
          </p>
        </div>
        <div className="flex mt-2 justify-center">
          {!card.default && (
            <>
              <Button
                color="secondary"
                onClick={() => {
                  setOpen(true);
                }}
              >
                {t("general_delete")}
              </Button>
              <Button
                classNames="ml-2"
                onClick={() => {
                  makeDefaultCardHandler(card.id);
                }}
              >
                {t("payment_methods_make_default")}
              </Button>
            </>
          )}
        </div>
      </div>
      <ConfirmModal
        headerText={t("general_confirm_delete_text")}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onConfirm={() => {
          deleteCardHandler(card.id);
          setOpen(false);
        }}
      />
    </>
  );
}

export default PaymentCard;
