import React, { useState, useEffect } from "react";
import { Dialog, InputAdornment, TextField } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { Button, Icon, Loader } from "@components";
import { editPassword } from "@store/auth/AuthActions";
import {
  validate,
  VALIDATOR_MINLENGTH,
  VALIDATOR_REQUIRE,
} from "@helpers/validators";

const Input = ({
  name,
  value,
  inputHandler,
  isValid,
  isTouched,
  touchHandler,
  helperText,
}) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  return (
    <TextField
      name={name}
      type={showPassword ? "text" : "password"}
      label={t("auth_password_label")}
      placeholder={"**********"}
      variant="outlined"
      fullWidth
      value={value}
      onChange={inputHandler}
      className="mt-4"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {showPassword ? (
              <div className="w-8 cursor-pointer">
                <Icon
                  type="visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                />
              </div>
            ) : (
              <div className="w-8 cursor-pointer">
                <Icon
                  type="visibilityOff"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                />
              </div>
            )}
          </InputAdornment>
        ),
      }}
      error={!isValid && isTouched}
      helperText={!isValid && isTouched && helperText}
      onBlur={touchHandler}
    />
  );
};

function EditPasswordModal({ onClose, open }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [form, setForm] = useState({
    password: {
      value: "",
      isValid: false,
      isTouched: false,
      validators: [VALIDATOR_MINLENGTH(6)],
    },
    confirmPassword: {
      value: "",
      isValid: false,
      isTouched: false,
      validators: [VALIDATOR_REQUIRE()],
    },
  });

  const [isDisable, setIsDisable] = useState(true);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const userType = localStorage.getItem("userType");

  const updatePasswordSubmitHandler = () => {
    dispatch(editPassword(form.password.value, userType));
    handleClose();
  };

  const inputHandler = (event) => {
    let temp = form;
    let validity = false;
    if (temp[`${event.target.name}`]?.validators) {
      validity = validate(
        event.target.value,
        temp[`${event.target.name}`].validators
      );
    }
    temp[`${event.target.name}`].value = event.target.value;
    temp[`${event.target.name}`].isValid = validity;
    temp[`${event.target.name}`].isTouched = true;
    setForm({ ...temp });
  };

  const touchHandler = (event) => {
    let temp = form;
    temp[`${event.target.name}`].isTouched = true;
    setForm({ ...temp });
  };

  useEffect(() => {
    let flag = form.password.isValid && form.confirmPassword.isValid;
    setIsDisable(!flag);
  }, [form]);

  useEffect(() => {
    if (form.password.value === form.confirmPassword.value) {
      setConfirmPasswordError(false);
    } else {
      setConfirmPasswordError(true);
    }
  }, [form]);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Loader loading={false}></Loader>
      <div className="flex flex-col items-center px-8 py-6 md:w-96">
        <Icon
          type="closeIconBlack"
          classNames="self-end cursor-pointer -mr-3"
          onClick={handleClose}
        />
        <div className="flex items-center mb-6">
          <Icon type="ratingIcon" classNames="w-8 h-8" />
          <p className="ml-3 text-xl font-medium ">
            {t("auth_update_password_heading")}
          </p>
        </div>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            updatePasswordSubmitHandler();
          }}
          className="flex flex-col items-center justify-center mt-12"
        >
          <Input
            name="password"
            value={form.password.value}
            inputHandler={inputHandler}
            touchHandler={touchHandler}
            isValid={form.password.isValid}
            isTouched={form.password.isTouched}
            helperText={t("auth_password_helper_text")}
          />
          <Input
            name="confirmPassword"
            value={form.confirmPassword.value}
            inputHandler={inputHandler}
            touchHandler={touchHandler}
            isValid={!confirmPasswordError}
            isTouched={form.confirmPassword.isTouched}
            helperText={t("auth_confirm_password_helper_text")}
          />
          <Button
            classNames="mt-10"
            color="secondary"
            type="submit"
            isDisable={isDisable || confirmPasswordError}
          >
            {t("general_submit")}
          </Button>
        </form>
      </div>
    </Dialog>
  );
}

export default EditPasswordModal;
