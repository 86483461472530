import React from "react";
import StarRatings from "react-star-ratings";

import { getLastTimeDuration } from "@helpers/timeDateUtils";
import Avatar from "./Avatar";

function Review({ review, classNames }) {
  return (
    <div className={`flex flex-col ${classNames}`}>
      <div className="flex items-center">
        <Avatar
          uri={review?.studentId?.image}
          name={review?.studentId?.name}
          classNames="w-14 h-14"
          textSize="text-md"
        />
        <div className="flex flex-col ml-1">
          <p>{review?.studentId?.name + " " + review?.studentId?.surname}</p>
          <div className="flex items-center">
            <StarRatings
              starRatedColor="red"
              starDimension={"20px"}
              starSpacing={"2px"}
              rating={review?.rating}
            />
            <p className="ml-2 mt-1">
              {getLastTimeDuration(review?.createdAt)}
            </p>
          </div>
        </div>
      </div>
      <p className="mt-3 mb-3">{review?.description}</p>
    </div>
  );
}

export default Review;
