import React from "react";
import { useTranslation } from "react-i18next";
import dompurify from "dompurify";
import "react-quill/dist/quill.snow.css";

function LessonDescription({ lesson }) {
  const { t } = useTranslation();
  const sanitizer = dompurify.sanitize;
  return (
    <div className="flex flex-col">
      <p className="font-bold text-xl">
        {t("lesson_detail_description_title")}
      </p>
      {/* <p className="mt-2">{lesson?.description}</p> */}
      <div className="mt-2 customDescription">
        <span
          className="ql-editor"
          dangerouslySetInnerHTML={{ __html: sanitizer(lesson?.description) }}
        />
      </div>
    </div>
  );
}

export default LessonDescription;
