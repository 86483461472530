import React, { useState } from "react";
import { Hidden } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { Button } from "@components";
import { AddReviewModal, ContactInfoModal } from "@components/modals";
import { useSelector } from "react-redux";
import { youtube_parser } from "@helpers/utils";

export default function ProfileInfo({ teacherProfile }) {
  const { t } = useTranslation();
  const history = useHistory();
  const [showContactModal, setShowContactModal] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState(false);

  const { user } = useSelector(({ EnglishCafe }) => EnglishCafe.auth);

  const contactClickHandler = () => {
    if (!user) {
      history.push("/login?type=Student");
    } else {
      setShowContactModal(true);
    }
  };

  let embedId =
    teacherProfile?.videoLink && youtube_parser(teacherProfile?.videoLink);
  return (
    <>
      <div className="flex flex-col items-center">
        {teacherProfile?.image && (
          <img
            className="h-56 w-56 object-cover rounded-xl"
            src={teacherProfile?.image}
            alt="userImage"
          />
        )}
        {teacherProfile?.languages?.length > 0 && (
          <Hidden smDown>
            <div className="mt-6 flex flex-wrap items-center justify-center border-checkbox-main px-2 pb-4 border-2">
              {teacherProfile?.languages?.map((e) => {
                return (
                  <div
                    className={`bg-checkbox-main mr-2 mt-4 rounded-xl p-2 cursor-pointer`}
                    key={e}
                  >
                    {e}
                  </div>
                );
              })}
            </div>
          </Hidden>
        )}

        {embedId && (
          <div className="mt-4">
            <iframe
              src={`https://www.youtube.com/embed/${embedId}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
            />
          </div>
        )}
        {!teacherProfile?.hideContactButton && (
          <Button
            color="secondary"
            classNames="mt-4"
            onClick={contactClickHandler}
          >
            {t("teacher_profile_contact")}
          </Button>
        )}
        {user && teacherProfile && !teacherProfile.hideReviewButton && (
          <Button
            color="secondary"
            classNames="mt-4"
            onClick={() => setShowReviewModal(true)}
          >
            {t("teacher_profile_add_review")}
          </Button>
        )}
      </div>
      <ContactInfoModal
        open={showContactModal}
        onClose={() => {
          setShowContactModal(false);
        }}
        user={teacherProfile}
      />
      <AddReviewModal
        open={showReviewModal}
        onClose={() => {
          setShowReviewModal(false);
        }}
        teacherId={teacherProfile?._id}
        type="teacher"
      />
    </>
  );
}
