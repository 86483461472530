import React, { useState, useEffect } from "react";
import { Dialog, TextField } from "@material-ui/core";
import StarRatings from "react-star-ratings";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Button, Icon, Loader } from "@components";
import { addReview } from "@store/lesson/LessonActions";

function AddReviewModal({ onClose, open, lessonId, teacherId, type }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const loading = useSelector(({ EnglishCafe }) => EnglishCafe.lesson.loading);

  const [rating, setRating] = useState(0);
  const [ratingDescription, setRatingDescription] = useState("");

  const handleClose = () => {
    onClose();
  };

  const changeRating = (newRating) => {
    setRating(newRating);
  };

  const changeHandler = (event) => {
    setRatingDescription(event.target.value);
  };

  const submitHandler = () => {
    const data = {
      description: ratingDescription,
      rating,
      lessonId,
      teacherId,
      type,
    };
    dispatch(addReview(data));
    handleClose();
  };

  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => window.removeEventListener("resize", handleWindowSizeChange);
  }, []);
  const isMobile = width <= 600;

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Loader loading={loading}></Loader>
      <div className="flex flex-col items-center px-8 py-6 md:w-96">
        <Icon
          type="closeIconBlack"
          classNames="self-end cursor-pointer -mr-3"
          onClick={handleClose}
        />
        <div className="flex items-center mb-6">
          <Icon type="ratingIcon" classNames="w-8 h-8" />
          <p className="ml-3 text-xl font-medium ">{t("review_review")}</p>
        </div>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            submitHandler();
          }}
          className="flex flex-col items-center justify-center mt-12"
        >
          <TextField
            placeholder={t("review_review_placeholder")}
            variant="outlined"
            multiline
            fullWidth
            rows={5}
            rowsMax={10}
            value={ratingDescription}
            onChange={changeHandler}
          />
          <div className="mt-7">
            <StarRatings
              rating={rating}
              starRatedColor="red"
              changeRating={changeRating}
              numberOfStars={5}
              name="rating"
              starDimension={isMobile ? "20px" : "40px"}
              starSpacing={isMobile ? "5px" : "10px"}
            />
          </div>
          <Button
            classNames="mt-10"
            color="secondary"
            type="submit"
            isDisable={rating <= 0}
          >
            {t("review_add_review")}
          </Button>
        </form>
      </div>
    </Dialog>
  );
}

export default AddReviewModal;
