import React, { useState } from "react";
import { Hidden } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import moment from "moment";

import { Button } from "@components";
import {
  deleteLesson,
  showHideLesson,
  unSubscribeLesson,
} from "@store/lesson/LessonActions";
import { setActiveTab } from "@store/header/HeaderActions";
import { setLessonId } from "@store/lesson/LessonActions";
import { ConfirmModal, AddReviewModal, SubscribersListModal } from "./modals";
import { getSubscribersByLessonId } from "@store/lesson/LessonActions";
import {
  compareDateWithCurrentDate,
  getGroupNextClassDate,
  getNewLessonRenewalDate,
} from "@helpers/utils";
import { toast } from "react-toastify";
import {
  translatedLanguageSpanish,
  translatedLessonCategoriesSpanish,
  translatedLessonTypesSpanish,
} from "@helpers/data";

function ProfileLessonCard({ lesson, isAll, lastLessonElementRef, tab }) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [showLesson, setshowLesson] = useState(lesson?.visibility);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [subscribersModalOpen, setSubscribersModalOpen] = useState(false);
  const [addReviewModalOpen, setAddreviewModalOpen] = useState(false);
  const [confirmSubscriptionModalOpen, setConfirmSubscriptionModalOpen] =
    useState(false);

  const userType = localStorage.getItem("userType");

  const cancelSubscriptionHandler = () => {
    dispatch(unSubscribeLesson(lesson?._id, isAll));
  };

  let newRenewalClassDate;
  if (lesson?.type === "group") {
    const enrolmentDate = getGroupNextClassDate(
      lesson?.dateRange?.startDate,
      lesson?.dayOfTheWeek
    );
    newRenewalClassDate = getNewLessonRenewalDate(enrolmentDate);
  }

  return (
    <>
      <div
        className="bg-profileCard-lightgray px-1 md:px-4 py-8 mb-4 rounded-md w-full"
        ref={lastLessonElementRef}
      >
        <div className="flex justify-between">
          <div className="flex justify-between w-full">
            <div className="flex">
              <img
                alt="lessonImage"
                className={`w-32 h-32 object-cover rounded-md ${
                  userType === "Student" && "cursor-pointer"
                }`}
                src={
                  lesson?.images &&
                  lesson?.images.length > 0 &&
                  lesson?.images[0]
                }
                onClick={() => {
                  if (userType === "Student") {
                    let lessonInfo = `clases`;
                    if (
                      Object.keys(translatedLanguageSpanish).includes(
                        lesson?.language
                      )
                    ) {
                      lessonInfo = `${lessonInfo}-${
                        translatedLanguageSpanish[lesson.language]
                      }`;
                    } else {
                      lessonInfo = `${lessonInfo}-${lesson.language.toLowerCase()}`;
                    }
                    if (lesson?.level?.length > 0) {
                      lessonInfo = `${lessonInfo}-${lesson.level.join("-")}`;
                    }
                    lessonInfo = `${lessonInfo}-${
                      translatedLessonCategoriesSpanish[lesson.type]
                    }-${translatedLessonTypesSpanish[lesson.classType]}`;
                    if (lesson.city) {
                      lessonInfo = `${lessonInfo}-${lesson.city.toLowerCase()}`;
                    }
                    // lessonInfo = `${lessonInfo}-${lesson.title
                    //   .toLowerCase()
                    //   .replaceAll(" ", "-")}/`;
                    history.push("/lessonDetail/" + lessonInfo + lesson._id);
                  }
                }}
              />
              <p
                className={`ml-4 font-bold ${
                  userType === "Student" && "cursor-pointer"
                } ${isAll ? "text-md" : "text-xs"}`}
                onClick={() => {
                  if (userType === "Student") {
                    let lessonInfo = `clases`;
                    if (
                      Object.keys(translatedLanguageSpanish).includes(
                        lesson?.language
                      )
                    ) {
                      lessonInfo = `${lessonInfo}-${
                        translatedLanguageSpanish[lesson.language]
                      }`;
                    } else {
                      lessonInfo = `${lessonInfo}-${lesson.language.toLowerCase()}`;
                    }
                    if (lesson?.level?.length > 0) {
                      lessonInfo = `${lessonInfo}-${lesson.level.join("-")}`;
                    }
                    lessonInfo = `${lessonInfo}-${
                      translatedLessonCategoriesSpanish[lesson.type]
                    }-${translatedLessonTypesSpanish[lesson.classType]}`;
                    if (lesson.city) {
                      lessonInfo = `${lessonInfo}-${lesson.city.toLowerCase()}`;
                    }
                    // lessonInfo = `${lessonInfo}-${lesson.title
                    //   .toLowerCase()
                    //   .replaceAll(" ", "-")}/`;
                    history.push("/lessonDetail/" + lessonInfo + lesson._id);
                  }
                }}
              >
                {lesson?.title}
              </p>
            </div>
            {!lesson?.isApproved && (
              <p className="text-sm font-medium rounded-md self-start">
                {t("lesson_detail_in_review")}
              </p>
            )}
          </div>
          {userType === "Student" && (
            <Hidden smDown>
              <div className="flex">
                {!lesson.isAlreadyReviewed && (
                  <Button
                    classNames="mr-1 mt-1 whitespace-nowrap self-center"
                    color="secondary"
                    variant="outlined"
                    onClick={() => {
                      setAddreviewModalOpen(true);
                    }}
                  >
                    {t("profile_lesson_review_button")}
                  </Button>
                )}
                {lesson.type === "group" && !lesson.enrolmentCancelDate && (
                  <Button
                    classNames="mr-1 mt-1 whitespace-nowrap self-center"
                    color="secondary"
                    onClick={() => {
                      setConfirmSubscriptionModalOpen(true);
                    }}
                  >
                    {t("profile_lesson_cancel_subscription_button")}
                  </Button>
                )}
              </div>
            </Hidden>
          )}
        </div>
        <div className="flex mt-2 md:items-center justify-between">
          <div className="flex items-baseline">
            <p
              className="text-xs mt-1 font-bold bg-profileCard-gray mr-2 md:mr-0 p-2 rounded-md whitespace-nowrap"
              style={{ width: "fit-content" }}
            >
              {"€ " + lesson?.price}
            </p>
            {userType === "Student" && (
              <Hidden smDown>
                <p
                  className="text-xs ml-1 mt-1 font-bold bg-profileCard-gray p-2 rounded-md capitalize whitespace-nowrap"
                  style={{ width: "fit-content" }}
                >
                  {lesson?.type}
                </p>
              </Hidden>
            )}
          </div>
          <div className={`flex flex-wrap ${isAll ? "md:ml-2" : "md:ml-10"}`}>
            {userType === "Teacher" && (
              <>
                {(lesson.type === "group" || lesson.type === "experience") && (
                  <Button
                    size={isAll ? "medium" : "small"}
                    classNames="mr-1 mt-1 whitespace-nowrap"
                    onClick={() => {
                      dispatch(getSubscribersByLessonId(lesson?._id));
                      setSubscribersModalOpen(true);
                    }}
                  >
                    {t("profile_lesson_subscribers_button")}
                  </Button>
                )}
                {lesson?.cancelDate ? (
                  <p className="text-secondary-main self-center">
                    {t("profile_lesson_subscription_canceled")}
                  </p>
                ) : (
                  <>
                    <Button
                      size={isAll ? "medium" : "small"}
                      variant="outlined"
                      classNames="mr-1 mt-1 whitespace-nowrap"
                      onClick={() => {
                        dispatch(setLessonId(lesson?._id));
                        dispatch(setActiveTab("Modify Ads"));
                      }}
                    >
                      {t("profile_lesson_modify_button")}
                    </Button>
                    <Button
                      size={isAll ? "medium" : "small"}
                      classNames="mr-1 mt-1 whitespace-nowrap"
                      onClick={() => {
                        setshowLesson(!showLesson);
                        dispatch(showHideLesson(lesson?._id));
                      }}
                    >
                      {showLesson
                        ? t("profile_lesson_close_ad_button")
                        : t("profile_lesson_show_ad_button")}
                    </Button>
                    <Button
                      size={isAll ? "medium" : "small"}
                      classNames="mr-1 mt-1 whitespace-nowrap"
                      color="secondary"
                      onClick={() => {
                        if (
                          (lesson?.type === "group" ||
                            lesson?.type === "experience") &&
                          lesson?.totalStudents &&
                          lesson?.totalStudents > 0
                        ) {
                          toast.error(t("profile_lesson_delete_error"));
                        } else {
                          setConfirmModalOpen(true);
                        }
                      }}
                    >
                      {t("profile_lesson_delete_ad_button")}
                    </Button>
                  </>
                )}
              </>
            )}
            {userType === "Student" && (
              <Hidden only={["md", "lg", "xl"]}>
                {!lesson.isAlreadyReviewed && (
                  <Button
                    classNames="mr-1 mt-1 whitespace-nowrap self-center"
                    color="secondary"
                    variant="outlined"
                    onClick={() => {
                      setAddreviewModalOpen(true);
                    }}
                  >
                    {t("profile_lesson_review_button")}
                  </Button>
                )}
                {lesson.type === "group" && !lesson.enrolmentCancelDate && (
                  <Button
                    classNames="mr-1 mt-1 whitespace-nowrap self-center"
                    color="secondary"
                    onClick={() => {
                      setConfirmSubscriptionModalOpen(true);
                    }}
                  >
                    {t("profile_lesson_cancel_subscription_button")}
                  </Button>
                )}
              </Hidden>
            )}
          </div>
        </div>
        <div className="flex flex-wrap items-center mt-2">
          {userType === "Student" &&
            lesson?.type === "experience" &&
            lesson?.enrolmentDate && (
              <div className="flex items-center bg-profileCard-gray p-2 rounded-md mb-2 mr-2">
                <p className="text-xs">
                  {t("lesson_detail_schedule") + ": " + lesson?.enrolmentDate}
                </p>
              </div>
            )}
          {userType === "Student" &&
            lesson?.type === "group" &&
            lesson?.enrolmentDate && (
              <div className="flex items-center bg-profileCard-gray p-2 rounded-md mb-2 mr-2">
                <p className="text-xs">
                  {t("profile_lesson_date_renewal") + ": "}
                  {newRenewalClassDate}
                </p>
              </div>
            )}
          {userType === "Teacher" &&
            ((lesson?.dates && lesson?.dates?.length > 0) ||
              lesson?.dayOfTheWeek) && (
              <div className="flex items-center bg-profileCard-gray p-2 rounded-md mb-2 mr-2">
                <p className="text-xs">
                  {t("lesson_detail_schedule") + ": "}
                  {lesson?.dayOfTheWeek ? (
                    <span className="bg-black rounded-xl px-2 py-1 text-white ml-1 text-xs">
                      {lesson?.dayOfTheWeek}
                    </span>
                  ) : (
                    lesson?.dates.map((date) => {
                      return (
                        <span className="bg-black rounded-xl px-2 py-1 text-white ml-1 text-xs">
                          {date + " "}
                        </span>
                      );
                    })
                  )}
                </p>
              </div>
            )}
          {userType === "Teacher" && lesson?.type === "group" && (
            <p className="mr-2 bg-profileCard-gray p-2 rounded-md mb-2 text-xs">
              {t("profile_lesson_available_places")}
              {": "}
              <span>
                {lesson.numberOfPlaces - lesson.totalStudents}/
                {lesson.numberOfPlaces}
              </span>
            </p>
          )}
          {(lesson?.type === "group" || lesson?.type === "experience") &&
            lesson?.level && (
              <p className="mr-2 bg-profileCard-gray p-2 rounded-md mb-2 text-xs">
                {t("lesson_detail_level")}: <span>{lesson?.level}</span>
              </p>
            )}
        </div>
        {lesson?.type === "group" &&
          lesson.enrolmentCancelDate &&
          compareDateWithCurrentDate(
            moment(lesson?.enrolmentDate, "DD/MM/YYYY")
              .add(1, "M")
              .format("DD/MM/YYYY")
          ) && (
            <div className="flex">
              <p className="text-sm text-secondary-main">
                {t("lesson_detail_subscription_to_be_cancelled")}
              </p>
            </div>
          )}
        {lesson?.type === "group" &&
          lesson.enrolmentCancelDate &&
          !compareDateWithCurrentDate(
            moment(lesson?.enrolmentDate, "DD/MM/YYYY")
              .add(1, "M")
              .format("DD/MM/YYYY")
          ) && (
            <div className="flex">
              <p className="text-sm text-secondary-main">
                {t("lesson_detail_subscription_cancelled")}
              </p>
            </div>
          )}
      </div>
      <ConfirmModal
        headerText={t("profile_lesson_cancel_subscription_confirm")}
        mainText={t("profile_lesson_cancel_subscription_main")}
        open={confirmSubscriptionModalOpen}
        onClose={() => {
          setConfirmSubscriptionModalOpen(false);
        }}
        onConfirm={() => {
          setConfirmSubscriptionModalOpen(false);
          cancelSubscriptionHandler();
        }}
      />
      <ConfirmModal
        headerText={t("general_confirm_delete_text")}
        open={confirmModalOpen}
        onClose={() => {
          setConfirmModalOpen(false);
        }}
        onConfirm={() => {
          setConfirmModalOpen(false);
          dispatch(deleteLesson(lesson?._id, isAll, tab));
        }}
      />
      <SubscribersListModal
        open={subscribersModalOpen}
        onClose={() => {
          setSubscribersModalOpen(false);
        }}
      />
      <AddReviewModal
        open={addReviewModalOpen}
        onClose={() => {
          setAddreviewModalOpen(false);
        }}
        teacherId={lesson?.teacherId}
        lessonId={lesson?._id}
        type={"lesson"}
      />
    </>
  );
}

export default ProfileLessonCard;
