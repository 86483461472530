import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Loader } from "@components";
import { getTeachersByStudentId } from "@store/lesson/LessonActions";
import TeacherList from "./TeacherList";

function ViewTeacher() {
  const dispatch = useDispatch();
  const teachersData = useSelector(
    ({ EnglishCafe }) => EnglishCafe.lesson.teachers
  );

  const totalPages = useSelector(
    ({ EnglishCafe }) => EnglishCafe.lesson.totalPages
  );
  const loading = useSelector(({ EnglishCafe }) => EnglishCafe.lesson.loading);
  const [hasMore, setHasMore] = useState(true);
  const [currentPageNo, setCurrentPageNo] = useState(1);

  useEffect(() => {
    if (totalPages === currentPageNo) {
      setHasMore(false);
    }
  }, [currentPageNo, totalPages]);

  const observer = useRef();

  const lastTeacherElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setCurrentPageNo(currentPageNo + 1);
          dispatch(getTeachersByStudentId(currentPageNo + 1, 10));
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore, loading, currentPageNo, dispatch]
  );

  useEffect(() => {
    dispatch(getTeachersByStudentId(1, 10));
  }, [dispatch]);

  return (
    <div className="flex  w-full  md:px-16 pt-4 md:pb-4">
      <Loader loading={loading}></Loader>
      <TeacherList
        lastTeacherElementRef={lastTeacherElementRef}
        teachersData={teachersData}
        isAll={true}
      />
    </div>
  );
}

export default ViewTeacher;
