export const BASE = "api end point";

export const loginTeacherUrl = () => {
  return encodeURI("auth/teacher/login");
};
export const registerTeacherUrl = () => {
  return encodeURI("auth/teacher");
};

export const loginStudentUrl = () => {
  return encodeURI("auth/student/login");
};
export const registerStudentUrl = () => {
  return encodeURI("auth/student");
};

export const getTeacherByIdUrl = (userId) => {
  return encodeURI("auth/teacher/" + userId);
};

export const getStudentByIdUrl = (userId) => {
  return encodeURI("auth/student/" + userId);
};

export const onBoardTeacherUrl = () => {
  return encodeURI("auth/teacher/onBoard");
};

export const onBoardStudentUrl = () => {
  return encodeURI("auth/student/onBoard");
};

export const getLessonsUrl = (page, limit, filter) => {
  if (filter) {
    filter = JSON.stringify(filter);
    return encodeURI(
      "lesson?page=" + page + "&limit=" + limit + "&filter=" + filter
    );
  } else {
    return encodeURI("lesson?page=" + page + "&limit=" + limit);
  }
};

export const getHomeScreenLessonsUrl = () => {
  return encodeURI("homeScreenLessons");
};

export const teacherSocialAuthenticateUrl = () => {
  return encodeURI("auth/teacher/socialAuthenticate");
};

export const studentSocialAuthenticateUrl = () => {
  return encodeURI("auth/student/socialAuthenticate");
};

export const getLessonbyIdUrl = (lessonId) => {
  return encodeURI("lesson/" + lessonId);
};

export const createLessonUrl = () => {
  return encodeURI("lesson");
};

export const getLessonsByTeacherIdUrl = (teacherId, page, limit, type) => {
  return encodeURI(
    "lessonsByTeacherId?teacherId=" +
      teacherId +
      "&page=" +
      page +
      "&limit=" +
      limit +
      "&type=" +
      type
  );
};

export const getChatsUrl = () => {
  return encodeURI("chat");
};
export const createChatUrl = () => {
  return encodeURI("chat");
};

export const createMessageUrl = () => {
  return encodeURI("message");
};

export const seenAllMessagesUrl = () => {
  return encodeURI("message");
};
export const readOneMessageUrl = () => {
  return encodeURI("readOneMessage");
};

export const getUserMessagesUrl = (userChatId, recieverUserId, page, limit) => {
  if (userChatId) {
    return encodeURI(
      "message?userChatId=" + userChatId + "&page=" + page + "&limit=" + limit
    );
  } else {
    return encodeURI(
      "message?recieverUserId=" +
        recieverUserId +
        "&page=" +
        page +
        "&limit=" +
        limit
    );
  }
};

export const showHideLessonUrl = () => {
  return encodeURI("showHideLesson");
};

export const updateTeacherUrl = () => {
  return encodeURI("auth/teacher");
};
export const updateStudentUrl = () => {
  return encodeURI("auth/student");
};

export const deleteLessonUrl = () => {
  return encodeURI("lesson");
};

export const updateLessonUrl = () => {
  return encodeURI("lesson");
};

export const addCardUrl = () => {
  return encodeURI("cards");
};

export const removeCardUrl = () => {
  return encodeURI("cards");
};

export const getUserCardsUrl = (userType) => {
  return encodeURI("cards?userType=" + userType);
};

export const makeDefaultCardUrl = () => {
  return encodeURI("cards");
};

export const makePaymentUrl = () => {
  return encodeURI("makePayment");
};

export const buyLessonUrl = () => {
  return encodeURI("enrolment");
};

export const getSubscribedLessonsUrl = (page, limit) => {
  return encodeURI("enrolment?page=" + page + "&limit=" + limit);
};

export const getLessonPriceForTeacherUrl = () => {
  return encodeURI("lessonPriceByStripePriceId");
};

export const getSubscribersByLessonIdUrl = (lessonId) => {
  return encodeURI("subscribersByLessonId?lessonId=" + lessonId);
};

export const getTeacherPublicProfileDetailsUrl = (teacherId, studentId) => {
  if (studentId) {
    return encodeURI(
      "auth/teacher/publicProfile?teacherId=" +
        teacherId +
        "&studentId=" +
        studentId
    );
  }
  return encodeURI("auth/teacher/publicProfile?teacherId=" + teacherId);
};

export const addReviewUrl = () => {
  return encodeURI("review");
};

export const getTeachersByStudentIdUrl = (page, limit) => {
  return encodeURI("teachersByStudentId?page=" + page + "&limit=" + limit);
};

export const studentForgotPasswordUrl = () => {
  return encodeURI("auth/student/forgotPassword");
};

export const teacherForgotPasswordUrl = () => {
  return encodeURI("auth/teacher/forgotPassword");
};

export const studentUpdatePasswordUrl = () => {
  return encodeURI("auth/student/updatePassword");
};

export const teacherUpdatePasswordUrl = () => {
  return encodeURI("auth/teacher/updatePassword");
};

export const unSubscribeLessonUrl = () => {
  return encodeURI("enrolment");
};

export const getTeacherMonthlyRevenueUrl = () => {
  return encodeURI("auth/teacher/monthlyRevenueData");
};

export const getTeacherRevenueListUrl = (page, limit) => {
  return encodeURI(
    "auth/teacher/teacherRevenueList?page=" + page + "&limit=" + limit
  );
};

export const getStudentsByTeacherIdUrl = () => {
  return encodeURI("auth/teacher/studentsByTeacherId");
};

export const getTopTeachersUrl = () => {
  return encodeURI("topTeachers");
};

export const saveLessonDraftUrl = () => {
  return encodeURI("lessonDraft");
};

export const getLessonDraftByTeacherIdUrl = (page, limit) => {
  return encodeURI("lessonDraftsByTeacherId?page=" + page + "&limit=" + limit);
};

export const getDraftLessonbyIdUrl = (lessonId) => {
  return encodeURI("lessonDraft/" + lessonId);
};

export const deleteLessonDraftUrl = (lessonId) => {
  return encodeURI("lessonDraft/" + lessonId);
};

export const updateLessonDraftUrl = (lessonId) => {
  return encodeURI("lessonDraft/" + lessonId);
};

export const studentEditPasswordUrl = () => {
  return encodeURI("auth/student/editPassword");
};

export const teacherEditPasswordUrl = () => {
  return encodeURI("auth/teacher/editPassword");
};

export const getCalendarDatesByTeacherIdUrl = () => {
  return encodeURI("calendarDatesByTeacherId");
};
