import React, { useEffect, useState } from "react";
import { Dialog } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Icon, Avatar, Loader, Button } from "@components";
import { useHistory } from "react-router-dom";
import { setActiveTab } from "@store/header/HeaderActions";
import { setUserChatDetails } from "@store/chat/ChatActions";
import { getNewLessonRenewalDate } from "@helpers/utils";

function SubscribersListModal({ onClose, open }) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleClose = () => {
    onClose();
  };

  const { subscribers, loading } = useSelector(
    ({ EnglishCafe }) => EnglishCafe.lesson
  );

  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => window.removeEventListener("resize", handleWindowSizeChange);
  }, []);
  const isMobile = width <= 600;

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Loader loading={loading}></Loader>
      <div
        className={`flex flex-col items-center px-3 md:px-8 py-6 overflow-auto md:styledScrollbar ${
          subscribers.length < 1 && "md:w-96"
        }`}
        style={{ height: "60vh" }}
      >
        <Icon
          type="closeIconBlack"
          classNames="self-end cursor-pointer -mr-3"
          onClick={handleClose}
        />
        <div className="flex items-center mb-6">
          <Icon type="profileIcon" classNames="w-8 h-8" />
          <p className="ml-3 text-xl font-medium">{t("general_subscribers")}</p>
        </div>
        <div className="flex flex-col">
          {subscribers &&
            subscribers.length > 0 &&
            subscribers.map((user) => {
              return (
                <div className="flex items-center mb-4 justify-between border-dotted pb-2 border-b-2">
                  <div className="flex flex-col">
                    <div className="flex items-center">
                      <Avatar
                        uri={user?.image}
                        name={user?.name}
                        classNames="w-8 h-8"
                        textSize="text-md"
                      />
                      <div className="flex flex-col ml-1 md:ml-4">
                        <div className="flex justify-between">
                          <p className="md:font-medium text-xs md:text-sm">
                            {user?.name + " " + user?.surname}
                          </p>
                        </div>
                        <p className="md:font-medium text-xs md:text-sm">
                          {user?.email}
                        </p>
                      </div>
                    </div>
                    {user?.isLessonTypeGroup && user?.enrolmentDate && (
                      <div className="flex items-center bg-profileCard-gray p-2 rounded-md">
                        <p className="text-xs">
                          {t("profile_lesson_date_renewal") +
                            ": " +
                            getNewLessonRenewalDate(user?.enrolmentDate)}
                        </p>
                      </div>
                    )}
                  </div>
                  <Button
                    color="secondary"
                    classNames="text-xs w-16 md:24 md:text-sm ml-1 md:ml-4"
                    onClick={() => {
                      dispatch(setActiveTab("Messages"));
                      dispatch(
                        setUserChatDetails({
                          recieverId: user?._id,
                          userImage: user?.image,
                          userName: user?.name + " " + user?.surname,
                          chatOpened: true,
                        })
                      );
                      if (isMobile) {
                        history.push("/mobile-messenger");
                      } else {
                        history.push("/teacherProfile");
                      }
                      handleClose();
                    }}
                  >
                    {t("lesson_detail_message")}
                  </Button>
                </div>
              );
            })}
        </div>
      </div>
    </Dialog>
  );
}

export default SubscribersListModal;
