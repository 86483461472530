import React from "react";

function Avatar({ uri, name, classNames, textSize, onClick }) {
  return uri ? (
    <img
      alt="userImage"
      className={`${classNames} object-cover`}
      style={{ borderRadius: "100%" }}
      src={uri}
      onClick={onClick}
    />
  ) : (
    <div
      className={`flex items-center justify-center bg-secondary-main ${classNames}`}
      style={{ borderRadius: "100%" }}
      onClick={onClick}
    >
      <p
        className={`capitalize ${
          textSize ? textSize : "text-3xl mt-1"
        } p-0 text-white text-center`}
      >
        {name?.charAt(0)}
      </p>
    </div>
  );
}

export default Avatar;
