import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Icon } from "@components";
import { logoutUser } from "@helpers/utils";
import { setActiveTab } from "@store/header/HeaderActions";
import { setLessonId, setLessonType } from "@store/lesson/LessonActions";
import Avatar from "./Avatar";

function ProfileNavBar({ user, tabs }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeTab = useSelector(
    ({ EnglishCafe }) => EnglishCafe.header.activeTab
  );
  const history = useHistory();
  return (
    <div className="flex flex-col pt-32 pb-10 bg-profileCard-gray w-60 h-full  justify-between">
      <div className="flex flex-col">
        <div className="flex items-center justify-center mb-2">
          <Avatar uri={user?.image} name={user?.name} classNames="w-20 h-20" />
        </div>
        <p className="font-bold text-center capitalize">
          {user?.name + " " + user?.surname}
        </p>
        <p
          className="cursor-pointer mt-4 mb-8 text-xs text-center"
          onClick={() => {
            dispatch(setActiveTab("Edit Profile"));
          }}
        >
          {t("profile_tab_edit_profile")}
        </p>
        {tabs.map((tab) => {
          return (
            <div
              className={`cursor-pointer mt-3 flex items-center pl-2 border-r-4 border-transparent ${
                activeTab === tab.value && " border-secondary-main"
              }`}
              onClick={() => {
                if (tab.value === "Create Ads") {
                  dispatch(setLessonId(undefined));
                  dispatch(setLessonType(undefined));
                }
                dispatch(setActiveTab(tab.value));
              }}
              key={tab.title}
            >
              <Icon type={tab.icon} />
              <p className="ml-3 font-medium whitespace-nowrap">
                {t(tab.title)}
              </p>
            </div>
          );
        })}
      </div>
      <div
        className="cursor-pointer flex items-center  px-12"
        onClick={() => {
          logoutUser(history, dispatch);
        }}
      >
        <div className="w-4">
          <Icon type="logoutIcon2" />
        </div>
        <p className="ml-3 font-medium">{t("general_logout")}</p>
      </div>
    </div>
  );
}

export default ProfileNavBar;
