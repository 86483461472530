import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { setActiveTab } from "@store/header/HeaderActions";
import { Avatar, Button } from "@components";
import { setUserChatDetails } from "@store/chat/ChatActions";

function TeacherList({ isAll, teachersData, lastTeacherElementRef }) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => window.removeEventListener("resize", handleWindowSizeChange);
  }, []);
  const isMobile = width <= 600;

  return (
    <div
      className={`flex flex-col border border-border-gray bg-white py-4 rounded-md w-full`}
    >
      <div className="flex justify-between items-center mb-4 px-10">
        <p className="font-bold text-lg">{t("profile_teacher_my_teachers")}</p>
        {isAll && <p className="font-bold text-lg">{t("general_all")}</p>}
        {!isAll && (
          <p
            className="text-xs cursor-pointer"
            onClick={() => {
              dispatch(setActiveTab("View Teachers"));
            }}
          >
            {t("general_view_all")}
          </p>
        )}
      </div>
      <div
        className={`flex flex-col md:p-6 mr-1 ${
          isAll && "overflow-y-auto styledScrollbar"
        } px-4`}
        style={{ height: isAll && !isMobile && "calc(92vh - 13.5rem)" }}
      >
        {teachersData &&
          teachersData.length > 0 &&
          teachersData.map((user) => {
            return (
              <div
                className="bg-profileCard-lightgray md:px-4 py-8 mb-4 rounded-md w-fulll"
                ref={lastTeacherElementRef}
              >
                <div className="flex">
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      const teacherId = user?._id;
                      history.push("/teacherPublicProfile/" + teacherId);
                    }}
                  >
                    <Avatar
                      uri={user?.image}
                      name={user?.name}
                      classNames="w-20 h-20 mr-2"
                    />
                  </div>
                  <div className="flex flex-col w-full">
                    <p
                      className="font-bold text-md cursor-pointer"
                      onClick={() => {
                        const teacherId = user?._id;
                        history.push("/teacherPublicProfile/" + teacherId);
                      }}
                    >
                      {user?.name + " " + user?.surname}
                    </p>
                    <Button
                      color="secondary"
                      size="small"
                      classNames="self-end"
                      onClick={() => {
                        dispatch(setActiveTab("Messages"));
                        dispatch(
                          setUserChatDetails({
                            recieverId: user?._id,
                            userImage: user?.image,
                            userName: user?.name + " " + user?.surname,
                            chatOpened: true,
                          })
                        );
                        if (isMobile) {
                          history.push("/mobile-messenger");
                        } else {
                          history.push("/studentProfile");
                        }
                      }}
                    >
                      {t("general_message")}
                    </Button>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default TeacherList;
