import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import {
  // HomeScreen,
  Login,
  Signup,
  Lessons,
  LessonDetail,
  TeacherPublicProfile,
} from "@modules";

const NonUser = (props) => {
  return (
    <Switch>
      {/* <Route exact path="/" component={HomeScreen} /> */}
      <Route exact path="/signup" component={Signup} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/lessons" component={Lessons} />
      <Route
        exact
        path="/lessonDetail/:lessonInfo/:lessonId"
        component={LessonDetail}
      />
      <Route
        exact
        path="/teacherPublicProfile/:teacherId"
        component={TeacherPublicProfile}
      />
      <Redirect to="/lessons" />
    </Switch>
  );
};
export default NonUser;
