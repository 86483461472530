import React, { useState, useEffect } from "react";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Tooltip,
  Checkbox,
} from "@material-ui/core";
import { toast } from "react-toastify";
import Geocode from "react-geocode";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";

import {
  Icon,
  Select,
  Button,
  Loader,
  MultipleDateSelector,
  CustomDateRangePicker,
  Map,
  QuillEditor,
} from "@components";
import {
  LessonInputData,
  DayOfTheWeek,
  DayOfTheWeekSpanish,
  Level,
  OptionNumberGenerator,
  Discounts,
} from "../helper/Data";
import { lighterImage } from "@helpers/imageCompression";
import {
  isImage,
  percentageValue,
  S3ImageDelete,
  S3ImageUploader,
} from "@helpers/utils";
import {
  createLesson,
  getDraftLessonbyId,
  getLessonbyId,
  saveLessonDraft,
  updateLesson,
  updateLessonDraft,
} from "@store/lesson/LessonActions";
import { AddCardModal, ConfirmModal } from "@components/modals";
import { getLessonPriceForTeacher } from "@store/payment/PaymentActions";

Geocode.setApiKey(process.env.REACT_APP_MAP_API_KEY);

function LessonForm() {
  const { t } = useTranslation();

  const [weekDays, setWeekDays] = useState([]);

  const currentLanguageCode = cookies.get("i18next") || "en";

  useEffect(() => {
    if (currentLanguageCode === "es") {
      setWeekDays([...DayOfTheWeekSpanish]);
    } else {
      setWeekDays([...DayOfTheWeek]);
    }
  }, [currentLanguageCode]);

  const history = useHistory();
  const dispatch = useDispatch();

  const [classType, setClassType] = useState("group");
  const [inputData, setInputData] = useState(LessonInputData);
  const [isDisable, setIsDisable] = useState(true);
  const [imageUploading, setImageUploading] = useState(false);
  const [inputChanged, setInputChanged] = useState(false);
  const [toBeDeletedImages, setToBeDeletedImages] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [mapsZoom, setMapsZoom] = useState(2);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [openCardModal, setOpenCardModal] = useState(false);
  const [checked, setChecked] = useState(false);
  const [openConfirmImageModal, setOpenConfirmImageModal] = useState(false);
  const [locationLoading, setLocationLoading] = useState(false);

  const user = useSelector(({ EnglishCafe }) => EnglishCafe.auth.user);

  const [descriptionText, setDescriptionText] = useState("");

  const activeTab = useSelector(
    ({ EnglishCafe }) => EnglishCafe.header.activeTab
  );

  useEffect(() => {
    const tempLanguages = user?.languages.map((language) => {
      return { label: language, value: language };
    });
    setLanguageOptions(tempLanguages);
  }, [user]);

  useEffect(() => {
    if (!inputData?.city?.value) {
      setInputData((prevForm) => ({
        ...prevForm,
        city: {
          value: "",
        },
        postalCode: {
          value: "",
        },
        address: {
          value: "",
        },
        coordinates: {
          value: { latitude: 17.308688, longitude: 7.03125 },
        },
      }));
    }
  }, [inputData?.city?.value]);

  const setLatAndLong = (event, query) => {
    setLocationLoading(true);
    Geocode.fromAddress(query).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        setInputData((prevForm) => ({
          ...prevForm,
          coordinates: {
            value: { latitude: lat, longitude: lng },
          },
        }));
        setLocationLoading(false);
      },
      (error) => {
        console.error(error);
        setLocationLoading(false);
      }
    );
    inputHandler(event);
  };

  const locationChangeHandler = (event) => {
    const query = [event.target.value];

    switch (event.target.name) {
      case "city":
        setMapsZoom(8);
        if (inputData?.postalCode?.value !== "") {
          query.push(inputData.postalCode.value);
        }
        if (inputData?.address?.value !== "") {
          query.push(inputData.address.value);
        }
        break;
      case "postalCode":
        setMapsZoom(11);
        if (inputData?.city?.value !== "") {
          query.push(inputData.city.value);
        }
        if (inputData?.address?.value !== "") {
          query.push(inputData.address.value);
        }
        break;
      case "address":
        if (inputData?.city?.value !== "") {
          query.push(inputData.city.value);
        }
        if (inputData?.postalCode?.value !== "") {
          query.push(inputData.postalCode.value);
        }
        setMapsZoom(15);
        break;
      default:
        setMapsZoom(2);
    }
    setLatAndLong(event, query.join(" "));
  };

  const inputHandler = (event) => {
    let temp = inputData;
    temp[`${event.target.name}`] = {
      value: event.target.value,
    };
    setInputData({ ...temp });
    setInputChanged(true);
  };

  const dateRangeHandler = (startDate, endDate) => {
    setInputData((prevForm) => ({
      ...prevForm,
      dateRange: {
        value: { startDate: startDate?._d, endDate: endDate?._d },
      },
    }));
    setInputChanged(true);
  };

  const typeHandler = (type) => {
    setInputData((prevForm) => ({
      ...prevForm,
      type: {
        value: type,
      },
    }));
    setClassType(type);
    setInputChanged(true);
  };

  const lesson = useSelector(({ EnglishCafe }) => EnglishCafe.lesson.lesson);
  const loading = useSelector(({ EnglishCafe }) => EnglishCafe.lesson.loading);
  const { lessonId, lessonType } = useSelector(
    ({ EnglishCafe }) => EnglishCafe.lesson
  );

  useEffect(() => {
    if (activeTab === "Modify Drafts" && lessonId) {
      dispatch(getDraftLessonbyId(lessonId));
    } else if (activeTab === "Modify Ads" && lessonId) {
      dispatch(getLessonbyId(lessonId));
    }
  }, [dispatch, lessonId, activeTab]);

  useEffect(() => {
    if (lessonId && lesson) {
      let temp = LessonInputData;
      for (let key in temp) {
        if (key === "imageFiles") {
          temp[key].value = lesson["images"];
        } else {
          temp[key].value = lesson[key] ? lesson[key] : "";
        }
      }
      const span = document.createElement("span");
      span.innerHTML = lesson.description;
      const descText = span.innerText || span.textContent;
      setDescriptionText(descText);
      setInputData({ ...temp });
      setClassType(temp["type"].value);
      setChecked(true);
      setMapsZoom(12);
    } else {
      setChecked(false);
      setInputData({
        type: {
          value: "group",
        },
        language: {
          value: "",
        },
        classType: {
          value: "",
        },
        city: {
          value: "",
        },
        postalCode: {
          value: "",
        },
        address: {
          value: "",
        },
        coordinates: {
          value: { latitude: 17.308688, longitude: 7.03125 },
        },
        title: {
          value: "",
        },
        description: {
          value: "",
        },
        level: {
          value: [],
        },
        duration: {
          value: "",
        },
        startTime: {
          value: "",
        },
        endTime: {
          value: "",
        },
        dayOfTheWeek: {
          value: "",
        },
        dateRange: {
          value: { startDate: "", endDate: "" },
        },
        dates: {
          value: [],
        },
        numberOfPlaces: {
          value: "",
        },
        trialClass: {
          value: "",
        },
        price: {
          value: "",
        },
        discount: {
          value: "",
        },
        imageFiles: {
          value: [],
        },
      });
      setClassType("group");
      if (lessonType) {
        setInputData((prevForm) => ({
          ...prevForm,
          type: {
            value: lessonType,
          },
        }));
        setClassType(lessonType);
      }
    }
  }, [lessonId, lesson, lessonType]);

  const uploadFile = async (event) => {
    let tempAttachments = [];
    const totalImagesLength =
      inputData?.imageFiles?.value?.length + event.target.files.length;
    if (event.target.files.length > 10 || totalImagesLength > 10) {
      toast.error(t("lesson_form_maximum_upload_image_error"));
      return;
    }

    for (let i = 0; i < event.target.files.length; i++) {
      let file1 = event.target.files[i];
      if (file1.type.split("/")[0] === "image") {
        if (!isImage(file1.type)) {
          toast.error(t("general_image_format_error"));
          return;
        }
      } else {
        toast.error(t("general_file_format_error"));
        return;
      }
      if (Math.floor(file1.size / 1048576) > 10) {
        toast.error(t("general_image_size_error"));
        return;
      }
      tempAttachments.push(file1);
    }
    if (inputData.imageFiles.value.length === 0) {
      setOpenConfirmImageModal(true);
    }
    setImageUploading(true);
    for (let i = 0; i < tempAttachments.length; i++) {
      let newfile = await lighterImage(tempAttachments[i]);
      tempAttachments[i] = newfile;
    }
    setImageUploading(false);
    setInputData((prevForm) => ({
      ...prevForm,
      imageFiles: {
        value: [...prevForm.imageFiles.value, ...tempAttachments],
      },
    }));
    setInputChanged(true);
  };

  useEffect(() => {
    let disableFlag = false;
    if (inputData.type.value === "group") {
      disableFlag =
        inputData.language.value === "" ||
        inputData.classType.value === "" ||
        inputData.title.value === "" ||
        descriptionText === "" ||
        inputData.title.value.length < 5 ||
        descriptionText.length < 10 ||
        inputData.level.value.length < 1 ||
        inputData.dayOfTheWeek.value === "" ||
        inputData.dateRange.value.startDate === "" ||
        inputData.dateRange.value.endDate === "" ||
        inputData.startTime.value === "" ||
        inputData.duration.value === "" ||
        inputData.price.value === "" ||
        inputData.imageFiles.value.length < 2;
    } else if (
      inputData.type.value === "one-to-one" ||
      inputData.type.value === "children"
    ) {
      disableFlag =
        inputData.language.value === "" ||
        inputData.classType.value === "" ||
        inputData.title.value === "" ||
        descriptionText === "" ||
        inputData.title.value.length < 5 ||
        descriptionText.length < 10 ||
        inputData.trialClass.value === "" ||
        inputData.price.value === "" ||
        inputData.imageFiles.value.length < 2;
    } else if (inputData.type.value === "experience") {
      disableFlag =
        inputData.language.value === "" ||
        inputData.classType.value === "" ||
        inputData.title.value === "" ||
        descriptionText === "" ||
        inputData.title.value.length < 5 ||
        descriptionText.length < 10 ||
        inputData.level.value.length < 1 ||
        inputData.dates.value.length < 1 ||
        inputData.startTime.value === "" ||
        inputData.endTime.value === "" ||
        inputData.numberOfPlaces.value === "" ||
        inputData.price.value === "" ||
        inputData.imageFiles.value.length < 2;
    }
    if (inputData.classType.value === "face-to-face" && !disableFlag) {
      if (
        inputData.type.value === "group" ||
        inputData.type.value === "experience"
      ) {
        disableFlag =
          inputData.city.value === "" ||
          inputData.postalCode.value === "" ||
          inputData.address.value === "" ||
          inputData.coordinates.value.latitude === 17.308688;
      } else {
        disableFlag =
          inputData.city.value === "" ||
          inputData.coordinates.value.latitude === 17.308688;
      }
    }
    setIsDisable(disableFlag);
  }, [inputData, descriptionText]);

  const submitHandler = async (isDraft) => {
    try {
      let imageUrl = [];

      if (toBeDeletedImages?.length > 0) {
        toBeDeletedImages.forEach((imgUrl) => {
          S3ImageDelete(imgUrl, localStorage.getItem("userId"));
        });
      }

      if (inputData?.imageFiles?.value.length > 0) {
        for (let i = 0; i < inputData.imageFiles.value.length; i++) {
          if (typeof inputData.imageFiles.value[i] === "string") {
            imageUrl.push(inputData.imageFiles.value[i]);
          } else {
            let url = await S3ImageUploader(
              inputData.imageFiles.value[i],
              localStorage.getItem("userId"),
              setLoading
            );
            imageUrl.push(url);
          }
        }
      }
      let data = {};
      data["type"] = inputData.type.value;
      data["language"] = inputData.language.value;
      data["classType"] = inputData.classType.value;
      data["title"] = inputData.title.value;
      data["description"] = inputData.description.value;
      data["price"] = inputData.price.value;
      data["imageFiles"] = imageUrl;
      if (inputData.type.value === "group") {
        data["level"] = inputData.level.value;
        data["dayOfTheWeek"] = inputData.dayOfTheWeek.value;
        data["dateRange"] = inputData.dateRange.value;
        data["startTime"] = inputData.startTime.value;
        data["duration"] = inputData.duration.value;
        if (inputData.discount.value !== "") {
          data["discount"] = inputData.discount.value;
        }
        data["numberOfPlaces"] = 5;
      } else if (
        inputData.type.value === "one-to-one" ||
        inputData.type.value === "children"
      ) {
        data["trialClass"] = inputData.trialClass.value;
      } else if (inputData.type.value === "experience") {
        data["level"] = inputData.level.value;
        data["dates"] = inputData.dates.value;
        data["startTime"] = inputData.startTime.value;
        data["endTime"] = inputData.endTime.value;
        data["numberOfPlaces"] = inputData.numberOfPlaces.value;
      }
      if (inputData.classType.value === "face-to-face") {
        if (
          inputData.type.value === "group" ||
          inputData.type.value === "experience"
        ) {
          data["city"] = inputData.city.value;
          data["postalCode"] = inputData.postalCode.value;
          data["address"] = inputData.address.value;
          data["coordinates"] = inputData.coordinates.value;
        } else {
          data["coordinates"] = inputData.coordinates.value;
          data["city"] = inputData.city.value;
        }
      }
      if (isDraft) {
        if (activeTab === "Modify Drafts") {
          dispatch(updateLessonDraft(lessonId, data, history));
        } else {
          dispatch(saveLessonDraft(data, history));
        }
      } else if (lessonId && activeTab === "Modify Ads") {
        dispatch(updateLesson(lessonId, data, history));
      } else {
        dispatch(createLesson(data, history, lessonId));
      }
    } catch (error) {
      console.log("error", error);
      toast.error(t("general_something_went_wrong_error"));
    }
  };

  const setLoading = (loading) => {
    setImageUploading(loading);
  };

  const { oneToOneLessonPrice, childrenLessonPrice, cards } = useSelector(
    ({ EnglishCafe }) => EnglishCafe.payment
  );
  useEffect(() => {
    dispatch(getLessonPriceForTeacher());
  }, [dispatch]);

  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => window.removeEventListener("resize", handleWindowSizeChange);
  }, []);
  const isMobile = width <= 600;

  const paymentLoading = useSelector(
    ({ EnglishCafe }) => EnglishCafe.payment.loading
  );
  const [submitInitiated, setSubmitInitiated] = useState(false);

  useEffect(() => {
    if (submitInitiated && cards?.length > 0) {
      submitHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cards, submitInitiated]);

  return (
    <>
      <Loader loading={imageUploading || loading || paymentLoading} />
      <div
        className={`flex flex-col overflow-y-scroll ${
          !isMobile && "styledScrollbar"
        }  md:pb-10 mr-1 mb-1`}
      >
        <div className="flex flex-col w-full md:items-center">
          {lessonId ? (
            <>
              <p className="mt-6 font-bold">{t("lesson_form_ad_type_label")}</p>
              <div className="flex my-7">
                <Icon
                  type={`${
                    classType === "group"
                      ? "filledgroupClassIcon"
                      : classType === "one-to-one"
                      ? "filledoneOnOneClassIcon"
                      : classType === "children"
                      ? "filledchildrenClassIcon"
                      : "filledexperienceClassIcon"
                  }`}
                />
              </div>
            </>
          ) : (
            <>
              <div className="flex items-center mt-6">
                <p className="font-bold">
                  {t("lesson_form_choose_ad_type_label")}
                </p>
              </div>
              <div className="flex my-7">
                <Icon
                  type={`${
                    classType === "group"
                      ? "filledgroupClassIcon"
                      : "groupClassIcon"
                  }`}
                  classNames="cursor-pointer"
                  onClick={() => {
                    typeHandler("group");
                  }}
                />
                <Icon
                  classNames="cursor-pointer ml-3"
                  type={`${
                    classType === "one-to-one"
                      ? "filledoneOnOneClassIcon"
                      : "oneOnOneClassIcon"
                  }`}
                  onClick={() => {
                    typeHandler("one-to-one");
                  }}
                />
                <Icon
                  classNames="cursor-pointer ml-3"
                  type={`${
                    classType === "children"
                      ? "filledchildrenClassIcon"
                      : "childrenClassIcon"
                  }`}
                  onClick={() => {
                    typeHandler("children");
                  }}
                />
                <Icon
                  classNames="cursor-pointer ml-3"
                  type={`${
                    classType === "experience"
                      ? "filledexperienceClassIcon"
                      : "experienceClassIcon"
                  }`}
                  onClick={() => {
                    typeHandler("experience");
                  }}
                />
              </div>
            </>
          )}
          <p className="font-bold text-3xl">
            {classType === "group" && t("lesson_form_type_group")}
            {classType === "one-to-one" && t("lesson_form_type_one_on_one")}
            {classType === "children" && t("lesson_form_type_children")}
            {classType === "experience" && t("lesson_form_type_experience")}
          </p>
          <form
            className="flex flex-col md:flex-row"
            onSubmit={(event) => {
              event.preventDefault();
            }}
          >
            <div className="flex flex-col">
              <div className="mt-6 w-full md:customLessonFormWidth">
                <div className="flex items-center mb-2">
                  <p className="font-bold text-xs">
                    {t("lesson_form_language_label")}
                  </p>
                  <Tooltip
                    enterTouchDelay={10}
                    title={t("lesson_form_language_tooltip")}
                  >
                    <div className="ml-1 self-start w-4">
                      <Icon type="hintIcon" />
                    </div>
                  </Tooltip>
                </div>
                <Select
                  onChange={inputHandler}
                  name="language"
                  options={languageOptions}
                  value={inputData?.language?.value}
                />
              </div>
              <div className="mt-6 w-full md:customLessonFormWidth">
                <div className="flex items-center">
                  <p className="font-bold text-xs">
                    {t("lesson_form_classType_label")}
                  </p>
                </div>
                <RadioGroup
                  aria-label="classType"
                  name="classType"
                  row
                  defaultValue={
                    inputData?.classType?.value
                      ? inputData?.classType?.value
                      : ""
                  }
                  onChange={(event) => {
                    inputHandler(event);
                  }}
                  value={
                    inputData?.classType?.value
                      ? inputData?.classType?.value
                      : ""
                  }
                >
                  <FormControlLabel
                    value="online"
                    control={<Radio />}
                    label={t("lesson_form_classType_online_label")}
                  />
                  <FormControlLabel
                    value="face-to-face"
                    control={<Radio />}
                    label={t("lesson_form_classType_face_to_face_label")}
                  />
                </RadioGroup>
              </div>
              {inputData?.classType?.value === "face-to-face" && (
                <>
                  {inputData?.type?.value === "group" ||
                  inputData?.type?.value === "experience" ? (
                    <>
                      <div className="mt-6 ">
                        <div className="flex items-center mb-2">
                          <p className="font-bold text-xs">
                            {t("lesson_form_city_label")}
                          </p>
                        </div>
                        <input
                          onChange={(event) => {
                            locationChangeHandler(event);
                          }}
                          value={inputData?.city?.value}
                          name="city"
                          type="text"
                          placeholder={t("lesson_form_city_placeholder")}
                          className="bg-white w-full md:customLessonFormWidth p-4 rounded border border-border-gray outline-none"
                        />
                      </div>

                      <div className="mt-6">
                        <div className="flex items-center mb-2">
                          <p className="font-bold text-xs">
                            {t("lesson_form_postal_code_label")}
                          </p>
                        </div>
                        <input
                          onChange={(event) => {
                            locationChangeHandler(event);
                          }}
                          value={inputData?.postalCode?.value}
                          name="postalCode"
                          type="text"
                          placeholder={t("lesson_form_postal_code_placeholder")}
                          className="bg-white w-full md:customLessonFormWidth p-4 rounded border border-border-gray outline-none"
                        />
                      </div>
                      <div className="mt-6">
                        <div className="flex items-center mb-2">
                          <p className="font-bold text-xs">
                            {t("lesson_form_address_label")}
                          </p>
                          <Tooltip title={t("lesson_form_address_tooltip")}>
                            <div className="ml-1 self-start w-4">
                              <Icon type="hintIcon" />
                            </div>
                          </Tooltip>
                        </div>
                        <input
                          onChange={(event) => {
                            locationChangeHandler(event);
                          }}
                          value={inputData?.address?.value}
                          name="address"
                          type="text"
                          placeholder={t("lesson_form_address_placeholder")}
                          className="bg-white w-full md:customLessonFormWidth p-4 rounded border border-border-gray outline-none"
                        />
                      </div>
                      <div className="mt-6 ">
                        <div className="flex items-center mb-2">
                          <p className="font-bold text-xs">
                            {t("lesson_form_location_on_map_label")}
                          </p>
                        </div>
                        <div classNames="w-full md:customLessonFormWidth h-40">
                          <Map
                            latitude={inputData?.coordinates?.value?.latitude}
                            longitude={inputData?.coordinates?.value?.longitude}
                            zoom={mapsZoom}
                            isMarkerShown={true}
                            setInputData={setInputData}
                            isDraggable={true}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="mt-6 ">
                      <div className="flex items-center mb-2">
                        <p className="font-bold text-xs">
                          {t("lesson_form_city_label")}
                        </p>
                        <Tooltip title={t("lesson_form_city_tooltip")}>
                          <div className="ml-1 self-start w-4">
                            <Icon type="hintIcon" />
                          </div>
                        </Tooltip>
                      </div>
                      <input
                        onChange={(event) => {
                          locationChangeHandler(event);
                        }}
                        value={inputData?.city?.value}
                        name="city"
                        type="text"
                        placeholder={t("lesson_form_city_placeholder")}
                        className="bg-white w-full md:customLessonFormWidth p-4 rounded border border-border-gray outline-none"
                      />
                    </div>
                  )}
                </>
              )}
              <div className="mt-6">
                <div className="flex items-center mb-2">
                  <p className="font-bold text-xs">
                    {t("lesson_form_title_label")}
                  </p>
                  <Tooltip title={t("lesson_form_title_tooltip")}>
                    <div className="ml-1 self-start w-4">
                      <Icon type="hintIcon" />
                    </div>
                  </Tooltip>
                </div>
                <input
                  name="title"
                  onChange={inputHandler}
                  value={inputData?.title?.value}
                  type="text"
                  placeholder={t("lesson_form_title_placeholder")}
                  className={`bg-white w-full md:customLessonFormWidth p-4 rounded border outline-none  ${
                    inputData?.title?.value !== "" &&
                    inputData?.title?.value?.length < 5
                      ? "border-secondary-main"
                      : "border-border-gray"
                  }`}
                />
              </div>
              <div className="mt-6">
                <div className="flex items-center mb-2">
                  <p className="font-bold text-xs">
                    {t("lesson_form_description_label")}
                  </p>
                  <Tooltip title={t("lesson_form_description_tooltip")}>
                    <div className="ml-1 self-start w-4">
                      <Icon type="hintIcon" />
                    </div>
                  </Tooltip>
                </div>
                <QuillEditor
                  name="description"
                  value={inputData?.description?.value}
                  placeholder={t("lesson_form_description_placeholder")}
                  className={`bg-white w-full md:customLessonFormWidth rounded border outline-none ${
                    descriptionText !== "" && descriptionText?.length < 10
                      ? "border-secondary-main"
                      : "border-border-gray"
                  }`}
                  onChange={inputHandler}
                  setDescriptionText={setDescriptionText}
                />
                {/* <textarea
                  name="description"
                  className={`bg-white w-full md:customLessonFormWidth p-4 rounded border  outline-none ${
                    inputData?.description?.value !== "" &&
                    inputData?.description?.value?.length < 10
                      ? "border-secondary-main"
                      : "border-border-gray"
                  }`}
                  placeholder={t("lesson_form_description_placeholder")}
                  value={inputData?.description?.value}
                  rows={4}
                  onChange={inputHandler}
                /> */}
              </div>
            </div>

            <div className="md:ml-24 flex flex-col">
              {inputData?.type?.value === "group" ||
              inputData?.type?.value === "experience" ? (
                <>
                  <div className="mt-6 w-full md:customLessonFormWidth">
                    <div className="flex items-center mb-2">
                      <p className="font-bold text-xs">
                        {t("lesson_form_level_label")}
                      </p>
                      <Tooltip title={t("lesson_form_level_tooltip")}>
                        <div className="ml-1 self-start w-4">
                          <Icon type="hintIcon" />
                        </div>
                      </Tooltip>
                    </div>
                    <Select
                      onChange={inputHandler}
                      name="level"
                      options={Level}
                      isMulti={true}
                      value={inputData?.level?.value}
                    />
                  </div>
                  <div className="mt-6 w-full md:customLessonFormWidth">
                    <div className="flex items-center mb-2">
                      <p className="font-bold text-xs">
                        {t("lesson_form_start_time_label")}
                      </p>
                    </div>
                    <TextField
                      id="startTime"
                      name="startTime"
                      type="time"
                      className="w-full md:customLessonFormWidth"
                      value={
                        inputData?.startTime?.value !== "" &&
                        inputData?.startTime?.value
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300,
                      }}
                      onChange={inputHandler}
                    />
                  </div>
                  {inputData.type.value === "group" && (
                    <div className="mt-6 w-full md:customLessonFormWidth">
                      <div className="flex items-center mb-2">
                        <p className="font-bold text-xs">
                          {t("lesson_form_class_duration_label")}
                        </p>
                        <Tooltip
                          title={
                            inputData.type.value === "group"
                              ? t("lesson_form_duration_group_tooltip")
                              : t("lesson_form_duration_experience_tooltip")
                          }
                        >
                          <div className="ml-1 self-start w-4">
                            <Icon type="hintIcon" />
                          </div>
                        </Tooltip>
                      </div>
                      <Select
                        onChange={inputHandler}
                        name="duration"
                        options={OptionNumberGenerator(2)}
                        value={inputData?.duration?.value}
                      />
                    </div>
                  )}
                  {inputData?.type?.value === "experience" && (
                    <div className="mt-6 w-full md:customLessonFormWidth">
                      <div className="flex items-center mb-2">
                        <p className="font-bold text-xs">
                          {t("lesson_form_end_time_label")}
                        </p>
                      </div>
                      <TextField
                        id="endTime"
                        name="endTime"
                        type="time"
                        className="w-full md:customLessonFormWidth"
                        value={
                          inputData?.endTime?.value !== "" &&
                          inputData?.endTime?.value
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300,
                        }}
                        onChange={inputHandler}
                      />
                    </div>
                  )}
                  {inputData?.type?.value === "group" && (
                    <div className="mt-6 w-full md:customLessonFormWidth">
                      <div className="flex items-center mb-2">
                        <p className="font-bold text-xs">
                          {t("lesson_form_day_of_week_label")}
                        </p>
                        <Tooltip title={t("lesson_form_day_of_week_tooltip")}>
                          <div className="ml-1 self-start w-4">
                            <Icon type="hintIcon" />
                          </div>
                        </Tooltip>
                      </div>
                      <Select
                        onChange={inputHandler}
                        name="dayOfTheWeek"
                        options={weekDays}
                        value={inputData?.dayOfTheWeek?.value}
                      />
                    </div>
                  )}
                  <div className="mt-6 w-full md:customLessonFormWidth relative">
                    <div className="flex items-center mb-2">
                      <p className="font-bold text-xs">
                        {t("lesson_form_schedule_label")}
                      </p>
                      <Tooltip
                        title={
                          inputData?.type?.value === "group"
                            ? t("lesson_form_schedule_group_tooltip")
                            : t("lesson_form_schedule_experience_tooltip")
                        }
                      >
                        <div className="ml-1 self-start w-4">
                          <Icon type="hintIcon" />
                        </div>
                      </Tooltip>
                    </div>
                    <div className="w-full md:customLessonFormWidth">
                      {inputData?.type?.value === "group" ? (
                        <CustomDateRangePicker
                          name="dateRange"
                          dateRange={inputData?.dateRange}
                          onChange={dateRangeHandler}
                        />
                      ) : (
                        <MultipleDateSelector
                          dates={inputData?.dates?.value}
                          name="dates"
                          onChange={inputHandler}
                        />
                      )}
                    </div>
                  </div>

                  <div className="mt-6 w-full md:customLessonFormWidth">
                    <div className="flex items-center mb-2">
                      <p className="font-bold text-xs">
                        {t("lesson_form_number_of_places_label")}
                      </p>
                      <Tooltip
                        title={
                          inputData?.type?.value === "group"
                            ? t("lesson_form_number_of_places_group_tooltip")
                            : t(
                                "lesson_form_number_of_places_experience_tooltip"
                              )
                        }
                      >
                        <div className="ml-1 self-start w-4">
                          <Icon type="hintIcon" />
                        </div>
                      </Tooltip>
                    </div>
                    {inputData?.type?.value === "group" ? (
                      <input
                        name="numberOfPlaces"
                        type="number"
                        disabled
                        value="5"
                        className="bg-border-lightGray w-full md:customLessonFormWidth p-4 rounded border  border-border-gray outline-none"
                      />
                    ) : (
                      <Select
                        onChange={inputHandler}
                        name="numberOfPlaces"
                        options={OptionNumberGenerator(50)}
                        value={inputData?.numberOfPlaces?.value}
                      />
                    )}
                  </div>
                </>
              ) : (
                <div className="mt-6 w-full md:customLessonFormWidth">
                  <div className="flex items-center mb-2">
                    <p className="font-bold text-xs">
                      {t("lesson_form_trial_class_label")}
                    </p>
                    <Tooltip title={t("lesson_form_trial_class_tooltip")}>
                      <div className="ml-1 self-start w-4">
                        <Icon type="hintIcon" />
                      </div>
                    </Tooltip>
                  </div>
                  <RadioGroup
                    aria-label="trialClass"
                    name="trialClass"
                    row
                    defaultValue={
                      inputData?.trialClass?.value
                        ? inputData?.trialClass?.value
                        : ""
                    }
                    onChange={inputHandler}
                    value={
                      inputData?.trialClass?.value
                        ? inputData?.trialClass?.value
                        : ""
                    }
                  >
                    <FormControlLabel
                      value={"yes"}
                      control={<Radio />}
                      label={t("lesson_form_trial_class_yes_label")}
                    />
                    <FormControlLabel
                      value={"no"}
                      control={<Radio />}
                      label={t("lesson_form_trial_class_no_label")}
                    />
                  </RadioGroup>
                </div>
              )}
              <div className="mt-6">
                {inputData.type.value === "group" && (
                  <div className="flex items-center mb-2">
                    <p className="font-bold text-xs mb-2">
                      {t("lesson_form_monthly_price_label")}
                    </p>
                    <Tooltip title={t("lesson_form_monthly_price_tooltip")}>
                      <div className="ml-1 self-start w-4">
                        <Icon type="hintIcon" />
                      </div>
                    </Tooltip>
                  </div>
                )}
                {(inputData.type.value === "one-to-one" ||
                  inputData.type.value === "children") && (
                  <div className="flex items-center mb-2">
                    <p className="font-bold text-xs mb-2">
                      {t("lesson_form_price_per_hour_label")}
                    </p>
                    <Tooltip title={t("lesson_form_price_per_hour_tooltip")}>
                      <div className="ml-1 self-start w-4">
                        <Icon type="hintIcon" />
                      </div>
                    </Tooltip>
                  </div>
                )}
                {inputData.type.value === "experience" && (
                  <div className="flex items-center mb-2">
                    <p className="font-bold text-xs mb-2">
                      {t("lesson_form_price_for_student_label")}
                    </p>
                    <Tooltip title={t("lesson_form_price_for_student_tooltip")}>
                      <div className="ml-1 self-start w-4">
                        <Icon type="hintIcon" />
                      </div>
                    </Tooltip>
                  </div>
                )}
                <input
                  name="price"
                  onChange={inputHandler}
                  value={inputData?.price?.value}
                  min="0"
                  type="Number"
                  placeholder={t("lesson_form_price_placeholder")}
                  className="bg-white w-full md:customLessonFormWidth  p-4 rounded border border-border-gray outline-none"
                />
                {(inputData.type.value === "group" ||
                  inputData.type.value === "experience") &&
                  inputData?.price?.value && (
                    <p className="font-bold text-xs mt-1">
                      {t("lesson_form_amount_recieve_label")}
                      {"€ " + percentageValue(80, inputData?.price?.value)}
                    </p>
                  )}
                {inputData.type.value === "group" && (
                  <div className="mt-6 w-full md:customLessonFormWidth">
                    <div className="flex items-center mb-2">
                      <p className="font-bold text-xs">
                        {t("lesson_form_discount_label")}
                      </p>
                      <Tooltip title={t("lesson_form_discount_tooltip")}>
                        <div className="ml-1 self-start w-4">
                          <Icon type="hintIcon" />
                        </div>
                      </Tooltip>
                    </div>
                    <Select
                      onChange={inputHandler}
                      name="discount"
                      options={Discounts}
                      value={inputData?.discount?.value}
                    />
                  </div>
                )}
              </div>
              <div className="mt-6 flex flex-col items-center self-start">
                <div className="flex items-center mb-2">
                  <p className="font-bold text-xs mb-2">
                    {t("lesson_form_ad_thumbnail_label")}
                  </p>
                  <Tooltip title={t("lesson_form_ad_thumbnail_tooltip")}>
                    <div className="ml-1 self-start w-4">
                      <Icon type="hintIcon" />
                    </div>
                  </Tooltip>
                </div>
                <div className="p-2 md:p-3 border border-dashed border-border-gray rounded-xl">
                  <input
                    type="file"
                    id="uploadImage"
                    accept="image/*"
                    className="hidden"
                    multiple
                    value={""}
                    onChange={uploadFile}
                  />
                  <label htmlFor="uploadImage" className="cursor-pointer">
                    <Icon classNames="w-24" type="uploadImageIcon" />
                  </label>
                </div>
              </div>
              {inputData?.imageFiles?.value !== "" &&
                inputData?.imageFiles?.value?.length > 0 && (
                  <div className="flex overflow-x-auto relative w-full md:customLessonFormWidth  mt-2 border border-dashed border-border-gray rounded-xl styledScrollbar px-2 py-2">
                    {inputData?.imageFiles?.value?.map((image, index) => {
                      return (
                        <div
                          className="flex flex-shrink-0 w-40 h-40 relative mr-2"
                          key={index}
                        >
                          <img
                            alt="userImage"
                            className="w-full h-full rounded-md object-cover"
                            src={
                              typeof image === "string"
                                ? image
                                : URL.createObjectURL(image)
                            }
                          />
                          <Icon
                            type="closeIconRed"
                            classNames="absolute w-4 cursor-pointer top-2 right-2"
                            onClick={() => {
                              if (typeof image === "string") {
                                let newarr =
                                  inputData?.imageFiles?.value.filter(
                                    (rm) => rm !== image
                                  );

                                setToBeDeletedImages((prevState) => [
                                  ...prevState,
                                  image,
                                ]);

                                setInputData((prevForm) => ({
                                  ...prevForm,
                                  imageFiles: {
                                    value: [...newarr],
                                  },
                                }));
                                setInputChanged(true);
                              } else {
                                let newarr =
                                  inputData?.imageFiles?.value.filter(
                                    (rm) => rm.name !== image.name
                                  );
                                setInputData((prevForm) => ({
                                  ...prevForm,
                                  imageFiles: {
                                    value: [...newarr],
                                  },
                                }));
                              }
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
              <div className="mt-6 flex items-center w-full md:customLessonFormWidth">
                <Checkbox
                  checked={checked}
                  onChange={() => {
                    setChecked(!checked);
                  }}
                />
                <div className="flex items-center">
                  <a
                    href="https://www.englishcafe.es/terminos-condiciones"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <p className="font-bold text-xs cursor-pointer">
                      {t("lesson_form_terms_of_service_label")}
                    </p>
                  </a>
                </div>
              </div>
              <div className="flex justify-center mt-6">
                <Button
                  classNames="w-32 self-end"
                  color="secondary"
                  isDisable={
                    isDisable ||
                    (activeTab === "Modify Ads" && !inputChanged) ||
                    !checked ||
                    locationLoading
                  }
                  onClick={() => {
                    if (
                      !lessonId &&
                      (inputData.type.value === "one-to-one" ||
                        inputData.type.value === "children")
                    ) {
                      setOpenPaymentModal(true);
                    } else {
                      submitHandler();
                    }
                  }}
                >
                  {lessonId && activeTab === "Modify Ads"
                    ? t("lesson_form_update_ad")
                    : t("lesson_form_create_ad")}
                </Button>
                {activeTab !== "Modify Ads" && (
                  <Button
                    classNames="w-32 self-end ml-2"
                    color="secondary"
                    isDisable={
                      inputData.title.value === "" ||
                      inputData?.title?.value?.length < 5 ||
                      !inputChanged ||
                      locationLoading
                    }
                    onClick={() => {
                      submitHandler(true);
                    }}
                  >
                    {lessonId && activeTab === "Modify Drafts"
                      ? t("lesson_form_update_draft")
                      : t("lesson_form_save_draft")}
                  </Button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
      <ConfirmModal
        headerText={`${t("lesson_form_charge_text1")} ${
          classType === "one-to-one"
            ? oneToOneLessonPrice / 100
            : childrenLessonPrice / 100
        } ${t("lesson_form_charge_text2")}`}
        open={openPaymentModal}
        onClose={() => {
          setOpenPaymentModal(false);
        }}
        onConfirm={() => {
          setOpenPaymentModal(false);
          setSubmitInitiated(true);
          if (!cards.length > 0) {
            setOpenCardModal(true);
          }
        }}
      />
      <ConfirmModal
        headerText={t("lesson_form_confirm_image_upload")}
        open={openConfirmImageModal}
        onClose={() => {
          setOpenConfirmImageModal(false);
        }}
        onConfirm={() => {
          setOpenConfirmImageModal(false);
        }}
        notCancellable={true}
      />
      <AddCardModal
        open={openCardModal}
        onClose={() => {
          setOpenCardModal(false);
        }}
      />
    </>
  );
}

export default LessonForm;
