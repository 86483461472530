import React from "react";
import { useHistory } from "react-router-dom";
import Icon from "./Icon";

function BackButton(props) {
  const history = useHistory();
  return (
    <div className={`w-4 cursor-pointer ${props.classNames}`}>
      <Icon
        type="backArrow"
        onClick={() => {
          history.goBack();
        }}
      />
    </div>
  );
}

export default BackButton;
