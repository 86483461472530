import React from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { socialAuthenticate } from "@store/auth/AuthActions";
import { Button, Icon } from "@components";
import { facebookProvider, googleProvider } from "config/authMethods";
import { socialMediaAuth } from "@helpers/utils";

function SocialAuth({ isLogin, userType }) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleOnClick = async (provider) => {
    const response = await socialMediaAuth(provider);
    let name = "";
    let surname = "";

    if (response.displayName) {
      const nameArray = response.displayName.split(" ");
      name = nameArray[0];
      if (nameArray[1]) {
        surname = nameArray[1];
      }
    }
    const data = {
      email: response.email,
      name,
      surname,
    };
    if (data.email) {
      await dispatch(socialAuthenticate(data, history, userType));
    } else {
      toast.error(t("general_login_failed_error"));
    }
  };

  return (
    <div className="flex flex-col">
      <Button
        classNames="w-full mt-6 md:mt-8 rounded-3xl py-2 md:py-3"
        style={{ backgroundColor: "#3C5A99" }}
        onClick={() => handleOnClick(facebookProvider)}
      >
        <div className="w-5 mr-2">
          <Icon type="facebookIcon" />
        </div>
        {`${isLogin ? t("general_login") : t("general_signup")} ${t(
          "auth_social_facebook"
        )}`}
      </Button>
      <Button
        classNames="w-full mt-2 rounded-3xl py-2 md:py-3 bg-white text-black"
        onClick={() => handleOnClick(googleProvider)}
      >
        <div className="w-5 mr-2">
          <Icon type="googleIcon" />
        </div>
        {`${isLogin ? t("general_login") : t("general_signup")} ${t(
          "auth_social_google"
        )}`}
      </Button>
    </div>
  );
}

export default SocialAuth;
