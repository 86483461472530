export const AuthActionTypes = {
  CREATE_USER_START: "CREATE_USER_START",
  CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
  CREATE_USER_FAIL: "CREATE_USER_FAIL",

  LOGIN_USER_START: "LOGIN_USER_START",
  LOGIN_USER_SUCCESS: "LOGIN_USER_SUCCESS",
  LOGIN_USER_FAIL: "LOGIN_USER_FAIL",

  GET_USER_START: "GET_USER_START",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_USER_FAIL: "GET_USER_FAIL",

  SOCIAL_AUTH_START: "SOCIAL_AUTH_START",
  SOCIAL_AUTH_SUCCESS: "SOCIAL_AUTH_SUCCESS",
  SOCIAL_AUTH_FAIL: "SOCIAL_AUTH_FAIL",

  ONBOARD_USER_START: "ONBOARD_USER_START",
  ONBOARD_USER_SUCCESS: "ONBOARD_USER_SUCCESS",
  ONBOARD_USER_FAIL: "ONBOARD_USER_FAIL",

  UPDATE_USER_START: "UPDATE_USER_START",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAIL: "UPDATE_USER_FAIL",

  FORGOT_PASSWORD_START: "FORGOT_PASSWORD_START",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAIL: "FORGOT_PASSWORD_FAIL",

  UPDATE_PASSWORD_START: "UPDATE_PASSWORD_START",
  UPDATE_PASSWORD_SUCCESS: "UPDATE_PASSWORD_SUCCESS",
  UPDATE_PASSWORD_FAIL: "UPDATE_PASSWORD_FAIL",

  LOGOUT: "LOGOUT",
  ONBOARD: "ONBOARD",

  GET_TEACHER_PROFILE_START: "GET_TEACHER_PROFILE_START",
  GET_TEACHER_PROFILE_SUCCESS: "GET_TEACHER_PROFILE_SUCCESS",
  GET_TEACHER_PROFILE_FAIL: "GET_TEACHER_PROFILE_FAIL",

  GET_MONTHLY_REVENUE_START: "GET_MONTHLY_REVENUE_START",
  GET_MONTHLY_REVENUE_SUCCESS: "GET_MONTHLY_REVENUE_SUCCESS",
  GET_MONTHLY_REVENUE_FAIL: "GET_MONTHLY_REVENUE_FAIL",

  GET_TEACHER_REVENUE_LIST_START: "GET_TEACHER_REVENUE_LIST_START",
  GET_TEACHER_REVENUE_LIST_SUCCESS: "GET_TEACHER_REVENUE_LIST_SUCCESS",
  GET_TEACHER_REVENUE_LIST_FAIL: "GET_TEACHER_REVENUE_LIST_FAIL",
};

export const LessonActionTypes = {
  GET_LESSONS_START: "GET_LESSONS_START",
  GET_LESSONS_SUCCESS: "GET_LESSONS_SUCCESS",
  GET_LESSONS_FAIL: "GET_LESSONS_FAIL",

  GET_HOME_SCREEN_LESSONS_START: "GET_HOME_SCREEN_LESSONS_START",
  GET_HOME_SCREEN_LESSONS_SUCCESS: "GET_HOME_SCREEN_LESSONS_SUCCESS",
  GET_HOME_SCREEN_LESSONS_FAIL: "GET_HOME_SCREEN_LESSONS_FAIL",

  GET_LESSON_BY_ID_START: "GET_LESSON_BY_ID_START",
  GET_LESSON_BY_ID_SUCCESS: "GET_LESSON_BY_ID_SUCCESS",
  GET_LESSON_BY_ID_FAIL: "GET_LESSON_BY_ID_FAIL",

  CREATE_LESSON_START: "CREATE_LESSON_START",
  CREATE_LESSON_SUCCESS: "CREATE_LESSON_SUCCESS",
  CREATE_LESSON_FAIL: "CREATE_LESSON_FAIL",

  GET_LESSONS_BY_TEACHER_ID_START: "GET_LESSONS_BY_TEACHER_ID_START",
  GET_LESSONS_BY_TEACHER_ID_SUCCESS: "GET_LESSONS_BY_TEACHER_ID_SUCCESS",
  GET_LESSONS_BY_TEACHER_ID_FAIL: "GET_LESSONS_BY_TEACHER_ID_FAIL",

  SHOW_HIDE_LESSON_START: "SHOW_HIDE_LESSON_START",
  SHOW_HIDE_LESSON_SUCCESS: "SHOW_HIDE_LESSON_SUCCESS",
  SHOW_HIDE_LESSON_FAIL: "SHOW_HIDE_LESSON_FAIL",

  DELETE_LESSON_START: "DELETE_LESSON_START",
  DELETE_LESSON_SUCCESS: "DELETE_LESSON_SUCCESS",
  DELETE_LESSON_FAIL: "DELETE_LESSON_FAIL",

  UPDATE_LESSON_START: "UPDATE_LESSON_START",
  UPDATE_LESSON_SUCCESS: "UPDATE_LESSON_SUCCESS",
  UPDATE_LESSON_FAIL: "UPDATE_LESSON_FAIL",

  SET_LESSON_ID: "SET_LESSON_ID",

  SET_LESSON_TYPE: "SET_LESSON_TYPE",

  SET_FILTER_CITY: "SET_FILTER_CITY",
  SET_FILTER_CATEGORY: "SET_FILTER_CATEGORY",
  SET_FILTER_ACTIVE: "SET_FILTER_ACTIVE",
  SET_FILTER: "SET_FILTER",
  SET_FILTER_VALUES: "SET_FILTER_VALUES",
  SET_IS_FILTER_VISIBLE: "SET_IS_FILTER_VISIBLE",

  GET_SUBSCRIBERS_START: "GET_SUBSCRIBERS_START",
  GET_SUBSCRIBERS_SUCCESS: "GET_SUBSCRIBERS_SUCCESS",
  GET_SUBSCRIBERS_FAIL: "GET_SUBSCRIBERS_FAIL",

  ADD_REVIEW_START: "ADD_REVIEW_START",
  ADD_REVIEW_SUCCESS: "ADD_REVIEW_SUCCESS",
  ADD_REVIEW_FAIL: "ADD_REVIEW_FAIL",

  GET_TEACHERS_BY_STUDENT_ID_START: "GET_TEACHERS_BY_STUDENT_ID_START",
  GET_TEACHERS_BY_STUDENT_ID_SUCCESS: "GET_TEACHERS_BY_STUDENT_ID_SUCCESS",
  GET_TEACHERS_BY_STUDENT_ID_FAIL: "GET_TEACHERS_BY_STUDENT_ID_FAIL",

  GET_TOP_TEACHERS_START: "GET_TOP_TEACHERS_START",
  GET_TOP_TEACHERS_SUCCESS: "GET_TOP_TEACHERS_SUCCESS",
  GET_TOP_TEACHERS_FAIL: "GET_TOP_TEACHERS_FAIL",

  OPEN_SUCCESS_MODAL: "OPEN_SUCCESS_MODAL",

  GET_CALENDAR_DATES_START: "GET_CALENDAR_DATES_START",
  GET_CALENDAR_DATES_SUCCESS: "GET_CALENDAR_DATES_SUCCESS",
  GET_CALENDAR_DATES_FAIL: "GET_CALENDAR_DATES_FAIL",
};

export const ChatActionTypes = {
  GET_CHATS_START: "GET_CHAT_START",
  GET_CHATS_SUCCESS: "GET_CHAT_SUCCESS",
  GET_CHATS_FAIL: "GET_CHAT_FAIL",

  CREATE_CHAT_START: "CREATE_CHAT_START",
  CREATE_CHAT_SUCCESS: "CREATE_CHAT_SUCCESS",
  CREATE_CHAT_FAIL: "CREATE_CHAT_FAIL",

  SET_USER_CHAT_DETAILS: "SET_USER_CHAT_DETAILS",
};

export const MessageActionTypes = {
  CREATE_MESSAGE_START: "CREATE_MESSAGE_START",
  CREATE_MESSAGE_SUCCESS: "CREATE_MESSAGE_SUCCESS",
  CREATE_MESSAGE_FAIL: "GET_MESSAGES_FAIL",

  GET_MESSAGES_START: "GET_MESSAGES_START",
  GET_MESSAGES_SUCCESS: "GET_MESSAGES_SUCCESS",
  GET_MESSAGES_FAIL: "GET_MESSAGES_FAIL",

  SEEN_ALL_MESSAGES_START: "SEEN_ALL_MESSAGES_START",
  SEEN_ALL_MESSAGES_SUCCESS: "SEEN_ALL_MESSAGES_SUCCESS",
  SEEN_ALL_MESSAGES_FAIL: "SEEN_ALL_MESSAGES_FAIL",

  READ_ONE_MESSAGE_START: "READ_ONE_MESSAGE_START",
  READ_ONE_MESSAGE_SUCCESS: "READ_ONE_MESSAGE_SUCCESS",
  READ_ONE_MESSAGE_FAIL: "READ_ONE_MESSAGE_FAIL",
};

export const HeaderActionTypes = {
  SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
};

export const CartActionTypes = {
  ADD_CART_ITEM: "ADD_CART_ITEM",
  REMOVE_CART_ITEM: "REMOVE_CART_ITEM",
  REMOVE_SINGLE_CART_ITEM: "REMOVE_SINGLE_CART_ITEM",
  CLEAR_CART: "CLEAR_CART",
};

export const PaymentActionTypes = {
  ADD_CARD_START: "ADD_CARD_START",
  ADD_CARD_SUCCESS: "ADD_CARD_SUCCESS",
  ADD_CARD_FAIL: "ADD_CARD_FAIL",

  REMOVE_CARD_START: "REMOVE_CARD_START",
  REMOVE_CARD_SUCCESS: "REMOVE_CARD_SUCCESS",
  REMOVE_CARD_FAIL: "REMOVE_CARD_FAIL",

  MAKE_DEFAULT_CARD_START: "MAKE_DEFAULT_CARD_START",
  MAKE_DEFAULT_CARD_SUCCESS: "MAKE_DEFAULT_CARD_SUCCESS",
  MAKE_DEFAULT_CARD_FAIL: "MAKE_DEFAULT_CARD_FAIL",

  GET_USER_CARDS_START: "GET_USER_CARDS_START",
  GET_USER_CARDS_SUCCESS: "GET_USER_CARDS_SUCCESS",
  GET_USER_CARDS_FAIL: "GET_USER_CARDS_FAIL",

  MAKE_PAYMENT_START: "MAKE_PAYMENT_START",
  MAKE_PAYMENT_SUCCESS: "MAKE_PAYMENT_SUCCESS",
  MAKE_PAYMENT_FAIL: "MAKE_PAYMENT_FAIL",

  BUY_LESSON_START: "BUY_LESSON_START",
  BUY_LESSON_SUCCESS: "BUY_LESSON_SUCCESS",
  BUY_LESSON_FAIL: "BUY_LESSON_FAIL",

  GET_LESSON_PRICE_TEACHER_START: "GET_LESSON_PRICE_TEACHER_START",
  GET_LESSON_PRICE_TEACHER_SUCCESS: "GET_LESSON_PRICE_TEACHER_SUCCESS",
  GET_LESSON_PRICE_TEACHER_FAIL: "LESSON_PRICE_TEACHER_FAIL",
};
