import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Select, Button, CustomPhoneInput, MultiCheckbox } from "@components";
import { Provinces, Languages_list, Countries_List } from "@helpers/data";
import { updateUserProfile } from "@store/auth/AuthActions";
import Avatar from "./Avatar";
import { lighterImage } from "@helpers/imageCompression";
import { toast } from "react-toastify";
import { isImage, S3ImageDelete, S3ImageUploader } from "@helpers/utils";
import Loader from "./Loader";
import { validate, VALIDATOR_IS_URL } from "@helpers/validators";
import { EditPasswordModal } from "./modals";

function EditProfile({ user }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [inputData, setInputData] = useState({});
  const [isDisable, setIsDisable] = useState(false);
  const [inputTouched, setInputTouched] = useState(false);
  const [imageUploading, setImageUploading] = useState(false);
  let [languages, setLanguages] = useState(Languages_list);
  let [extraLanguage, setExtraLanguage] = useState("");
  let [validUrl, setValidUrl] = useState(true);
  const [editPasswordOpen, setEditPasswordOpen] = useState(false);

  const loading = useSelector(({ EnglishCafe }) => EnglishCafe.auth.loading);

  const inputHandler = (event) => {
    let temp = inputData;
    temp[`${event.target.name}`] = event.target.value;
    setInputData({ ...temp });
    let flag = false;
    for (let key in inputData) {
      if (
        key !== "videoLink" &&
        (inputData[key] === "" || inputData[key] === [])
      ) {
        flag = true;
      }
    }
    setInputTouched(true);
    setIsDisable(flag);
  };

  const userType = localStorage.getItem("userType");

  useEffect(() => {
    let temp = {};
    if (userType === "Teacher") {
      for (let key in user) {
        if (
          key === "name" ||
          key === "surname" ||
          key === "phoneNo" ||
          key === "province" ||
          key === "biography" ||
          key === "experiences" ||
          key === "aboutClasses" ||
          key === "languages" ||
          key === "videoLink" ||
          key === "image" ||
          key === "nationality"
        )
          temp[`${key}`] = user[key];
      }
    } else {
      for (let key in user) {
        if (
          key === "name" ||
          key === "surname" ||
          key === "phoneNo" ||
          key === "province" ||
          key === "languages" ||
          key === "profession" ||
          key === "image"
        )
          temp[`${key}`] = user[key];
      }
    }
    setInputData({ ...temp });
  }, [user, userType]);

  const languageChangeHandler = (inputValues) => {
    const inputLanguages = [];
    inputValues.forEach((e) => {
      if (e.value === true) {
        inputLanguages.push(e.label);
      }
    });

    setInputData((prevForm) => ({
      ...prevForm,
      languages: inputLanguages,
    }));
    setInputTouched(true);
  };

  const addExtraLanguageHandler = () => {
    let temp = languages;
    temp.push({ label: extraLanguage, value: true });
    setExtraLanguage("");
    languageChangeHandler([...temp]);
    setLanguages([...temp]);
  };

  useEffect(() => {
    const items = Languages_list;
    const defaultLanguages = Languages_list.map((e) => {
      return e.label;
    });
    const userLanguages = user.languages;
    const newItems = items.map((item) => {
      if (userLanguages.includes(item.label)) {
        return {
          ...item,
          value: true,
        };
      } else {
        return item;
      }
    });
    userLanguages.forEach((e) => {
      if (!defaultLanguages.includes(e)) {
        newItems.push({ label: e, value: true });
      }
    });
    setLanguages(newItems);
  }, [user, userType]);

  const uploadFile = async (event) => {
    let file1 = event.target.files[0];
    if (file1.type.split("/")[0] === "image") {
      if (!isImage(file1.type)) {
        toast.error(t(t("general_image_format_error")));
        return;
      }
    } else {
      toast.error(t("general_file_format_error"));
      return;
    }
    if (Math.floor(file1.size / 1048576) > 10) {
      toast.error(t("general_image_size_error"));
      return;
    }
    file1 = await lighterImage(file1);

    setInputData((prevForm) => ({
      ...prevForm,
      image: file1,
    }));
    setInputTouched(true);
  };

  const setLoading = (loading) => {
    setImageUploading(loading);
  };

  const submitHandler = async () => {
    let data = inputData;
    if (inputData?.image && typeof inputData.image !== "string") {
      S3ImageDelete(user.image, localStorage.getItem("userId"));
      const imageUrl = await S3ImageUploader(
        inputData.image,
        localStorage.getItem("userId"),
        setLoading
      );
      data = {
        ...data,
        image: imageUrl,
      };
    }
    if (data.videoLink === "") {
      data.emptyVideoLink = true;
    }
    dispatch(updateUserProfile(data));
  };

  return (
    <>
      <Loader loading={imageUploading || loading}></Loader>
      <form
        className={`flex flex-col overflow-scroll`}
        onSubmit={async (event) => {
          event.preventDefault();
          submitHandler();
        }}
      >
        <div
          className={`flex flex-col md:flex-row md:items-center ${
            userType === "Teacher" ? "md:pl-16" : "justify-center"
          }`}
        >
          <div className="flex flex-col">
            <div className="mt-6 flex justify-center">
              <input
                type="file"
                id="uploadImage"
                accept="image/*"
                className="hidden"
                value={""}
                onChange={uploadFile}
              />
              <label
                htmlFor="uploadImage"
                className="cursor-pointer flex items-center"
              >
                {inputData?.image ? (
                  <Avatar
                    uri={
                      typeof inputData?.image === "string"
                        ? inputData?.image
                        : URL.createObjectURL(inputData?.image)
                    }
                    name={inputData.name}
                    classNames="w-20 h-20 cursor-pointer"
                  />
                ) : (
                  <div
                    className={`flex items-center justify-center bg-secondary-main w-20 h-20`}
                    style={{ borderRadius: "100%" }}
                  >
                    <p className={`capitalize  text-3xl p-0 mt-1 text-white`}>
                      {inputData?.name?.charAt(0)}
                    </p>
                  </div>
                )}
                <p className="underline ml-2">
                  {t("profile_edit_profile_picture")}
                </p>
              </label>
            </div>
            <div className="flex justify-center mt-6">
              <Button
                color="secondary"
                onClick={() => setEditPasswordOpen(true)}
              >
                {t("profile_edit_profile_edit_password")}
              </Button>
            </div>
            <div className="mt-6">
              <p className="font-bold text-xs mb-2">
                {t("profile_edit_profile_name_heading")}
              </p>
              <input
                name="name"
                required
                onChange={inputHandler}
                value={inputData?.name ? inputData?.name : ""}
                type="text"
                placeholder={t("profile_edit_profile_name_placeholder")}
                className="bg-white w-full md:customEditProfileWidth p-4 rounded border border-border-gray outline-none"
              />
            </div>
            <div className="mt-6">
              <p className="font-bold text-xs mb-2">
                {t("profile_edit_profile_surname_heading")}
              </p>
              <input
                name="surname"
                required
                onChange={inputHandler}
                value={inputData?.surname ? inputData?.surname : ""}
                type="text"
                placeholder={t("profile_edit_profile_surname_placeholder")}
                className="bg-white w-full md:customEditProfileWidth p-4 rounded border border-border-gray outline-none"
              />
            </div>
            <div className="mt-6">
              <p className="font-bold text-xs mb-2">
                {t("profile_edit_profile_phoneNo_heading")}
              </p>
              <CustomPhoneInput
                inputStyle={{
                  width: "inherit",
                  height: "3.5rem",
                  fontSize: "13px",
                  paddingLeft: "48px",
                  borderRadius: "5px",
                }}
                name="phoneNo"
                onChange={inputHandler}
                value={inputData?.phoneNo}
              />
            </div>
            <div className="mt-6 w-full md:customEditProfileWidth">
              <p className="font-bold text-xs mb-2">
                {t("profile_edit_profile_province_heading")}
              </p>
              <Select
                onChange={inputHandler}
                value={inputData?.province}
                name="province"
                options={Provinces}
              />
            </div>
            <div className="mt-6 w-full md:customEditProfileWidth">
              <p className="font-bold text-xs mb-2">
                {t("profile_edit_profile_edit_nationality")}
              </p>
              <Select
                onChange={inputHandler}
                value={inputData?.nationality}
                name="nationality"
                options={Countries_List}
              />
            </div>
          </div>
          <div className="flex flex-col md:ml-20">
            {userType === "Teacher" ? (
              <>
                <div className="mt-6">
                  <p className="font-bold text-xs mb-2">
                    {t("profile_edit_profile_biography_heading")}
                  </p>
                  <textarea
                    name="biography"
                    required
                    className="bg-white w-full md:customEditProfileWidth p-4 rounded border border-border-gray outline-none"
                    placeholder={t(
                      "profile_edit_profile_biography_placeholder"
                    )}
                    value={inputData?.biography}
                    rows={4}
                    onChange={inputHandler}
                  />
                </div>
                <div className="mt-6">
                  <p className="font-bold text-xs mb-2">
                    {" "}
                    {t("profile_edit_profile_experiences_heading")}
                  </p>
                  <textarea
                    name="experiences"
                    required
                    className="bg-white w-full md:customEditProfileWidth p-4 rounded border border-border-gray outline-none"
                    placeholder={t(
                      "profile_edit_profile_experiences_placeholder"
                    )}
                    value={inputData?.experiences}
                    rows={4}
                    onChange={inputHandler}
                  />
                </div>
                <div className="mt-6">
                  <p className="font-bold text-xs mb-2">
                    {t("profile_edit_profile_about_classes_heading")}
                  </p>
                  <textarea
                    name="aboutClasses"
                    required
                    className="bg-white w-full md:customEditProfileWidth p-4 rounded border border-border-gray outline-none"
                    placeholder={t(
                      "profile_edit_profile_about_classes_placeholder"
                    )}
                    value={inputData?.aboutClasses}
                    rows={4}
                    onChange={inputHandler}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="mt-6 w-full md:customEditProfileWidth">
                  <p className="font-bold text-xs mb-2">
                    {t("profile_edit_profile_profession_heading")}
                  </p>
                  <input
                    name="profession"
                    required
                    onChange={inputHandler}
                    value={inputData?.profession ? inputData?.profession : ""}
                    type="text"
                    placeholder={t("user_profession_placeholder")}
                    className="bg-white w-full md:customEditProfileWidth p-4 rounded border border-border-gray outline-none"
                  />
                </div>
                <div className="flex flex-col mt-6 w-full md:customEditProfileWidth pb-2">
                  <p className="font-bold text-xs mb-2">
                    {t("profile_edit_profile_languages_heading")}
                  </p>
                  <div className="flex flex-col items-center justify-center">
                    <MultiCheckbox
                      items={languages}
                      setItems={setLanguages}
                      onChange={languageChangeHandler}
                    />

                    <div className="flex w-full md:customEditProfileWidth mt-8 justify-center">
                      <input
                        className="bg-white w-60 md:w-7/12 text-center  p-4 rounded-md outline-none"
                        placeholder={t(
                          "profile_edit_profile_languages_placeholder"
                        )}
                        value={extraLanguage}
                        onChange={(event) => {
                          setExtraLanguage(event.target.value);
                        }}
                        style={{ boxShadow: "0px 2px 15px rgb(0 0 0 / 15%)" }}
                      />
                      <Button
                        classNames="ml-2"
                        color="secondary"
                        isDisable={extraLanguage === ""}
                        onClick={(event) => {
                          event.preventDefault();
                          addExtraLanguageHandler();
                        }}
                      >
                        {t("profile_edit_profile_add_button")}
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          {userType === "Teacher" && (
            <div className="flex flex-col md:ml-20 justify-between">
              <div className="flex flex-col">
                <div className="flex flex-col mt-6 w-full md:customEditProfileWidth pb-2">
                  <p className="font-bold text-xs mb-2">
                    {t("profile_edit_profile_languages_heading")}
                  </p>
                  <div className="flex flex-col items-center justify-center">
                    <MultiCheckbox
                      items={languages}
                      setItems={setLanguages}
                      onChange={languageChangeHandler}
                    />

                    <div className="flex w-full md:customEditProfileWidth mt-8 justify-center">
                      <input
                        className="bg-white w-60 md:w-7/12 text-center  p-4 rounded-md outline-none"
                        placeholder={t(
                          "profile_edit_profile_languages_placeholder"
                        )}
                        value={extraLanguage}
                        onChange={(event) => {
                          setExtraLanguage(event.target.value);
                        }}
                        style={{ boxShadow: "0px 2px 15px rgb(0 0 0 / 15%)" }}
                      />
                      <Button
                        classNames="ml-2"
                        color="secondary"
                        isDisable={extraLanguage === ""}
                        onClick={(event) => {
                          event.preventDefault();
                          addExtraLanguageHandler();
                        }}
                      >
                        {t("profile_edit_profile_add_button")}
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="mt-6">
                  <p className="font-bold text-xs mb-2">
                    {t("profile_edit_profile_url_heading")}
                  </p>
                  <input
                    name="videoLink"
                    onChange={(event) => {
                      const validity = validate(event.target.value, [
                        VALIDATOR_IS_URL(),
                      ]);
                      setValidUrl(validity);
                      inputHandler(event);
                    }}
                    value={inputData?.videoLink ? inputData?.videoLink : ""}
                    type="text"
                    placeholder="Enter Video Link"
                    className="bg-white w-full md:customEditProfileWidth p-4 rounded border border-border-gray outline-none"
                  />
                  {!validUrl &&
                    inputData?.videoLink &&
                    inputData?.videoLink !== "" && (
                      <p className="text-secondary-main mt-4 text-sm text-center">
                        {t("profile_edit_profile_url_error_msg")}
                      </p>
                    )}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="flex justify-center mb-3">
          <Button
            classNames={`md:mt-20 mt-6
            } w-32 self-center`}
            type="submit"
            color="secondary"
            isDisable={
              isDisable ||
              !inputTouched ||
              (userType === "Teacher" &&
                inputData.videoLink !== "" &&
                !validUrl)
            }
          >
            {t("profile_edit_profile_button")}
          </Button>
        </div>
      </form>
      <EditPasswordModal
        open={editPasswordOpen}
        onClose={() => {
          setEditPasswordOpen(false);
        }}
      />
    </>
  );
}

export default EditProfile;
