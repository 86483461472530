import React, { useState, useRef, useEffect } from "react";
import { Fade, Hidden, Menu, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Icon, Button } from "@components";
import { useOutsideClick } from "@customeHooks";
import SideDrawer from "./components/SideDrawer";
import { logoutUser } from "@helpers/utils";
import { Avatar, FilterSearchForm } from "@components";
import {
  setFilterActive,
  setFilter,
  setFilterValues,
} from "@store/lesson/LessonActions";
import { setActiveTab } from "@store/header/HeaderActions";
import ChangeLanguageDropDown from "./components/ChangeLanguageDropDown";

function HeaderContainer({ children }) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loginModal, setLoginModal] = useState(false);
  const [registerModal, setRegisterModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchModelOpen, setSearchModelOpen] = useState(false);

  const isFilterVisible = useSelector(
    ({ EnglishCafe }) => EnglishCafe.lesson.isFilterVisible
  );

  const handleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileClose = () => {
    setAnchorEl(null);
  };

  const StyledMenu = withStyles({
    paper: {
      border: "1px solid #d3d4d5",
      width: "8rem",
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  ));

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const userType = localStorage.getItem("userType");

  useEffect(() => {
    if (
      localStorage.getItem("access_token") &&
      localStorage.getItem("userId")
    ) {
      setIsAuthenticated(true);
    }
  }, [dispatch]);

  const user = useSelector(({ EnglishCafe }) => EnglishCafe.auth.user);

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, () => {
    setLoginModal(false);
  });

  const wrapperRef2 = useRef(null);
  useOutsideClick(wrapperRef2, () => {
    setSearchModelOpen(false);
    setRegisterModal(false);
  });

  // const wrapperRef3 = useRef(null);
  // useOutsideClick(wrapperRef3, () => {
  //   setRegisterModal(false);
  // });

  return (
    <div
      className="flex w-full shadow-xl z-40 bg-white"
      style={{ height: "8vh" }}
    >
      <div
        className="flex flex-col fixed w-full z-40 bg-white shadow-sm"
        ref={wrapperRef2}
      >
        <div className="flex w-full px-4 py-4 md:px-16 md:py-6 bg-white justify-between">
          <div className="flex items-center w-full">
            <Icon
              type="logo"
              classNames="cursor-pointer"
              onClick={() => {
                if (user?.onBoarded || !user) {
                  if (userType === "Student" || !userType) {
                    dispatch(
                      setFilterValues({
                        category: "",
                        language: "",
                        type: "",
                        city: "",
                      })
                    );
                    dispatch(setFilter({}));
                    dispatch(setFilterActive(false));
                    history.push("/lessons");
                  } else {
                    history.push("/teacherProfile");
                    dispatch(setActiveTab("My Profile"));
                  }
                }
              }}
            />
            <Hidden smDown>
              {children}
              {(window.location.pathname !== "/lessons" || !isFilterVisible) &&
                (userType === "Student" || !userType) &&
                !searchModelOpen && (
                  <div className="flex justify-center w-full">
                    <div className="relative" style={{ width: "24vw" }}>
                      <div
                        className="flex cursor-pointer pl-2 rounded-full items-center w-full justify-between border border-text-gray"
                        onClick={(event) => {
                          event.stopPropagation();
                          setSearchModelOpen(true);
                        }}
                      >
                        <p className="text-md pl-4">
                          {t("general_search_button")}
                        </p>
                        <div
                          className="rounded-full bg-secondary-main  px-2 py-2"
                          style={{ marginLeft: "1vw" }}
                        >
                          <Icon type="searchIcon" classNames="w-5" />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
            </Hidden>
          </div>
          {!isAuthenticated && (
            <div className="flex items-center">
              <Hidden smDown>
                <a
                  href="https://www.englishcafe.es/como-funciona/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="font-semibold ml-2 md:ml-6 cursor-pointer whitespace-nowrap text-sm mt-2 md:text-lg">
                    {t("header_how_does_it_work")}
                  </p>
                </a>
              </Hidden>
              <p
                className="font-semibold ml-2 md:ml-6 cursor-pointer whitespace-nowrap text-sm mt-2 md:text-lg"
                onClick={() => {
                  history.push("/signup?type=Teacher");
                }}
              >
                {t("header_become_teacher")}
              </p>
              <Hidden smDown>
                <a
                  href="https://www.englishcafe.es/nuestro-blog"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="font-semibold ml-2 md:ml-6 cursor-pointer whitespace-nowrap text-sm mt-2 md:text-lg">
                    {t("header_blog")}
                  </p>
                </a>
              </Hidden>
              <div className="relative" ref={wrapperRef}>
                <Hidden only={["md", "lg", "xl"]}>
                  <Button
                    classNames="ml-2 font text-sm"
                    onClick={() => {
                      setLoginModal(!loginModal);
                    }}
                  >
                    {t("general_login")}
                  </Button>
                </Hidden>
                <Hidden smDown>
                  <Button
                    classNames="ml-4 md:ml-6 font text-sm h-10"
                    color="secondary"
                    onClick={() => {
                      setLoginModal(!loginModal);
                      setRegisterModal(false);
                    }}
                  >
                    {t("general_login")}
                  </Button>
                </Hidden>
                {loginModal && (
                  <div className="absolute flex flex-col bg-card-main p-4 md:-left-4 -left-6 md:mt-1 shadow-lg rounded-md z-10">
                    <p
                      className="font-semibold ml-2 cursor-pointer text-sm whitespace-nowrap"
                      onClick={() => {
                        history.push("/login?type=Teacher");
                        setLoginModal(false);
                      }}
                    >
                      {t("header_as_teacher")}
                    </p>
                    <p
                      className="font-semibold ml-2 cursor-pointer text-sm whitespace-nowrap"
                      onClick={() => {
                        history.push("/login?type=Student");
                        setLoginModal(false);
                      }}
                    >
                      {t("header_as_student")}
                    </p>
                  </div>
                )}
              </div>
              <Hidden smDown>
                <div className="relative">
                  <Button
                    classNames="ml-4 md:ml-6 font text-sm h-10 whitespace-nowrap"
                    onClick={() => {
                      setRegisterModal(!registerModal);
                      setLoginModal(false);
                      // history.push("/signup?type=Student");
                    }}
                  >
                    {t("general_signup")}
                  </Button>
                  {registerModal && (
                    <div className="absolute flex flex-col bg-card-main p-4  md:mt-1 shadow-lg rounded-md z-10">
                      <p
                        className="font-semibold ml-2 cursor-pointer text-sm whitespace-nowrap"
                        onClick={() => {
                          history.push("/signup?type=Teacher");
                          setRegisterModal(false);
                        }}
                      >
                        {t("header_as_teacher")}
                      </p>
                      <p
                        className="font-semibold ml-2 cursor-pointer text-sm whitespace-nowrap"
                        onClick={() => {
                          history.push("/signup?type=Student");
                          setRegisterModal(false);
                        }}
                      >
                        {t("header_as_student")}
                      </p>
                    </div>
                  )}
                </div>
                <ChangeLanguageDropDown isAuthenticated={isAuthenticated} />
              </Hidden>
            </div>
          )}
          {isAuthenticated && (
            <Hidden smDown>
              <div className="flex items-center">
                <a
                  href="https://www.englishcafe.es/nuestro-blog"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="font-semibold mr-4 md:ml-6 cursor-pointer whitespace-nowrap text-sm mt-2 md:text-lg">
                    {t("header_blog")}
                  </p>
                </a>
                <ChangeLanguageDropDown isAuthenticated={isAuthenticated} />
                <div className="ml-2 flex relative -mb-4 whitespace-nowrap items-center justify-center w-32">
                  <div
                    className={`flex   pl-8 pr-4 py-3  ${
                      !Boolean(anchorEl) ? "rounded-full" : "w-32"
                    } border border-boder-gray justify-center items-center cursor-pointer transition duration-200 ease-in-out`}
                    onClick={handleProfileClick}
                  >
                    <Avatar
                      uri={user?.image}
                      name={user?.name}
                      classNames="w-7 h-7 -ml-4"
                      textSize="text-md"
                    />
                    <div className="ml-4">
                      <Icon type="menuIcon" classNames="w-4" />
                    </div>
                  </div>
                </div>
                <StyledMenu
                  id="fade-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleProfileClose}
                  TransitionComponent={Fade}
                  container={anchorEl && anchorEl.parentNode}
                >
                  {user?.onBoarded && (
                    <MenuItem
                      onClick={() => {
                        if (userType === "Teacher") {
                          history.push("/teacherProfile");
                        } else {
                          history.push("/studentProfile");
                        }
                        dispatch(setActiveTab("My Profile"));
                        handleProfileClose();
                      }}
                    >
                      {t("header_profile")}
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={() => {
                      logoutUser(history, dispatch);
                      handleProfileClose();
                    }}
                  >
                    {t("general_logout")}
                  </MenuItem>
                </StyledMenu>
              </div>
            </Hidden>
          )}
          <SideDrawer
            userType={userType}
            user={user}
            isAuthenticated={isAuthenticated}
          />
        </div>
        <Hidden smDown>
          {(window.location.pathname !== "/lessons" || !isFilterVisible) &&
            (userType === "Student" || !userType) &&
            searchModelOpen && (
              <div className="bg-white py-2" style={{ padding: "0 15vw" }}>
                <FilterSearchForm />
              </div>
            )}
        </Hidden>
      </div>
    </div>
  );
}

export default HeaderContainer;
