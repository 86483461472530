import { AuthActionTypes } from "./../redux/actionTypes";
const INITIAL_STATE: AuthState = {
  loading: false,
  teacherProfile: {},
  isEmailSent: false,
  revenueData: [],
  revenueList: [],
  totalPages: 0,
  currentPage: 0,
  totalDataCount: 0,
};
interface Action {
  payload: any;
  type: string;
}
const AuthReducer = (
  state: AuthState = INITIAL_STATE,
  action: Action
): AuthState => {
  switch (action.type) {
    case AuthActionTypes.LOGIN_USER_START: {
      return { ...state, loading: true };
    }
    case AuthActionTypes.LOGIN_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case AuthActionTypes.LOGIN_USER_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case AuthActionTypes.CREATE_USER_START: {
      return { ...state, loading: true };
    }
    case AuthActionTypes.CREATE_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case AuthActionTypes.CREATE_USER_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case AuthActionTypes.GET_USER_START: {
      return { ...state, loading: true };
    }
    case AuthActionTypes.GET_USER_SUCCESS: {
      return {
        ...state,
        user: action.payload,
        loading: false,
      };
    }
    case AuthActionTypes.GET_USER_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case AuthActionTypes.LOGOUT: {
      return {
        ...state,
        user: null,
      };
    }

    case AuthActionTypes.ONBOARD_USER_START: {
      return { ...state, loading: true };
    }
    case AuthActionTypes.ONBOARD_USER_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          onBoarded: true,
        },
        loading: false,
      };
    }
    case AuthActionTypes.ONBOARD_USER_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case AuthActionTypes.UPDATE_USER_START: {
      return { ...state, loading: true };
    }
    case AuthActionTypes.UPDATE_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case AuthActionTypes.UPDATE_USER_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case AuthActionTypes.SOCIAL_AUTH_START: {
      return { ...state, loading: true };
    }
    case AuthActionTypes.SOCIAL_AUTH_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case AuthActionTypes.SOCIAL_AUTH_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case AuthActionTypes.GET_TEACHER_PROFILE_START: {
      return { ...state, loading: true };
    }
    case AuthActionTypes.GET_TEACHER_PROFILE_SUCCESS: {
      return {
        ...state,
        teacherProfile: action.payload,
        loading: false,
      };
    }
    case AuthActionTypes.GET_TEACHER_PROFILE_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case AuthActionTypes.FORGOT_PASSWORD_START: {
      return { ...state, loading: true };
    }
    case AuthActionTypes.FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        isEmailSent: true,
        loading: false,
      };
    }
    case AuthActionTypes.FORGOT_PASSWORD_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case AuthActionTypes.UPDATE_PASSWORD_START: {
      return { ...state, loading: true };
    }
    case AuthActionTypes.UPDATE_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case AuthActionTypes.UPDATE_PASSWORD_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case AuthActionTypes.GET_MONTHLY_REVENUE_START: {
      return { ...state, loading: true };
    }
    case AuthActionTypes.GET_MONTHLY_REVENUE_SUCCESS: {
      return {
        ...state,
        revenueData: action.payload.transactions,
        loading: false,
      };
    }
    case AuthActionTypes.GET_MONTHLY_REVENUE_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case AuthActionTypes.GET_TEACHER_REVENUE_LIST_START: {
      if (action.payload.currentPage === 1) {
        state.revenueList = [];
        state.totalPages = 0;
        state.currentPage = 0;
        state.totalDataCount = 0;
        return { ...state, loading: true };
      } else {
        return { ...state, loading: true };
      }
    }
    case AuthActionTypes.GET_TEACHER_REVENUE_LIST_SUCCESS: {
      if (action.payload.currentPage === 1) {
        state.revenueList = [];
        return {
          ...state,
          revenueList: action.payload.transactions,
          totalPages: action.payload.totalPages,
          currentPage: action.payload.currentPage,
          totalDataCount: action.payload.totalDataCount,
          loading: false,
        };
      } else {
        return {
          ...state,
          revenueList: [...state.revenueList, ...action.payload.transactions],
          totalPages: action.payload.totalPages,
          currentPage: action.payload.currentPage,
          totalDataCount: action.payload.totalDataCount,
          loading: false,
        };
      }
    }
    case AuthActionTypes.GET_TEACHER_REVENUE_LIST_FAIL: {
      return { ...state, loading: false };
    }

    default: {
      return state;
    }
  }
};
export default AuthReducer;
