import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import { getUser } from "@store/auth/AuthActions";
import NonUserRoutes from "NonUserRoutes";
import UserRoutes from "UserRoutes";
import OnBoardingRoute from "OnBoardingRoute";
import { getUserCards } from "@store/payment/PaymentActions";

function SwitchRouter(props) {
  const dispatch = useDispatch();
  const user = useSelector(({ EnglishCafe }) => EnglishCafe.auth.user);
  useEffect(() => {
    if (
      localStorage.getItem("access_token") &&
      localStorage.getItem("userId")
    ) {
      dispatch(
        getUser(
          localStorage.getItem("userId"),
          localStorage.getItem("userType")
        )
      );
      dispatch(getUserCards());
    }
  }, [dispatch]);
  const isUser = localStorage.getItem("userId");
  return (
    <>
      {isUser && user && !user.onBoarded && <OnBoardingRoute {...props} />}
      {!isUser && !user && <NonUserRoutes {...props} />}
      {isUser && user && user.onBoarded && <UserRoutes {...props} />}
    </>
  );
}

const MainRouter = withRouter(SwitchRouter);

export default MainRouter;
