import React, { useState } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import { useTranslation } from "react-i18next";

function CustomDateRangePicker({ dateRange, onChange }) {
  const { t } = useTranslation();
  const [focusedInput, setFocusedInput] = useState();
  return (
    <DateRangePicker
      startDatePlaceholderText={t("general_start_date")}
      endDatePlaceholderText={t("general_end_date")}
      startDateId="startDate"
      endDateId="endDate"
      displayFormat={() => "DD/MM/YYYY"}
      startDate={
        dateRange?.value?.startDate &&
        dateRange?.value?.startDate !== "" &&
        moment(dateRange.value.startDate)
      }
      endDate={
        dateRange?.value?.endDate &&
        dateRange?.value?.endDate !== "" &&
        moment(dateRange.value.endDate)
      }
      onDatesChange={({ startDate, endDate }) => {
        onChange(startDate, endDate);
      }}
      focusedInput={focusedInput}
      onFocusChange={(focusedInput) => {
        setFocusedInput(focusedInput);
      }}
    />
  );
}

export default CustomDateRangePicker;
