import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyBtaJ0zBuxSZTzdi14fdpugJ9SS14Kv8qI",
  authDomain: "english-cafe-109e3.firebaseapp.com",
  projectId: "english-cafe-109e3",
  storageBucket: "english-cafe-109e3.appspot.com",
  messagingSenderId: "563852420946",
  appId: "1:563852420946:web:7f4fe2a53cd47c8ec8fc8b",
  measurementId: "G-TJMZ8JJDED",
};

firebase.initializeApp(firebaseConfig);

export default firebase;
