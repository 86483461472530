import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getLastTimeDuration } from "@helpers/timeDateUtils";
import { setActiveTab } from "@store/header/HeaderActions";
import { setUserChatDetails } from "@store/chat/ChatActions";

function ChatCard({ chat, isProfilePage }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const recieverId = useSelector(
    ({ EnglishCafe }) => EnglishCafe.chat.chatRecieverId
  );
  const chatId = useSelector(({ EnglishCafe }) => EnglishCafe.chat.chatId);
  const userId = localStorage.getItem("userId");

  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => window.removeEventListener("resize", handleWindowSizeChange);
  }, []);
  const isMobile = width <= 600;
  return (
    <div
      className={`flex-col mt-10 border-b border-border-gray ${
        (chatId === chat.chatId || recieverId === chat.user._id) &&
        !isProfilePage &&
        "bg-secondary-main text-white"
      } hover:bg-secondary-main hover:text-white p-4 ease-in-out duration-500 cursor-pointer`}
      onClick={() => {
        dispatch(
          setUserChatDetails({
            chatId: chat.chatId,
            recieverId: chat.user._id,
            userImage: chat.user?.image,
            userName: chat?.user?.name + " " + chat?.user?.surname,
            chatOpened: true,
          })
        );
        if (isMobile) {
          history.push("/mobile-messenger");
        } else if (isProfilePage) {
          dispatch(setActiveTab("Messages"));
        }
      }}
    >
      <div className="flex items-center">
        {!chat.lastMessage.read && chat?.lastMessage?.senderId !== userId && (
          <span className="p-1 rounded-full bg-secondary-main"></span>
        )}
        <p className="font-bold ml-1">
          {chat?.user?.name + " " + chat?.user?.surname}
        </p>
      </div>
      <p className="text-sm l-1 truncate">{chat?.lastMessage?.message}</p>
      <div className="flex  justify-end">
        <p className="text-xs ml-1 self-end">
          {getLastTimeDuration(chat?.lastMessage?.createdAt)}
        </p>
      </div>
    </div>
  );
}

export default ChatCard;
