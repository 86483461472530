import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { OnBoarding } from "@modules";

const OnBoardingRoute = (props) => {
  return (
    <Switch>
      <Route exact path="/onBoarding" component={OnBoarding} />
      <Redirect to="/onBoarding" />
    </Switch>
  );
};
export default OnBoardingRoute;
