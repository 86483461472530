import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Icon, Button, HeaderContainer, Footer } from "@components";
import { pageAnalytics } from "@helpers/utils";

function HomeScreen() {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    pageAnalytics(window);
  }, []);

  return (
    <>
      <HeaderContainer />
      <div className="flex flex-col">
        <div
          className="flex justify-center items-center"
          style={{ height: "92vh" }}
        >
          <div className="flex relative h-5/6 justify-center w-11/12">
            <Icon
              type="homeScreenBackground"
              classNames="w-11/12 object-cover rounded-3xl"
            />
            <div className="flex flex-col absolute left-8 bottom-0 items-start justify-start pb-4 md:pl-24 md:pb-28">
              <p className="text-white text-md font-semibold w-max md:text-4xl">
                {t("home_screen_title")}
              </p>
              <p variant="caption" align="left" className="text-white">
                {t("home_screen_text1")}
              </p>
              <div className="flex mt-2">
                <Button classNames="font text-sm" color="secondary">
                  {t("home_screen_text2")}
                </Button>
                <div className="ml-4">
                  <Icon type="playButton" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default HomeScreen;
