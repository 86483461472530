import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Hidden } from "@material-ui/core";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

import {
  Input,
  Button,
  BackButton,
  AuthPageContainer,
  Footer,
  SocialAuth,
  WhatsappLink,
} from "@components";
import { useForm } from "@customeHooks";
import { VALIDATOR_EMAIL, VALIDATOR_MINLENGTH } from "@helpers/validators";
import { submitUserLogin } from "@store/auth/AuthActions";
import { ForgotPasswordModal } from "@components/modals";
import { pageAnalytics } from "@helpers/utils";

function Login(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const params = Object.fromEntries(
    new URLSearchParams(props?.location?.search)
  );
  const { type: userType } = params;

  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);

  const [formState, inputHandler] = useForm(
    {
      email: {
        value: "",
        isValid: false,
      },
      password: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    pageAnalytics(window);
  }, []);

  return (
    <>
      <AuthPageContainer userType={userType} isLogin={true}>
        <div className="flex w-full items-center">
          <BackButton classNames="md:-ml-8" />
          <div className="flex w-full justify-center">
            <p className="font-bold text-xl text-center">
              {userType === "Teacher"
                ? t("general_teacher_login")
                : t("general_student_login")}
            </p>
          </div>
        </div>
        <form
          className="flex flex-col mt-8"
          onSubmit={(event) => {
            event.preventDefault();
            dispatch(
              submitUserLogin(
                {
                  email: formState.inputs.email.value,
                  password: formState.inputs.password.value,
                },
                history,
                userType
              )
            );
          }}
          autoComplete="off"
        >
          <Input
            id="email"
            label={t("auth_email_label")}
            placeholder="user@gmail.com"
            variant="outlined"
            onInput={inputHandler}
            validators={[VALIDATOR_EMAIL()]}
            helperText={t("auth_email_helper_text")}
            classNames="w-full mt-4 md:mt-8 bg-white"
            size="small"
          />
          <Input
            id="password"
            isPassword={true}
            label={t("auth_password_label")}
            onInput={inputHandler}
            validators={[VALIDATOR_MINLENGTH(6)]}
            helperText={t("auth_password_helper_text")}
            classNames="w-full mt-4 md:mt-8 bg-white"
            size="small"
          />
          <div className="flex justify-center">
            <Button
              classNames="rounded-md mt-6 md:mt-8 w-full md:w-20"
              color="secondary"
              isDisable={!formState.isValid}
              type="submit"
            >
              {t("general_login")}
            </Button>
          </div>
        </form>
        <SocialAuth isLogin={true} userType={userType} />

        <p
          className="mt-10 text-sm underline cursor-pointer text-center"
          onClick={() => {
            setForgotPasswordOpen(true);
          }}
        >
          {t("auth_forgot_password")}
        </p>
        <p className="mt-2 text-sm text-center">
          {t("auth_not_registered") + " "}
          <span
            onClick={() => {
              history.push("/signup?type=" + userType);
            }}
            className="underline cursor-pointer"
          >
            {t("general_signup")}
          </span>
        </p>
      </AuthPageContainer>
      <Hidden only={["md", "lg", "xl"]}>
        <Footer />
      </Hidden>
      <ForgotPasswordModal
        open={forgotPasswordOpen}
        onClose={() => {
          setForgotPasswordOpen(false);
        }}
        userType={userType}
      />
      <WhatsappLink />
    </>
  );
}

export default Login;
