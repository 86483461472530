import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getSubscribedLessons,
  getTeachersByStudentId,
} from "@store/lesson/LessonActions";
import { Loader, LessonList, ProfileChatList } from "@components";
import TeacherList from "./TeacherList";

function StudentDashboard() {
  const dispatch = useDispatch();

  const lessonsData = useSelector(
    ({ EnglishCafe }) => EnglishCafe.lesson.lessons
  );
  const teachersData = useSelector(
    ({ EnglishCafe }) => EnglishCafe.lesson.teachers
  );
  const loading = useSelector(({ EnglishCafe }) => EnglishCafe.lesson.loading);

  useEffect(() => {
    dispatch(getSubscribedLessons(1, 2));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getTeachersByStudentId(1, 2));
  }, [dispatch]);

  return (
    <>
      <Loader loading={loading}></Loader>
      <div className="flex flex-col px-16 mr-1 pb-4 mb-1 overflow-y-scroll styledScrollbar">
        <div className="flex flex-col">
          <LessonList lessonsData={lessonsData} isStudentDashboard={true} />
        </div>
        <div className="flex mt-10">
          <div className="flex w-96">
            {lessonsData && lessonsData.length > 0 && (
              <TeacherList teachersData={teachersData} />
            )}
          </div>
          <div className="ml-6">
            <ProfileChatList />
          </div>
        </div>
      </div>
    </>
  );
}

export default StudentDashboard;
