import React, { useState, useEffect } from "react";
import { Dialog, InputAdornment, TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Button, Icon, Loader } from "@components";
import { forgotPassword, updatePassword } from "@store/auth/AuthActions";
import {
  validate,
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH,
  VALIDATOR_MAXLENGTH,
  VALIDATOR_REQUIRE,
} from "@helpers/validators";
import { useHistory } from "react-router-dom";

const Input = ({
  name,
  value,
  inputHandler,
  isValid,
  isTouched,
  touchHandler,
  helperText,
  isPassword,
  label,
  placeholder,
  classNames,
}) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  return (
    <>
      {isPassword ? (
        <TextField
          name={name}
          type={showPassword ? "text" : "password"}
          label={t("auth_password_label")}
          placeholder={"**********"}
          variant="outlined"
          fullWidth
          value={value}
          onChange={inputHandler}
          className={`${classNames} `}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {showPassword ? (
                  <div className="w-8 cursor-pointer">
                    <Icon
                      type="visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    />
                  </div>
                ) : (
                  <div className="w-8 cursor-pointer">
                    <Icon
                      type="visibilityOff"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    />
                  </div>
                )}
              </InputAdornment>
            ),
          }}
          error={!isValid && isTouched}
          helperText={!isValid && isTouched && helperText}
          onBlur={touchHandler}
        />
      ) : (
        <TextField
          name={name}
          label={label}
          placeholder={placeholder}
          variant="outlined"
          fullWidth
          value={value}
          onChange={inputHandler}
          className={`${classNames} `}
          error={!isValid && isTouched}
          helperText={!isValid && isTouched && helperText}
          onBlur={touchHandler}
        />
      )}
    </>
  );
};

function ForgotPasswordModal({ onClose, open, userType }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { loading, isEmailSent } = useSelector(
    ({ EnglishCafe }) => EnglishCafe.auth
  );

  const [form, setForm] = useState({
    email: {
      value: "",
      isValid: false,
      isTouched: false,
      validators: [VALIDATOR_EMAIL()],
    },
    otp: {
      value: "",
      isValid: false,
      isTouched: false,
      validators: [VALIDATOR_MINLENGTH(1), VALIDATOR_MAXLENGTH(4)],
    },
    password: {
      value: "",
      isValid: false,
      isTouched: false,
      validators: [VALIDATOR_MINLENGTH(6)],
    },
    confirmPassword: {
      value: "",
      isValid: false,
      isTouched: false,
      validators: [VALIDATOR_REQUIRE()],
    },
  });

  const [isDisable, setIsDisable] = useState(true);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const forgotPasswordSubmitHandler = () => {
    dispatch(forgotPassword(form.email.value, userType));
  };

  const updatePasswordSubmitHandler = () => {
    dispatch(
      updatePassword(
        form.email.value,
        form.password.value,
        form.otp.value,
        history,
        userType
      )
    );
    handleClose();
  };

  const inputHandler = (event) => {
    let temp = form;
    let validity = false;
    if (temp[`${event.target.name}`]?.validators) {
      validity = validate(
        event.target.value,
        temp[`${event.target.name}`].validators
      );
    }
    temp[`${event.target.name}`].value = event.target.value;
    temp[`${event.target.name}`].isValid = validity;
    temp[`${event.target.name}`].isTouched = true;
    setForm({ ...temp });
  };

  const touchHandler = (event) => {
    let temp = form;
    temp[`${event.target.name}`].isTouched = true;
    setForm({ ...temp });
  };

  useEffect(() => {
    if (!isEmailSent) {
      setIsDisable(!form.email.isValid);
    } else {
      let flag =
        form.email.isValid &&
        form.otp.isValid &&
        form.password.isValid &&
        form.confirmPassword.isValid;
      setIsDisable(!flag);
    }
  }, [isEmailSent, form]);

  useEffect(() => {
    if (form.password.value === form.confirmPassword.value) {
      setConfirmPasswordError(false);
    } else {
      setConfirmPasswordError(true);
    }
  }, [form.password, form.confirmPassword]);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Loader loading={loading}></Loader>
      <div className="flex flex-col items-center px-8 py-6 md:w-96">
        <Icon
          type="closeIconBlack"
          classNames="self-end cursor-pointer -mr-3"
          onClick={handleClose}
        />
        <div className="flex items-center mb-6">
          <Icon type="ratingIcon" classNames="w-8 h-8" />
          {!isEmailSent ? (
            <p className="ml-3 text-xl font-medium ">
              {t("auth_forgot_password_heading")}
            </p>
          ) : (
            <p className="ml-3 text-xl font-medium ">
              {t("auth_update_password_heading")}
            </p>
          )}
        </div>
        <div className="flex items-center mb-4">
          {!isEmailSent ? (
            <p className="ml-3 text-sm font-medium ">
              {t("auth_forgot_password_text")}
            </p>
          ) : (
            <p className="ml-3 text-sm font-medium ">
              {t("auth_update_password_text")}
            </p>
          )}
        </div>
        {!isEmailSent ? (
          <form
            onSubmit={(event) => {
              event.preventDefault();
              forgotPasswordSubmitHandler();
            }}
            className="flex flex-col items-center justify-center mt-12"
          >
            <Input
              name="email"
              label={t("auth_email_label")}
              placeholder={"user@gmail.com"}
              value={form.email.value}
              inputHandler={inputHandler}
              touchHandler={touchHandler}
              classNames="mt-4"
              isValid={form.email.isValid}
              isTouched={form.email.isTouched}
              helperText={t("auth_email_helper_text")}
            />
            <Button
              classNames="mt-10"
              color="secondary"
              type="submit"
              isDisable={isDisable}
            >
              {t("general_submit")}
            </Button>
          </form>
        ) : (
          <form
            onSubmit={(event) => {
              event.preventDefault();
              updatePasswordSubmitHandler();
            }}
            className="flex flex-col items-center justify-center mt-12"
          >
            <Input
              name="otp"
              label={t("auth_otp_label")}
              placeholder={"1234"}
              value={form.otp.value}
              inputHandler={inputHandler}
              touchHandler={touchHandler}
              classNames="mt-4"
              isValid={form.otp.isValid}
              isTouched={form.otp.isTouched}
              helperText={t("auth_otp_helper_text")}
            />
            <Input
              isPassword={true}
              name="password"
              value={form.password.value}
              inputHandler={inputHandler}
              touchHandler={touchHandler}
              classNames="mt-4"
              isValid={form.password.isValid}
              isTouched={form.password.isTouched}
              helperText={t("auth_password_helper_text")}
            />
            <Input
              isPassword={true}
              name="confirmPassword"
              value={form.confirmPassword.value}
              inputHandler={inputHandler}
              touchHandler={touchHandler}
              classNames="mt-4"
              isValid={!confirmPasswordError}
              isTouched={form.confirmPassword.isTouched}
              helperText={t("auth_confirm_password_helper_text")}
            />
            <Button
              classNames="mt-10"
              color="secondary"
              type="submit"
              isDisable={isDisable || confirmPasswordError}
            >
              {t("general_submit")}
            </Button>
          </form>
        )}
      </div>
    </Dialog>
  );
}

export default ForgotPasswordModal;
