import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import {
  TeacherProfile,
  StudentProfile,
  Lessons,
  LessonDetail,
  // HomeScreen,
  MobileMessenger,
  Checkout,
  TeacherPublicProfile,
} from "@modules";

const User = (props) => {
  const userType = localStorage.getItem("userType");
  return (
    <Switch>
      {/* <Route exact path="/" component={HomeScreen} /> */}
      <Route
        exact
        path="/teacherProfile"
        {...props}
        component={TeacherProfile}
      />
      <Route
        exact
        path="/studentProfile"
        {...props}
        component={StudentProfile}
      />
      <Route exact path="/lessons" component={Lessons} />
      <Route
        exact
        path="/mobile-messenger"
        {...props}
        component={MobileMessenger}
      />
      <Route exact path="/checkout" {...props} component={Checkout} />
      <Route
        exact
        path="/lessonDetail/:lessonInfo/:lessonId"
        component={LessonDetail}
      />
      <Route
        exact
        path="/teacherPublicProfile/:teacherId"
        component={TeacherPublicProfile}
      />
      <Redirect to={userType === "Teacher" ? "/teacherProfile" : "/lessons"} />
    </Switch>
  );
};
export default User;
