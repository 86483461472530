import React, { useEffect, useState, useRef, useCallback } from "react";
import { Dialog } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { Button, Loader, Icon, Avatar } from "@components";
import { getTeachersByStudentId } from "@store/lesson/LessonActions";
import { setActiveTab } from "@store/header/HeaderActions";
import { setUserChatDetails } from "@store/chat/ChatActions";

function TeachersListModal({ onClose, open }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const handleClose = () => {
    onClose();
  };

  const teachersData = useSelector(
    ({ EnglishCafe }) => EnglishCafe.lesson.teachers
  );
  const totalPages = useSelector(
    ({ EnglishCafe }) => EnglishCafe.lesson.totalPages
  );

  const loading = useSelector(({ EnglishCafe }) => EnglishCafe.lesson.loading);
  const [hasMore, setHasMore] = useState(true);
  const [currentPageNo, setCurrentPageNo] = useState(1);

  useEffect(() => {
    if (totalPages === currentPageNo) {
      setHasMore(false);
    }
  }, [currentPageNo, totalPages]);

  const observer = useRef();

  const lastTeacherElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setCurrentPageNo(currentPageNo + 1);
          dispatch(getTeachersByStudentId(currentPageNo + 1, 10));
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore, loading, currentPageNo, dispatch]
  );

  useEffect(() => {
    dispatch(getTeachersByStudentId(1, 10));
  }, [dispatch]);

  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => window.removeEventListener("resize", handleWindowSizeChange);
  }, []);
  const isMobile = width <= 600;

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Loader loading={loading}></Loader>
      <div
        className={`flex flex-col items-center px-3 md:px-8 py-6 overflow-auto md:styledScrollbar ${
          teachersData.length < 1 && "md:w-96"
        }`}
        style={{ height: "60vh" }}
      >
        <Icon
          type="closeIconBlack"
          classNames="self-end cursor-pointer -mr-3"
          onClick={handleClose}
        />
        <div className="flex items-center mb-6">
          <Icon type="profileIcon" classNames="w-8 h-8" />
          <p className="ml-3 text-xl font-medium">{t("general_teachers")}</p>
        </div>
        <div className="flex flex-col">
          {teachersData &&
            teachersData.length > 0 &&
            teachersData.map((user) => {
              return (
                <div
                  className="flex items-center mb-4 justify-between border-dotted pb-2 border-b-2"
                  ref={lastTeacherElementRef}
                >
                  <div className="flex items-center">
                    <Avatar
                      uri={user?.image}
                      name={user?.name}
                      classNames="w-8 h-8"
                      textSize="text-md"
                    />
                    <div className="flex flex-col ml-1 md:ml-4">
                      <div className="flex justify-between">
                        <p className="md:font-medium text-xs md:text-sm">
                          {user?.name + " " + user?.surname}
                        </p>
                      </div>
                      <p className="md:font-medium text-xs md:text-sm">
                        {user?.email}
                      </p>
                    </div>
                  </div>
                  <Button
                    color="secondary"
                    classNames="text-xs w-16 md:24 md:text-sm ml-1 md:ml-4"
                    onClick={() => {
                      dispatch(setActiveTab("Messages"));
                      dispatch(
                        setUserChatDetails({
                          recieverId: user?._id,
                          userImage: user?.image,
                          userName: user?.name + " " + user?.surname,
                          chatOpened: true,
                        })
                      );
                      if (isMobile) {
                        history.push("/mobile-messenger");
                      } else {
                        history.push("/studentProfile");
                      }
                      handleClose();
                    }}
                  >
                    {t("lesson_detail_message")}
                  </Button>
                </div>
              );
            })}
        </div>
      </div>
    </Dialog>
  );
}

export default TeachersListModal;
