import React, { useEffect, useState } from "react";
import { DataGrid } from "@material-ui/data-grid";
import { useDispatch, useSelector } from "react-redux";

import { getTeacherRevenueList } from "@store/auth/AuthActions";

function PaymentHistory() {
  const dispatch = useDispatch();
  const limit = 10;
  const [currentPage, setCurrentPage] = useState(0);

  const {
    revenueList: rowData,
    loading,
    totalDataCount,
  } = useSelector(({ EnglishCafe }) => EnglishCafe.auth);

  const columns = [
    { field: "id", headerName: "ID", width: 100, hide: true },
    {
      field: "studentName",
      headerName: "Student Name",
      flex: 0.25,
    },
    {
      field: "lessonTitle",
      headerName: "Lesson Title",
      flex: 0.25,
    },
    {
      field: "lessonType",
      headerName: "Lesson Type",
      flex: 0.25,
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 0.18,
      //   type: "number",
    },
    {
      field: "date",
      headerName: "Date",
      flex: 0.17,
    },
  ];

  useEffect(() => {
    dispatch(getTeacherRevenueList(1, limit));
  }, [dispatch]);

  const pageChangeHandler = (newPage) => {
    if (newPage > currentPage && rowData.length !== totalDataCount) {
      dispatch(getTeacherRevenueList(newPage + 1, limit));
    }
    setCurrentPage(newPage);
  };

  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => window.removeEventListener("resize", handleWindowSizeChange);
  }, []);
  const isMobile = width <= 600;

  return (
    <div className="overflow-x-auto overflow-y-hidden ">
      <div
        className="md:pl-8 pt-4 pb-4 pr-4"
        style={{
          width: !isMobile ? "calc(100vw - 15rem)" : "800px",
          height: !isMobile
            ? "calc(100vh - 8vh - 86px)"
            : "calc(100vh - 8vh - 56px)",
        }}
      >
        <DataGrid
          rows={rowData.slice(currentPage * limit).map((e) => {
            return e;
          })}
          columns={columns}
          disableSelectionOnClick
          pagination
          pageSize={limit}
          rowsPerPageOptions={[limit]}
          rowCount={totalDataCount}
          paginationMode="server"
          page={currentPage}
          onPageChange={(newPage) => pageChangeHandler(newPage)}
          loading={loading}
        />
      </div>
    </div>
  );
}

export default PaymentHistory;
