import { MessageActionTypes } from "../redux/actionTypes";
const INITIAL_STATE: MessageState = {
  messages: [],
  showUnreadMessageDot: false,
  loading: false,
  totalPages: 0,
};
interface Action {
  payload: any;
  type: string;
}
const MessageReducer = (
  state: MessageState = INITIAL_STATE,
  action: Action
): MessageState => {
  switch (action.type) {
    case MessageActionTypes.GET_MESSAGES_START: {
      if (action.payload.currentPage === 1) {
        state.messages = [];
        state.totalPages = 0;
        return { ...state, loading: true };
      } else {
        return { ...state, loading: true };
      }
    }
    case MessageActionTypes.GET_MESSAGES_SUCCESS: {
      if (action.payload.currentPage === 1) {
        return {
          ...state,
          messages: action.payload.messages,
          totalPages: action.payload.totalPages,
          loading: false,
        };
      } else {
        return {
          ...state,
          messages: [...state.messages, ...action.payload.messages],
          totalPages: action.payload.totalPages,
          loading: false,
        };
      }
    }
    case MessageActionTypes.GET_MESSAGES_FAIL: {
      return {
        ...state,
        messages: action.payload,
        loading: false,
      };
    }
    case MessageActionTypes.SEEN_ALL_MESSAGES_START: {
      return { ...state, loading: true };
    }
    case MessageActionTypes.SEEN_ALL_MESSAGES_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case MessageActionTypes.SEEN_ALL_MESSAGES_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case MessageActionTypes.CREATE_MESSAGE_START: {
      return { ...state, loading: true };
    }
    case MessageActionTypes.CREATE_MESSAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case MessageActionTypes.CREATE_MESSAGE_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
};
export default MessageReducer;
