import React from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

import { Icon } from "@components";
import { numberWithCommas } from "@helpers/utils";
import Avatar from "./Avatar";
import {
  translatedLanguageSpanish,
  translatedLessonCategoriesSpanish,
  translatedLessonTypesSpanish,
} from "@helpers/data";

function LessonCard({ classNames, lesson, lessonRef, isTeacherProfile }) {
  const { t } = useTranslation();
  const history = useHistory();

  let discountedPrice = "";
  if (lesson.type === "group") {
    discountedPrice = (
      lesson?.price -
      (lesson?.price * lesson.discount) / 100
    ).toFixed(2);
  }

  return (
    <div
      ref={lessonRef}
      className={`flex flex-col cursor-pointer ${classNames}`}
      onClick={() => {
        let lessonInfo = `clases`;
        if (Object.keys(translatedLanguageSpanish).includes(lesson?.language)) {
          lessonInfo = `${lessonInfo}-${
            translatedLanguageSpanish[lesson.language]
          }`;
        } else {
          lessonInfo = `${lessonInfo}-${lesson.language.toLowerCase()}`;
        }

        if (lesson?.level?.length > 0) {
          lessonInfo = `${lessonInfo}-${lesson.level.join("-")}`;
        }
        lessonInfo = `${lessonInfo}-${
          translatedLessonCategoriesSpanish[lesson.type]
        }-${translatedLessonTypesSpanish[lesson.classType]}`;
        if (lesson.city) {
          lessonInfo = `${lessonInfo}-${lesson.city.toLowerCase()}`;
        }
        // lessonInfo = `${lessonInfo}-${lesson.title
        //   .toLowerCase()
        //   .replaceAll(" ", "-")}/`;

        history.push("/lessonDetail/" + lessonInfo + "/" + lesson._id);
      }}
    >
      <div className="h-44 w-auto md:h-64">
        <img
          alt="lessonImage"
          className="rounded-xl w-full h-full object-cover"
          src={lesson?.images && lesson?.images.length > 0 && lesson?.images[0]}
        />
      </div>
      <div className="flex items-center justify-between">
        <div className="flex flex-col">
          {lesson?.reviews > 0 && (
            <div className="flex items-center mt-4">
              <div>
                <Icon type="star" classNames="rounded-xl" />
              </div>
              <p className="text-sm font-semibold pl-2">
                {lesson?.avgRating && parseFloat(lesson?.avgRating).toFixed(1)}{" "}
                ({numberWithCommas(lesson?.reviews) + " " + t("lessons_votes")})
              </p>
            </div>
          )}
          <p className="text-sm font-bold mt-2">{lesson?.title}</p>
          <p className="text-md md:mt-2">
            {lesson.type === "group" ? (
              lesson.discount ? (
                <>
                  <span className="line-through mr-3">{lesson?.price}</span>
                  <span>
                    {discountedPrice + "€/" + t("lesson_detail_month")}
                  </span>
                </>
              ) : (
                lesson?.price + "€/" + t("lesson_detail_month")
              )
            ) : lesson.type === "one-to-one" || lesson.type === "children" ? (
              lesson?.price + "€ / h"
            ) : (
              "€ " + lesson?.price
            )}
          </p>
        </div>
        {!isTeacherProfile && (
          <div
            className="flex flex-col justify-between items-center mt-2"
            onClick={(event) => {
              event.stopPropagation();
              const teacherId = lesson?.teacherId?._id;
              history.push("/teacherPublicProfile/" + teacherId);
            }}
          >
            <div className="w-10 h-10">
              <Avatar
                uri={lesson?.teacherId?.image}
                name={lesson?.teacherId?.name}
                classNames="w-10 h-10"
                textSize="text-md"
              />
            </div>
            <p className="text-xs mt-1">{lesson?.teacherId?.name}</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default LessonCard;
