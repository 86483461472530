import { combineReducers } from "redux";

import EnglishCafe from "../redux/reducer";

const createReducer = (asyncReducers) =>
  combineReducers({
    EnglishCafe,
    ...asyncReducers,
  });

export default createReducer;
