import { BrowserRouter as Router } from "react-router-dom";
import React, { Suspense } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import ReactGA from "react-ga";

import "./App.css";
import Theme from "./Theme";
import store from "@store";
import MainRouter from "./MainRouter";
import "./i18next";
// import { pageAnalytics } from "@helpers/utils";
import TagManager from "react-gtm-module";

function App() {
  if (!window.location.href.includes("localhost")) {
    ReactGA.initialize("UA-219527912-1");
  }

  // useEffect(() => {
  //   pageAnalytics(window);
  // }, []);

  const tagManagerArgs = {
    gtmId: "GTM-NZ3N793",
  };

  TagManager.initialize(tagManagerArgs);

  return (
    <Provider store={store}>
      <div className="App">
        <ToastContainer />
        <div className="background"></div>
        <Theme>
          <Suspense fallback={<div>Loading..</div>}>
            <Router>
              <MainRouter />
            </Router>
          </Suspense>
        </Theme>
      </div>
    </Provider>
  );
}

export default App;
