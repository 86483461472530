import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { getChats } from "@store/chat/ChatActions";
import { ChatCard } from "@components";
import Messenger from "../components/Messenger";
import Loader from "@components/Loader";
import { getStudentsByTeacherId } from "@store/lesson/LessonActions";
import { SubscribersListModal, TeachersListModal } from "@components/modals";

function DesktopMessages(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [chatContacts, setChatContacts] = useState([]);
  const [subscribersModalOpen, setSubscribersModalOpen] = useState(false);
  const [teachersModalOpen, setTeachersModalOpen] = useState(false);

  const { loading } = useSelector(({ EnglishCafe }) => EnglishCafe.chat);

  const chatsList = useSelector(({ EnglishCafe }) => EnglishCafe.chat.chats);

  const chatOpened = useSelector(
    ({ EnglishCafe }) => EnglishCafe.chat.chatBoxOpened
  );

  useEffect(() => {
    dispatch(getChats());
  }, [dispatch]);

  const userType = localStorage.getItem("userType");

  useEffect(() => {
    const allChatContacts = [];
    chatsList &&
      chatsList.length > 0 &&
      chatsList.forEach((chat) => {
        chat.participents.forEach((user) => {
          if (
            user._id.toString() !== localStorage.getItem("userId").toString()
          ) {
            allChatContacts.push({
              user,
              chatId: chat._id,
              lastMessage: chat.lastMessage,
            });
          }
        });
      });
    setChatContacts(allChatContacts);
  }, [chatsList]);

  return (
    <>
      <Loader loading={loading} />
      <div className="flex mt-8 px-16 ">
        <div
          className="flex flex-col border-r-2 border-l-2 border-border-gray px-6 w-80 overflow-y-scroll styledScrollbar"
          // style={{ height: "fit-content" }}
          style={{ height: "calc(92vh - 8.5rem)" }}
        >
          <div className="flex items-center justify-between">
            <div className="flex items-baseline">
              <p>{t("profile_message_messages")}</p>
              <p className="ml-4 text-sm bg-profileCard-gray px-2 rounded-md">
                {chatContacts?.length}
              </p>
            </div>
            <p
              className="text-xs px-1 py-0.5 ml-2 bg-pink-100 rounded-md cursor-pointer "
              onClick={() => {
                if (userType === "Teacher") {
                  dispatch(getStudentsByTeacherId());
                  setSubscribersModalOpen(true);
                } else {
                  setTeachersModalOpen(true);
                }
              }}
            >
              {t("profile_message_start_conversation")}
            </p>
          </div>
          {chatContacts?.length > 0 &&
            chatContacts.map((chat) => {
              return <ChatCard chat={chat} key={chat.chatId} />;
            })}
        </div>
        <div
          className={`flex w-full ${
            !chatOpened && "justify-center items-center"
          } `}
          style={{ height: "calc(92vh - 8.5rem)" }}
        >
          {!chatOpened ? (
            <p className="text-xl"> {t("profile_message_open_messages")}</p>
          ) : (
            <Messenger />
          )}
        </div>
      </div>
      <SubscribersListModal
        open={subscribersModalOpen}
        onClose={() => {
          setSubscribersModalOpen(false);
        }}
      />
      <TeachersListModal
        open={teachersModalOpen}
        onClose={() => {
          setTeachersModalOpen(false);
        }}
      />
    </>
  );
}

export default DesktopMessages;
