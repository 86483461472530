import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Drawer, Hidden } from "@material-ui/core";

import { Icon, LessonCard, FilterSearchForm } from "@components";
import { getLessons, setIsFilterVisible } from "@store/lesson/LessonActions";
import filterGroupIcon from "assets/icons/filterGroupIcon.jpg";
import filterOneToOneIcon from "assets/icons/filterOneToOneIcon.jpg";
import filterChildrenIcon from "assets/icons/filterChildrenIcon.jpg";
import filterExperienceIcon from "assets/icons/filterExperienceIcon.jpg";
import lessonCoverImage from "assets/icons/lessonCoverImage.jpg";
import SideFilter from "./SideFilter";

function Filter({ filterActive, filter, setFilter, setAddressLoading }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // const lessonsData = useSelector(
  //   ({ EnglishCafe }) => EnglishCafe.lesson.lessons
  // );

  // const totalPages = useSelector(
  //   ({ EnglishCafe }) => EnglishCafe.lesson.totalPages
  // );
  const {
    loading,
    totalPages,
    lessons: lessonsData,
    currentPage: currentPageNo,
  } = useSelector(({ EnglishCafe }) => EnglishCafe.lesson);
  // const [hasMore, setHasMore] = useState(true);
  // const [currentPageNo, setCurrentPageNo] = useState(1);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showFilter, setShowFilter] = useState(true);

  // const filter = useSelector(
  //   ({ EnglishCafe }) => EnglishCafe.lesson.Lessonfilter
  // );

  // useEffect(() => {
  //   if (totalPages === currentPageNo) {
  //     setHasMore(false);
  //   }
  // }, [currentPageNo, totalPages]);

  const observer = useRef();

  const handleFilterScroll = () => {
    var element = document.querySelector("#searchBox");
    if (element) {
      var position = element.getBoundingClientRect();
      if (position.top >= 0 && position.bottom <= window.innerHeight) {
        dispatch(setIsFilterVisible(true));
      } else {
        dispatch(setIsFilterVisible(false));
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleFilterScroll);
    return () => window.removeEventListener("scroll", handleFilterScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(setIsFilterVisible(true));
  }, [dispatch]);

  const lastLessonElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && currentPageNo < totalPages) {
          // setCurrentPageNo(currentPageNo + 1);
          dispatch(getLessons(currentPageNo + 1, 10, filter));
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, currentPageNo, dispatch, filter, totalPages]
  );

  return (
    <>
      {!filterActive && (
        <Hidden only={["md", "lg", "xl"]}>
          <p className="font-bold text-xl mt-12 ml-10">{t("lessons_filter")}</p>
        </Hidden>
      )}
      <div
        className={`mt-2 mx-4 bg-card-main md:flex  md:relative md:py-40 md:flex-col md:mt-0 md:mx-0 md:z-0 ${
          filterActive && "md:py-64"
        }`}
        style={{
          backgroundImage: `url(${
            filterActive && filter?.category && filter?.category !== "All"
              ? filter.category === "group"
                ? filterGroupIcon
                : filter.category === "one-to-one"
                ? filterOneToOneIcon
                : filter.category === "children"
                ? filterChildrenIcon
                : filterExperienceIcon
              : lessonCoverImage
          })`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
      >
        {!filterActive && (
          <>
            <Hidden smDown>
              <div className="ml-8">
                <p
                  className={`font-bold text-3xl mt-12 ${
                    filterActive &&
                    filter?.category &&
                    filter?.category !== "All" &&
                    "text-white"
                  }`}
                  style={{ marginLeft: "15vw" }}
                >
                  {t("lessons_filter")}
                </p>
              </div>
            </Hidden>
            <div
              id="searchBox"
              className="flex justify-center w-full md:mt-8 md:z-20"
              style={{ padding: "0 15vw" }}
              r
            >
              <div className="flex justify-between">
                <FilterSearchForm pageFilter={true} />
              </div>
            </div>
          </>
        )}
      </div>
      {filterActive && (
        <>
          <div className={`mt-8 flex justify-between w-full px-3 md:px-40`}>
            {filterActive && filter?.category && filter?.category !== "All" && (
              <>
                {filter.category === "group" ? (
                  <>
                    <div className="flex flex-col items-center">
                      <Icon classNames="md:w-10 w-6" type="groupPeopleIcon" />
                      <p className="text-sm mt-4">{t("lessons_group_text1")}</p>
                    </div>
                    <div className="flex flex-col items-center ml-2">
                      <Icon classNames="md:w-10 w-6" type="bubblesIcon" />
                      <p className="text-sm mt-4">{t("lessons_group_text2")}</p>
                    </div>
                    <div className="flex flex-col items-center ml-2">
                      <Icon classNames="md:w-10 w-6" type="shopIcon" />
                      <p className="text-sm mt-4">{t("lessons_group_text3")}</p>
                    </div>
                    <div className="flex flex-col items-center ml-2">
                      <Icon classNames="md:w-10 w-6" type="bigCalendarIcon" />
                      <p className="text-sm mt-4">{t("lessons_group_text4")}</p>
                    </div>
                  </>
                ) : filter.category === "one-to-one" ? (
                  <>
                    <div className="flex flex-col items-center">
                      <Icon classNames="md:w-10 w-6" type="goalsIcon" />
                      <p className="text-sm mt-4">
                        {t("lessons_oneToOne_text1")}
                      </p>
                    </div>
                    <div className="flex flex-col items-center ml-2">
                      <Icon classNames="md:w-10 w-6" type="twoPeopleIcon" />
                      <p className="text-sm mt-4">
                        {t("lessons_oneToOne_text2")}
                      </p>
                    </div>
                    <div className="flex flex-col items-center ml-2">
                      <Icon classNames="md:w-10 w-6" type="bigCalendarIcon" />
                      <p className="text-sm mt-4">
                        {t("lessons_oneToOne_text3")}
                      </p>
                    </div>
                    <div className="flex flex-col items-center ml-2">
                      <Icon classNames="md:w-10 w-6" type="learningIcon" />
                      <p className="text-sm mt-4">
                        {t("lessons_oneToOne_text4")}
                      </p>
                    </div>
                  </>
                ) : filter.category === "children" ? (
                  <>
                    <div className="flex flex-col items-center">
                      <Icon classNames="md:w-10 w-6" type="twoPeopleIcon" />
                      <p className="text-sm mt-4">
                        {t("lessons_children_text1")}
                      </p>
                    </div>
                    <div className="flex flex-col items-center ml-2">
                      <Icon classNames="md:w-10 w-6" type="kidIcon" />
                      <p className="text-sm mt-4">
                        {t("lessons_children_text2")}
                      </p>
                    </div>
                    <div className="flex flex-col items-center ml-2">
                      <Icon classNames="md:w-10 w-6" type="followUpIcon" />
                      <p className="text-sm mt-4">
                        {t("lessons_children_text3")}
                      </p>
                    </div>
                    <div className="flex flex-col items-center ml-2">
                      <Icon classNames="md:w-10 w-6" type="bigCalendarIcon" />
                      <p className="text-sm mt-4">
                        {t("lessons_children_text4")}
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex flex-col items-center">
                      <Icon classNames="md:w-10 w-6" type="funIcon" />
                      <p className="text-sm mt-4">
                        {t("lessons_experience_text1")}
                      </p>
                    </div>
                    <div className="flex flex-col items-center ml-2">
                      <Icon classNames="md:w-10 w-6" type="exclusivityIcon" />
                      <p className="text-sm mt-4">
                        {t("lessons_experience_text2")}
                      </p>
                    </div>
                    <div className="flex flex-col items-center ml-2">
                      <Icon classNames="md:w-10 w-6" type="professionalIcon" />
                      <p className="text-sm mt-4">
                        {t("lessons_experience_text3")}
                      </p>
                    </div>
                    <div className="flex flex-col items-center ml-2">
                      <Icon classNames="md:w-10 w-6" type="groupPeopleIcon" />
                      <p className="text-sm mt-4">
                        {t("lessons_experience_text4")}
                      </p>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          <p className="font-bold text-center mt-6 md:mt-10 md:text-2xl capitalize">
            {filter?.category !== "" ? filter?.category : t("lessons_filtered")}{" "}
            {t("lessons_lessons")}
          </p>

          <div
            className="md:hidden flex items-center self-center p-2 border border-black rounded-md cursor-pointer mt-2"
            onClick={() => setOpenDrawer(true)}
          >
            <Icon classNames="w-6" type="filter" />
            <p>{t("lessons_filter")}</p>
          </div>
          <div
            className="hidden md:flex items-center self-start p-2 border border-black rounded-md cursor-pointer mt-2 ml-4 "
            onClick={() => setShowFilter(!showFilter)}
          >
            <Icon classNames="w-8" type="filter" />
            <p>{t("lessons_filter")}</p>
          </div>

          <div className="flex mt-4 md:mt-8">
            {/* ${!lessonsData?.length > 0 && "hidden"} */}
            <Drawer
              onClose={() => setOpenDrawer(false)}
              anchor="right"
              open={openDrawer}
            >
              <div>
                <SideFilter
                  setAddressLoading={setAddressLoading}
                  filter={filter}
                  setFilter={setFilter}
                />
              </div>
            </Drawer>
            <div
              className={`hidden md:flex overflow-hidden flex-col px-4  transform transition-all ease-in-out duration-700  ${
                showFilter
                  ? "w-72 min-w-72 opacity-1"
                  : "w-0 min-w-0 -translate-x-14 opacity-0"
              }`}
            >
              <SideFilter
                setAddressLoading={setAddressLoading}
                filter={filter}
                setFilter={setFilter}
              />
            </div>

            {lessonsData?.length > 0 ? (
              <div
                className={`grid md:grid-cols-3 grid-cols-1 gap-2 px-4 md:px-10`}
                style={{ margin: "0 auto" }}
              >
                {lessonsData.map((lesson, index) => {
                  return (
                    <LessonCard
                      key={lesson._id}
                      classNames="mt-4 md:mr-6 md:mt-10 md:mr-3"
                      lesson={lesson}
                      lessonRef={
                        lessonsData.length === index + 1
                          ? lastLessonElementRef
                          : null
                      }
                    />
                  );
                })}
              </div>
            ) : (
              !loading && (
                <div className="flex justify-center w-full  px-4 md:px-10 my-32">
                  <p className="font-medium text-xl text-secondary-main">
                    {t("lessons_no_lessons_found_2")}
                  </p>
                </div>
              )
            )}
          </div>
        </>
      )}
    </>
  );
}

export default Filter;
