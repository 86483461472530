import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { Button, Loader } from "@components";
import {
  deleteLessonDraft,
  getLessonDraftByTeacherId,
  setLessonId,
  setLessonType,
} from "@store/lesson/LessonActions";
import { setActiveTab } from "@store/header/HeaderActions";
import { ConfirmModal } from "@components/modals";

function ViewDraft() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const lessonsData = useSelector(
    ({ EnglishCafe }) => EnglishCafe.lesson.lessons
  );

  const totalPages = useSelector(
    ({ EnglishCafe }) => EnglishCafe.lesson.totalPages
  );
  const loading = useSelector(({ EnglishCafe }) => EnglishCafe.lesson.loading);

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [localLessonId, setLocalLessonId] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [currentPageNo, setCurrentPageNo] = useState(1);

  useEffect(() => {
    if (currentPageNo >= totalPages) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
  }, [currentPageNo, totalPages]);

  const observer = useRef();

  const lastLessonElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setCurrentPageNo(currentPageNo + 1);
          dispatch(
            getLessonDraftByTeacherId(
              localStorage.getItem("userId"),
              currentPageNo + 1,
              10
            )
          );
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore, loading, currentPageNo, dispatch]
  );

  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    dispatch(getLessonDraftByTeacherId(1, 10));
  }, [dispatch]);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => window.removeEventListener("resize", handleWindowSizeChange);
  }, []);
  const isMobile = width <= 600;

  return (
    <>
      <div className="flex  w-full  md:px-16 pt-4 md:pb-4">
        <Loader loading={loading}></Loader>
        <div
          className={`flex flex-col border border-border-gray bg-white py-4 rounded-md w-full`}
        >
          <div className="flex justify-between items-center mb-4 px-4 md:px-10 ">
            <p className="font-bold text-lg">{t("profile_lesson_my_drafts")}</p>
          </div>
          <div
            className={`flex flex-col md:p-6 mr-1 overflow-y-auto styledScrollbar px-4`}
            style={{ height: !isMobile && "calc(92vh - 13.5rem)" }}
          >
            {lessonsData && lessonsData.length > 0 ? (
              lessonsData.map((lesson, index) => {
                return (
                  <div
                    className="bg-profileCard-lightgray px-1 md:px-4 py-8 mb-4 rounded-md w-full"
                    ref={lastLessonElementRef}
                  >
                    <div className="flex justify-between">
                      <div className="flex justify-between w-full">
                        <div className="flex">
                          {lesson?.images[0] ? (
                            <img
                              alt="lessonImage"
                              className={`w-32 h-32 object-cover rounded-md`}
                              src={
                                lesson?.images &&
                                lesson?.images.length > 0 &&
                                lesson?.images[0]
                              }
                            />
                          ) : (
                            <div
                              className={`flex items-center justify-center w-32 h-32 rounded-md bg-secondary-main`}
                            >
                              <p className="text-white">{t("general_draft")}</p>
                            </div>
                          )}
                          <p className={`ml-4 font-bold  text-md`}>
                            {lesson?.title}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flex mt-2 md:items-center justify-between">
                      <div className="flex items-baseline">
                        <p
                          className="text-xs ml-1 mt-1 font-bold bg-profileCard-gray p-2 rounded-md capitalize whitespace-nowrap"
                          style={{ width: "fit-content" }}
                        >
                          {lesson?.type}
                        </p>
                      </div>
                      <div className={`flex flex-wrap md:ml-2`}>
                        <Button
                          size={"medium"}
                          variant="outlined"
                          classNames="mr-1 mt-1 whitespace-nowrap"
                          onClick={() => {
                            dispatch(setLessonId(lesson?._id));
                            dispatch(setActiveTab("Modify Drafts"));
                          }}
                        >
                          {t("profile_lesson_modify_button")}
                        </Button>
                        <Button
                          size={"medium"}
                          classNames="mr-1 mt-1 whitespace-nowrap"
                          color="secondary"
                          onClick={() => {
                            setLocalLessonId(lesson?._id);
                            setConfirmModalOpen(true);
                          }}
                        >
                          {t("general_delete")}
                        </Button>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="flex items-center justify-center p-4 md:h-full">
                <p className="text-sm md:text-xl">
                  {t("profile_lesson_no_lessons_to_show")}
                  <span
                    className="cursor-pointer underline"
                    onClick={() => {
                      dispatch(setActiveTab("Create Ads"));
                      dispatch(setLessonId(undefined));
                      dispatch(setLessonType("group"));
                      history.push("/teacherProfile");
                    }}
                  >
                    {t("profile_lesson_want_create_one")}
                  </span>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <ConfirmModal
        headerText={t("general_confirm_delete_text")}
        open={confirmModalOpen}
        onClose={() => {
          setConfirmModalOpen(false);
        }}
        onConfirm={() => {
          setConfirmModalOpen(false);
          dispatch(deleteLessonDraft(localLessonId));
        }}
      />
    </>
  );
}

export default ViewDraft;
