import React, { useEffect } from "react";
import { Hidden } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import DesktopTeacherProfile from "./Desktop/DesktopTeacherProfile";
import MobileTeacherProfile from "./Mobile/MobileTeacherProfile";
import { SuccessModal } from "@components/modals";
import { setOpenSuccessModal } from "@store/lesson/LessonActions";
import { pageAnalytics } from "@helpers/utils";

function TeacherProfile(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { openSuccessModal } = useSelector(
    ({ EnglishCafe }) => EnglishCafe.lesson
  );

  useEffect(() => {
    pageAnalytics(window);
  }, []);

  return (
    <>
      <Hidden smDown>
        <DesktopTeacherProfile {...props} />
      </Hidden>
      <Hidden only={["md", "lg", "xl"]}>
        <MobileTeacherProfile {...props} />
      </Hidden>
      <SuccessModal
        mainText={t("add_lesson_sucess_main")}
        headerText={t("add_lesson_sucess_header")}
        open={openSuccessModal}
        onClose={() => {
          dispatch(setOpenSuccessModal(false));
        }}
      />
    </>
  );
}

export default TeacherProfile;
