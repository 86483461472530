import React from "react";
import { Checkbox } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";

function TermAndConditons(props) {
  const { t } = useTranslation();
  const { checked, setChecked } = props;

  const currentLanguageCode = cookies.get("i18next") || "en";

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <div className="flex items-center">
      <Checkbox checked={checked} onChange={handleChange} />
      <p className="text-xs font-extralight">
        {t("auth_terms_text1") + " "}
        <a
          href={
            currentLanguageCode === "en"
              ? "https://www.englishcafe.es/en/terms-and-conditions/"
              : "https://www.englishcafe.es/terminos-condiciones/"
          }
        >
          <span className="underline">{t("auth_terms_text2") + " "}</span>
        </a>
        {t("auth_terms_text3") + " "}
        <a
          href={
            currentLanguageCode === "en"
              ? "https://www.englishcafe.es/en/politica-privacidad-2/"
              : "https://www.englishcafe.es/politica-privacidad/"
          }
        >
          <span className="underline">{t("auth_terms_text4") + " "}</span>
        </a>
      </p>
    </div>
  );
}

export default TermAndConditons;
