import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";

import { Button } from "@components";

import Icon from "./Icon";

function Footer() {
  const { t } = useTranslation();
  const currentLanguageCode = cookies.get("i18next") || "en";
  const elementRef = useRef();

  useEffect(() => {
    const script = document.createElement("script");

    script.src =
      currentLanguageCode === "en"
        ? "https://marketing.englishcafe.es/form/generate.js?id=4"
        : "https://marketing.englishcafe.es/form/generate.js?id=2";
    script.async = true;
    const divElement = elementRef.current;
    if (divElement.firstChild) {
      divElement.innerHTML = "";
    }
    divElement.appendChild(script);
  }, [currentLanguageCode]);
  return (
    <div className="flex flex-col md:flex-row items-center md:items-stretch  bg-card-main justify-center py-10 md:py-16">
      <div className="flex flex-col items-center md:w-4/12">
        <p className="font-bold text-center text-xl">
          {t("footer_pages_interest_heading")}
        </p>
        <div className="flex flex-col mt-8">
          <div className="flex justify-between w-full">
            <a href="https://www.englishcafe.es/en/learn-a-language">
              <p className="ml-2 cursor-pointer text-sm">{t("footer_home")}</p>
            </a>
            <a href="https://www.englishcafe.es/en/us">
              <p className="ml-2 cursor-pointer text-sm">
                {t("footer_about_us")}
              </p>
            </a>
            <a href="https://www.englishcafe.es/en/in-company">
              <p className="ml-2 cursor-pointer text-sm">
                {t("footer_in_company")}
              </p>
            </a>
          </div>
          <div className="flex justify-between w-full mt-2">
            <a href="https://www.englishcafe.es/en/become-a-teacher">
              <p className="ml-2 cursor-pointer text-sm">
                {t("footer_are_you_a_teacher")}
              </p>
            </a>
            <a href="https://www.englishcafe.es/en/our-blog">
              <p className="ml-2 cursor-pointer text-sm">
                {t("footer_our_blog")}
              </p>
            </a>
          </div>
          <div className="flex justify-between w-full mt-2">
            <a href="https://www.englishcafe.es/en/politica-privacidad-2">
              <p className="ml-2 cursor-pointer text-sm">
                {t("footer_privacy_policy")}
              </p>
            </a>
            <a href="https://www.englishcafe.es/terminos-condiciones">
              <p className="ml-2 cursor-pointer text-sm">
                {t("footer_terms_and_conditions")}
              </p>
            </a>
          </div>
          <div className="flex justify-center w-full mt-2">
            <a href="https://www.englishcafe.es/contacta-englishcafe-academia-online/">
              <Button color="secondary" classNames="mt-4" variant="outlined">
                {t("footer_contact_us")}
              </Button>
            </a>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center mt-12 md:mt-0 md:w-4/12">
        <p className="font-bold text-center text-xl">
          {t("footer_subscribe_newsletter")}
        </p>
        <div id="contactForm" ref={elementRef}></div>
        {/* <input
          name="name"
          required
          type="text"
          placeholder={t("general_name")}
          className="bg-white w-full md:customEditProfileWidth p-4 rounded border border-border-gray outline-none mt-2"
        />
        <input
          name="email"
          required
          type="text"
          placeholder={t("general_email")}
          className="bg-white w-full md:customEditProfileWidth p-4 rounded border border-border-gray outline-none mt-2"
        />
        <div className="flex justify-center">
          <Button classNames="mt-4">{t("general_send")}</Button>
        </div> */}

        {/* <p className="font-bold text-center text-xl">
          {t("footer_contact_heading")}
        </p> */}

        {/* <p className="mt-8 px-12 text-sm text-center">
          Calle Blasco de Garay, 43, 28015, Madrid, Spain
        </p>
        <a href="mailto:info@englishcafe.es">
          <p className="text-secondary-main mt-3 text-sm">
            info@englishcafe.es
          </p>
        </a>
        <p className="mt-3 text-sm">
          {t("footer_telephone_particulars")}: 653630324
        </p>
        <p className="mt-1 text-sm">
          {t("footer_telephone_companies")}: 661472694
        </p>
        <div className="flex justify-center items-center mt-5">
          <a href="https://www.facebook.com/englishcafe.spain">
            <Icon classNames="w-6" type="facebookIcon2" />
          </a>
          <a href="https://www.instagram.com/englishcafespain">
            <Icon classNames="w-6 ml-2" type="instagramIcon" />
          </a>
          <a href="https://www.linkedin.com/company/englishcafe">
            <Icon classNames="w-6 ml-2" type="linkedinIcon" />
          </a>
        </div> */}
      </div>
      <div className="flex flex-col items-center mt-12 md:mt-0 md:w-4/12">
        <p className="font-bold text-center text-xl">
          {t("footer_in_partnership_heading")}
        </p>
        <Icon type="partnersIcon" classNames="mt-8 w-72" />
      </div>
    </div>
  );
}

export default Footer;
