export const studentTabs = [
  { title: "profile_tab_home", value: "My Profile", icon: "userIcon" },
  { title: "profile_tab_lessons", value: "View Lessons", icon: "viewAdIcon" },
  {
    title: "profile_tab_teachers",
    value: "View Teachers",
    icon: "teacherIcon",
  },
  { title: "profile_tab_messages", value: "Messages", icon: "messageIcon" },
  {
    title: "profile_tab_payment_methods",
    value: "Payment Methods",
    icon: "paymentMethodIcon",
  },
];
