import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider, Hidden } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import { getLessonbyId, getLessons } from "@store/lesson/LessonActions";
import {
  LessonsRow,
  Footer,
  HeaderContainer,
  Review,
  Loader,
  Icon,
  WhatsappLink,
} from "@components";
import LessonTitleCard from "./components/LessonTitleCard";
import LessonPriceCard from "./components/LessonPriceCard";
import LessonAboutTeacher from "./components/LessonAboutTeacher";
import MoreInformation from "./components/MoreInformation";
import LessonDetailImages from "./components/LessonDetailImages";
import { ReviewListModal } from "@components/modals";
import { numberWithCommas, pageAnalytics } from "@helpers/utils";
import LessonDescription from "./components/LessonDescription";

function LessonDetail(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { lessonId } = useParams();

  const [openReviewsModal, setOpenReviewsModal] = useState(false);

  const { lesson, loading } = useSelector(
    ({ EnglishCafe }) => EnglishCafe.lesson
  );

  const user = useSelector(({ EnglishCafe }) => EnglishCafe.auth.user);

  useEffect(() => {
    dispatch(getLessonbyId(lessonId));
  }, [dispatch, lessonId]);

  useEffect(() => {
    dispatch(getLessons(1, 5));
  }, [dispatch]);

  useEffect(() => {
    pageAnalytics(window);
  }, []);

  const lessonsData = useSelector(
    ({ EnglishCafe }) => EnglishCafe.lesson.lessons
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [lessonId]);

  return (
    <>
      <Loader loading={loading} />
      <HeaderContainer />
      <div className="flex-col md:relative">
        <div className="absolute z-10 left-20 top-36 cursor-pointer">
          <Icon
            classNames="w-5"
            type="backArrow"
            onClick={() => {
              history.goBack();
            }}
          />
        </div>
        <div className="w-full h-64 md:h-96 object-cover md:px-40 px-2 mt-10">
          {lesson?.images?.length > 0 && (
            <LessonDetailImages images={lesson?.images} />
          )}
        </div>

        <Hidden smDown>
          <div className="flex justify-between w-full mt-10 md:px-40">
            <div className="flex w-full items-center">
              <LessonTitleCard lesson={lesson} />
            </div>
            <div>
              <LessonPriceCard lesson={lesson} user={user} />
            </div>
          </div>
        </Hidden>
        <Hidden only={["md", "lg", "xl"]}>
          <div className="mt-4 px-4 flex w-full ">
            <LessonTitleCard lesson={lesson} />
          </div>
        </Hidden>
        <Hidden only={["md", "lg", "xl"]}>
          <div className="mx-4 mt-10 ">
            <LessonPriceCard lesson={lesson} user={user} />
          </div>
        </Hidden>
        <Divider className="mx-6 mt-12 md:mx-40 md:w-5/12" />
        <div className="px-4 md:px-40 my-10">
          <MoreInformation lesson={lesson} />
        </div>
        <Divider className="mx-6 mt-12 md:mx-40 md:w-7/12" />
        <div className="px-4 md:px-40 mt-8 mb-10 md:w-7/12 md:mb-20">
          <LessonDescription lesson={lesson} />
        </div>
        <Divider className="mx-6 mt-12 md:mx-40 md:w-7/12" />
        <div className="px-4 md:px-40 md:w-7/12 mt-4 ">
          <LessonAboutTeacher lesson={lesson} user={user} />
        </div>

        {lesson?.reviews && lesson?.reviews?.length > 0 && (
          <div className="px-4 md:px-40 md:w-7/12 mt-4 ">
            <div className="flex flex-col mt-6 border border-border-gray rounded-md px-6 py-6">
              <div className="flex justify-between">
                <p className="font-bold">
                  {t("teacher_profile_reviews_label")}
                </p>
                <p
                  className="font-bold cursor-pointer"
                  onClick={() => {
                    setOpenReviewsModal(true);
                  }}
                >
                  {t("general_view_all")}
                </p>
              </div>
              <p className="test-sm">
                {lesson?.reviews &&
                  numberWithCommas(lesson?.reviews?.length) +
                    " " +
                    t("teacher_profile_reviews")}
              </p>
              <div className="flex flex-col mt-6">
                {lesson?.reviews &&
                  lesson?.reviews?.length > 0 &&
                  lesson?.reviews?.slice(0, 2).map((review) => {
                    return (
                      <>
                        <Review review={review} />
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
        )}
        <Divider className="mx-6 mt-12 md:mt-24 md:mx-40" />
        <div className="px-4 md:px-40 mt-4"></div>
        <div className="flex flex-col mt-10 mb-4">
          <div className="flex justify-center mb-6">
            <p className=" font-bold text-xl">
              {t("lesson_detail_courses_like_this")}
            </p>
          </div>
          <LessonsRow lessonsData={lessonsData} />
        </div>
        <ReviewListModal
          open={openReviewsModal}
          onClose={() => {
            setOpenReviewsModal(false);
          }}
          reviews={lesson?.reviews}
        />
        <WhatsappLink />
        <Footer />
      </div>
    </>
  );
}

export default LessonDetail;
