import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { setActiveTab } from "@store/header/HeaderActions";
import { Avatar, Button } from "@components";
import { setUserChatDetails } from "@store/chat/ChatActions";

function LessonAboutTeacher({ lesson, user }) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => window.removeEventListener("resize", handleWindowSizeChange);
  }, []);
  const isMobile = width <= 600;

  return (
    <div className="flex flex-col">
      <p className="font-bold text-xl">{t("lesson_detail_about_teacher")}</p>
      <p
        className="mt-2 capitalize cursor-pointer"
        onClick={() => {
          const teacherId = lesson?.teacherId?._id;
          history.push("/teacherPublicProfile/" + teacherId);
        }}
      >
        {lesson?.teacherId?.name + " " + lesson?.teacherId?.surname}
      </p>
      <div className="mt-2 flex">
        <Avatar
          uri={lesson?.teacherId?.image}
          name={lesson?.teacherId?.name}
          classNames="w-20 h-20 cursor-pointer"
          onClick={() => {
            const teacherId = lesson?.teacherId?._id;
            history.push("/teacherPublicProfile/" + teacherId);
          }}
        />
        <Button
          color="secondary"
          classNames="self-center ml-4"
          onClick={() => {
            if (!user) {
              history.push("/login?type=Student");
            } else {
              dispatch(setActiveTab("Messages"));
              dispatch(
                setUserChatDetails({
                  recieverId: lesson?.teacherId?._id,
                  userImage: lesson?.teacherId?.image,
                  userName:
                    lesson?.teacherId?.name + " " + lesson?.teacherId?.surname,
                  chatOpened: true,
                })
              );
              if (isMobile) {
                history.push("/mobile-messenger");
              } else {
                history.push("/studentProfile");
              }
            }
          }}
        >
          {t("lesson_detail_message")}
        </Button>
      </div>
      <p className="mt-3 text-sm text-justify">
        {lesson?.teacherId?.aboutClasses}
      </p>
    </div>
  );
}

export default LessonAboutTeacher;
