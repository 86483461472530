import { PaymentActionTypes } from "./../redux/actionTypes";
const INITIAL_STATE: PaymentState = {
  cards: [],
  paymentSuccessful: false,
  loading: false,
  oneToOneLessonPrice: 0,
  childrenLessonPrice: 0,
};
interface Action {
  payload: any;
  type: string;
}
const AuthReducer = (
  state: PaymentState = INITIAL_STATE,
  action: Action
): PaymentState => {
  switch (action.type) {
    case PaymentActionTypes.ADD_CARD_START: {
      return { ...state, loading: true };
    }
    case PaymentActionTypes.ADD_CARD_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case PaymentActionTypes.ADD_CARD_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case PaymentActionTypes.REMOVE_CARD_START: {
      return { ...state, loading: true };
    }
    case PaymentActionTypes.REMOVE_CARD_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case PaymentActionTypes.REMOVE_CARD_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case PaymentActionTypes.MAKE_DEFAULT_CARD_START: {
      return { ...state, loading: true };
    }
    case PaymentActionTypes.MAKE_DEFAULT_CARD_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case PaymentActionTypes.MAKE_DEFAULT_CARD_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case PaymentActionTypes.GET_USER_CARDS_START: {
      return { ...state, loading: true };
    }
    case PaymentActionTypes.GET_USER_CARDS_SUCCESS: {
      return {
        ...state,
        cards: action.payload.cards,
        loading: false,
      };
    }
    case PaymentActionTypes.GET_USER_CARDS_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case PaymentActionTypes.MAKE_PAYMENT_START: {
      state.paymentSuccessful = false;
      return { ...state, loading: true };
    }
    case PaymentActionTypes.MAKE_PAYMENT_SUCCESS: {
      return {
        ...state,
        paymentSuccessful: true,
        loading: false,
      };
    }
    case PaymentActionTypes.MAKE_PAYMENT_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case PaymentActionTypes.BUY_LESSON_START: {
      state.paymentSuccessful = false;
      return { ...state, loading: true };
    }
    case PaymentActionTypes.BUY_LESSON_SUCCESS: {
      return {
        ...state,
        paymentSuccessful: true,
        loading: false,
      };
    }
    case PaymentActionTypes.BUY_LESSON_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case PaymentActionTypes.GET_LESSON_PRICE_TEACHER_START: {
      return { ...state, loading: true };
    }
    case PaymentActionTypes.GET_LESSON_PRICE_TEACHER_SUCCESS: {
      return {
        ...state,
        oneToOneLessonPrice: action.payload.oneToOneLessonPrice.unit_amount,
        childrenLessonPrice: action.payload.childrenLessonPrice.unit_amount,
        loading: false,
      };
    }
    case PaymentActionTypes.GET_LESSON_PRICE_TEACHER_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
};
export default AuthReducer;
