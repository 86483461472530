import React, { useState } from "react";
import { Divider } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Button } from "@components";
import { SelectDateModal, ShareModal } from "@components/modals";
import {
  translatedLanguageSpanish,
  translatedLessonCategoriesSpanish,
  translatedLessonTypesSpanish,
} from "@helpers/data";

function LessonPriceCard({ lesson, user }) {
  const [selectDateOpen, setSelectDateOpen] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);

  const { t } = useTranslation();
  const history = useHistory();

  let discountedPrice = "";
  if (lesson.type === "group") {
    discountedPrice = (
      lesson?.price -
      (lesson?.price * lesson.discount) / 100
    ).toFixed(2);
  }

  const shareClickHandler = () => {
    setOpenShareModal(true);
  };
  let lessonInfo = `clases`;
  if (Object.keys(translatedLanguageSpanish).includes(lesson?.language)) {
    lessonInfo = `${lessonInfo}-${translatedLanguageSpanish[lesson?.language]}`;
  } else {
    lessonInfo = `${lessonInfo}-${lesson?.language?.toLowerCase()}`;
  }
  if (lesson?.level?.length > 0) {
    lessonInfo = `${lessonInfo}-${lesson?.level?.join("-")}`;
  }
  lessonInfo = `${lessonInfo}-${
    translatedLessonCategoriesSpanish[lesson?.type]
  }-${translatedLessonTypesSpanish[lesson?.classType]}`;
  if (lesson?.city) {
    lessonInfo = `${lessonInfo}-${lesson?.city?.toLowerCase()}`;
  }
  const lessonUrl =
    window.location.protocol +
    "//" +
    window.location.host +
    "/lessonDetail/" +
    lessonInfo +
    "/" +
    lesson?._id;

  return (
    <>
      <div
        className="bg-white flex flex-col p-6 md:p-10 md:rounded-3xl md:w-96"
        style={{ boxShadow: "0px 2px 15px rgb(0 0 0 / 15%)" }}
      >
        <p
          className={`font-bold  ${
            lesson.type === "group" ? "text-4xl" : "text-5xl"
          } text-center mr-2 whitespace-nowrap`}
        >
          {lesson.type === "group" ? (
            lesson.discount ? (
              <>
                <span className="line-through mr-3">{lesson?.price}</span>
                <span>{discountedPrice + "€/" + t("lesson_detail_month")}</span>
              </>
            ) : (
              lesson?.price + "€/" + t("lesson_detail_month")
            )
          ) : lesson.type === "one-to-one" || lesson.type === "children" ? (
            lesson?.price + "€ / h"
          ) : (
            "€ " + lesson?.price
          )}
        </p>
        {/* {lesson.discount ? (
          <p className="text-sm font-light my-4">
            {t("lesson_detail_discount_text1")} {" " + lesson?.discount + "% "}
            {t("lesson_detail_discount_text2")}
          </p>
        ) : (
          ""
        )} */}
        <Divider className="my-2" />

        {lesson?.type === "group" && (
          <div className="flex flex-col">
            {lesson.discount ? (
              <p className="text-sm font-light my-1">
                ✅ {t("lesson_detail_discount_text1")}
                <span className="font-bold">
                  {t("lesson_detail_discount_text2")}
                  {" " + lesson?.discount + "% "}
                </span>
                {t("lesson_detail_discount_text3")}
              </p>
            ) : (
              ""
            )}
            <p className="text-sm font-light my-1">
              ✅ {t("lesson_detail_one_class_per_week")}
            </p>
            <p className="text-sm font-light my-1">
              ✅ {t("lesson_detail_free_enrolment")}
            </p>
            <p className="text-sm font-light my-1">
              ✅ {t("lesson_detail_no_commitment_to_stay")}
            </p>
          </div>
        )}
        {/* <p className="text-sm font-bold mt-4">
          {t("lesson_detail_price_card_text")}
        </p> */}
        <div className="flex flex-col items-center justify-center mt-6">
          {(lesson?.type === "group" || lesson?.type === "experience") &&
            (lesson?.isFull ? (
              <p>{t("lesson_detail_lesson_full")}</p>
            ) : (
              <Button
                color="secondary"
                onClick={() => {
                  if (!user) {
                    history.push("/login?type=Student");
                  } else {
                    if (lesson?.type === "group") {
                      history.push({
                        pathname: "/checkout",
                        state: {
                          lesson,
                        },
                      });
                    } else {
                      setSelectDateOpen(true);
                    }
                  }
                }}
              >
                {t("lesson_detail_buy_now")}
              </Button>
            ))}
          <p
            className="underline cursor-pointer mt-1 font-bold"
            onClick={shareClickHandler}
          >
            {t("lesson_detail_share")}
          </p>
        </div>
      </div>
      <SelectDateModal
        open={selectDateOpen}
        onClose={() => {
          setSelectDateOpen(false);
        }}
        onSubmit={(enrolmentDate) => {
          history.push({
            pathname: "/checkout",
            state: {
              lesson,
              enrolmentDate,
            },
          });
        }}
        dates={lesson?.selectDates}
        numberOfPlaces={lesson?.numberOfPlaces}
      />
      <ShareModal
        open={openShareModal}
        onClose={() => setOpenShareModal(false)}
        lessonUrl={lessonUrl}
      />
    </>
  );
}

export default LessonPriceCard;
