import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

function LessonDetailImages({ images }) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const LessonImage = ({ classNames, src }) => {
    return (
      <img
        alt="lessonImage"
        className={`object-cover rounded-lg ${classNames}`}
        src={src}
      />
    );
  };

  return (
    <div className="flex w-full h-64 md:h-96 object-cover relative">
      {images.length === 1 && (
        <LessonImage classNames="w-full h-full" src={images[0]} />
      )}
      {images.length === 2 && (
        <>
          <div className="pr-1 w-1/2">
            <LessonImage classNames="w-full h-full" src={images[0]} />
          </div>
          <div className="pl-1 w-1/2">
            <LessonImage classNames="w-full h-full" src={images[1]} />
          </div>
        </>
      )}
      {images.length === 3 && (
        <>
          <div className="pr-1 w-1/2">
            <LessonImage classNames="w-full h-full" src={images[0]} />
          </div>
          <div className="pl-1 pr-1 w-1/4 ">
            <LessonImage classNames="w-full h-full" src={images[1]} />
          </div>
          <div className="pl-1 w-1/4 ">
            <LessonImage classNames="w-full h-full" src={images[2]} />
          </div>
        </>
      )}
      {images.length >= 4 && (
        <>
          <div className="pr-1 w-2/6">
            <LessonImage classNames="w-full h-full" src={images[0]} />
          </div>
          <div className="pl-1 pr-1 w-2/6 ">
            <div className="pb-1 w-full h-1/2">
              <LessonImage classNames="w-full h-full" src={images[1]} />
            </div>
            <div className="pt-1 w-full h-1/2">
              <LessonImage classNames="w-full h-full" src={images[2]} />
            </div>
          </div>
          <div className="pl-1 w-2/6 ">
            <LessonImage classNames="w-full h-full" src={images[3]} />
          </div>
        </>
      )}
      {images.length > 4 && (
        <p
          className="bg-white text-xs md:text-md rounded-md px-2 md:px-4 py-2 cursor-pointer md:rounded-3xl absolute bottom-2 right-2"
          style={{ boxShadow: "0px 2px 15px rgb(0 0 0 / 15%)" }}
          onClick={() => {
            setIsOpen(true);
          }}
        >
          {t("lesson_detail_view_all_images")}
        </p>
      )}
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </div>
  );
}

export default LessonDetailImages;
