import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Geocode from "react-geocode";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";

import { Icon } from "@components";
import { getLessons } from "@store/lesson/LessonActions";
import FilterCheckboxes from "./FilterCheckboxes";
import { useDebounce } from "@customeHooks";
import { filters } from "@helpers/data";

Geocode.setApiKey(process.env.REACT_APP_MAP_API_KEY);
Geocode.setRegion("es");

function SideFilter(props) {
  const { filter: filterObj, setFilter, setAddressLoading } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { filterValues } = useSelector(({ EnglishCafe }) => EnglishCafe.lesson);

  const [expanded, setExpanded] = useState({
    level: false,
    type: false,
    price: false,
    dayOfTheWeek: false,
    city: false,
  });

  const [city, setCity] = useState();

  const debouncedCity = useDebounce(city, 500);
  const firstUpdate = useRef(true);

  useEffect(() => {
    (async () => {
      if (debouncedCity !== undefined) {
        if (firstUpdate.current) {
          firstUpdate.current = false;
          return;
        }
        if (debouncedCity) {
          let newFilterObj = JSON.parse(JSON.stringify(filterObj));
          // newFilterObj["city"] = debouncedCity;
          // setFilter(newFilterObj);
          setAddressLoading(true);
          try {
            const response = await Geocode.fromAddress(debouncedCity);
            const { lat, lng } = response.results[0].geometry.location;
            newFilterObj["city"] = { lat, lng };
          } catch (err) {
            newFilterObj["city"] = { lat: "", lng: "" };
            console.log("No result found!");
          }
          setAddressLoading(false);
          setFilter(newFilterObj);
          fetchLessons(newFilterObj);
        } else {
          let newFilterObj = JSON.parse(JSON.stringify(filterObj));
          delete newFilterObj.city;
          setFilter(newFilterObj);
          fetchLessons(newFilterObj);
        }
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedCity]);

  const handleAccordionChange = (item) => {
    setExpanded((prev) => ({ ...prev, [item]: !prev[item] }));
  };

  const handleFilterChange = (item, value) => {
    let newFilterValues = filterObj[item] ? [...filterObj[item]] : [];

    let newFilterObj = JSON.parse(JSON.stringify(filterObj));

    // if(!newFilterObj[item]){
    //   newFilterObj[item]=[]
    // }

    if (newFilterValues?.includes(value)) {
      newFilterValues = newFilterValues.filter((val) => val !== value);
    } else {
      newFilterValues.push(value);
    }

    if (item === "types" && !newFilterValues.includes("face-to-face")) {
      newFilterObj["city"] = "";
      setCity("");
    }

    newFilterObj[item] = newFilterValues;
    setFilter(newFilterObj);
    fetchLessons(newFilterObj);
  };

  const fetchLessons = async (filterObj) => {
    const newFilterObj = JSON.parse(JSON.stringify(filterObj));
    if (!newFilterObj?.levels?.length > 0) {
      delete newFilterObj["levels"];
    }
    if (!newFilterObj?.types?.length > 0) {
      delete newFilterObj["types"];
    }
    if (!newFilterObj?.priceRanges?.length > 0) {
      delete newFilterObj["priceRanges"];
    }
    if (!newFilterObj?.daysOfTheWeek?.length > 0) {
      delete newFilterObj["daysOfTheWeek"];
    }
    if (!newFilterObj?.city) {
      delete newFilterObj["city"];
    }

    dispatch(getLessons(1, 10, newFilterObj));
  };

  const handleFilterCityChange = (event) => {
    setCity(event.target.value);
  };

  useEffect(() => {
    if (filterValues?.city) {
      setCity(filterValues?.city);
    }
  }, [filterValues?.city]);

  return (
    <div className="flex flex-col md:space-y-3 pb-3">
      {/* <Loader loading /> */}
      <div className="px-1 py-3 flex justify-center shadow-md">
        <p className="font-bold">{t("lessons_filter")}</p>
      </div>
      {/* {filters?.levels?.length > 0 && ( */}
      <Accordion
        expanded={expanded.level}
        onChange={() => handleAccordionChange("level")}
      >
        <AccordionSummary
          expandIcon={<Icon type="downArrow" />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <p>{t("general_browse_level")}</p>
        </AccordionSummary>
        <AccordionDetails>
          <FilterCheckboxes
            items={filters?.levels}
            selected={filterObj.levels}
            onChange={(value) => handleFilterChange("levels", value)}
          />
        </AccordionDetails>
      </Accordion>
      {/* )} */}
      {/* {filters?.types?.length > 0 && ( */}
      <Accordion
        expanded={expanded.type}
        onChange={() => handleAccordionChange("type")}
      >
        <AccordionSummary
          expandIcon={<Icon type="downArrow" />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <p>{t("general_browse_type")}</p>
        </AccordionSummary>
        <AccordionDetails>
          <FilterCheckboxes
            items={filters?.types}
            selected={filterObj.types}
            onChange={(value) => handleFilterChange("types", value)}
          />
        </AccordionDetails>
      </Accordion>
      {/* )} */}
      {/* {filters?.priceRanges?.length > 0 && ( */}
      <Accordion
        expanded={expanded.price}
        onChange={() => handleAccordionChange("price")}
      >
        <AccordionSummary
          expandIcon={<Icon type="downArrow" />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <p>{t("general_browse_price")}</p>
        </AccordionSummary>
        <AccordionDetails>
          <FilterCheckboxes
            items={filters?.priceRanges}
            selected={filterObj.priceRanges}
            onChange={(value) => handleFilterChange("priceRanges", value)}
          />
        </AccordionDetails>
      </Accordion>
      {/* )} */}
      {/* {filters?.daysOfTheWeek?.length > 0 && ( */}
      {(filterValues?.category === "group" ||
        filterValues?.category === "experience") && (
        <Accordion
          expanded={expanded.dayOfTheWeek}
          onChange={() => handleAccordionChange("dayOfTheWeek")}
        >
          <AccordionSummary
            expandIcon={<Icon type="downArrow" />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <p>{t("general_browse_week_day")}</p>
          </AccordionSummary>
          <AccordionDetails>
            <FilterCheckboxes
              languageHandler
              items={filters?.daysOfTheWeek}
              selected={filterObj.daysOfTheWeek}
              onChange={(value) => handleFilterChange("daysOfTheWeek", value)}
            />
          </AccordionDetails>
        </Accordion>
      )}
      {/* )} */}
      <Accordion
        expanded={expanded.city}
        onChange={() => handleAccordionChange("city")}
      >
        <AccordionSummary
          expandIcon={<Icon type="downArrow" />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <p>{t("general_browse_city")}</p>
        </AccordionSummary>
        <AccordionDetails>
          <input
            name="city"
            className={`max-w-full outline-none text-sm border border-black px-2 py-1.5 rounded-md`}
            placeholder={t("general_browse_city_placeholder")}
            size="small"
            // disabled={!filterObj?.types?.includes("face-to-face")}
            value={city}
            onChange={handleFilterCityChange}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default SideFilter;
