import React from "react";
import { Dialog } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
} from "react-share";

import Icon from "@components/Icon";

function ShareModal(props) {
  const { open, onClose, lessonUrl } = props;

  const { t } = useTranslation();

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <div className="flex flex-col justify-center items-center px-2 md:px-8 py-6">
        <Icon
          type="closeIconBlack"
          classNames="self-end cursor-pointer md:-mr-3"
          onClick={handleClose}
        />
        <p className="text-xl mb-4 font-bold">
          {t("lesson_detail_share_this_lesson")}
        </p>
        <div className="rounded-md border border-black flex space-x-2">
          <p className="p-2 break-all">{lessonUrl}</p>
          <p
            className="bg-primary-main text-white px-3 flex items-center cursor-pointer"
            onClick={() => navigator.clipboard.writeText(lessonUrl)}
          >
            {t("lesson_detail_copy")}
          </p>
        </div>
        <div className="flex justify-center space-x-3 mt-2">
          <FacebookShareButton className="outline-none" url={lessonUrl}>
            <div className="p-1.5 border border-black rounded-full cursor-pointer">
              <Icon classNames="w-6" type="facebook" />
            </div>
          </FacebookShareButton>
          <TwitterShareButton className="outline-none" url={lessonUrl}>
            <div className="p-1.5 border border-black rounded-full cursor-pointer">
              <Icon classNames="w-6" type="twitter" />
            </div>
          </TwitterShareButton>
          <EmailShareButton className="outline-none" url={lessonUrl}>
            <div className="p-1.5 border border-black rounded-full cursor-pointer">
              <Icon classNames="w-6" type="email" />
            </div>
          </EmailShareButton>
        </div>
      </div>
    </Dialog>
  );
}

export default ShareModal;
