import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  HeaderContainer,
  DesktopMessages,
  ProfileNavBar,
  EditProfile,
  PaymentMethods,
} from "@components";
import StudentDashboard from "../components/StudentDashboard";
import ViewLesson from "../components/ViewLesson";
import { studentTabs } from "../helper/Data";
import { setActiveTab } from "@store/header/HeaderActions";
import ViewTeacher from "../components/ViewTeacher";

function DesktopStudentProfile(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeTab = useSelector(
    ({ EnglishCafe }) => EnglishCafe.header.activeTab
  );
  const user = useSelector(({ EnglishCafe }) => EnglishCafe.auth.user);
  return (
    <>
      <HeaderContainer />
      <div className="flex w-full overflow-hidden" style={{ height: "92vh" }}>
        <ProfileNavBar user={user} tabs={studentTabs} />
        <div className="flex flex-col bg-profileCard-lightgray w-full h-full">
          <p className="mt-10 ml-10 pb-6">
            <span
              className=" font-bold cursor-pointer"
              onClick={() => {
                dispatch(setActiveTab("My Profile"));
              }}
            >
              {t("general_my_account") + " "}
            </span>
            {t(studentTabs.find((tab) => tab.value === activeTab)?.title)}
          </p>
          {activeTab === "Edit Profile" && <EditProfile user={user} />}
          {activeTab === "My Profile" && <StudentDashboard />}
          {activeTab === "View Lessons" && <ViewLesson />}
          {activeTab === "View Teachers" && <ViewTeacher />}
          {activeTab === "Messages" && <DesktopMessages {...props} />}
          {activeTab === "Payment Methods" && <PaymentMethods />}
        </div>
      </div>
    </>
  );
}

export default DesktopStudentProfile;
