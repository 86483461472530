import React from "react";
import { useTranslation } from "react-i18next";
import StarRatings from "react-star-ratings";
import { useHistory } from "react-router-dom";

import { formatDate, getGroupNextClassDate } from "@helpers/utils";
import { Icon } from "@components";

function LessonTitleCard({ lesson }) {
  const history = useHistory();
  const { t } = useTranslation();

  const newClassDate = getGroupNextClassDate(
    lesson?.dateRange?.startDate,
    lesson?.dayOfTheWeek
  );
  return (
    <div
      className="bg-white flex flex-col w-full pr-6 pl-6 md:pl-0 py-6 md:py-0  md:shadow-none shadow-lg"
      // style={{ boxShadow: "0px 2px 15px rgb(0 0 0 / 15%)" }}
    >
      <p className="font-bold text-2xl md:text-4xl">{lesson?.title}</p>
      <div className="flex items-center">
        {lesson?.avgRating && (
          <>
            <p className="mr-1 mt-0.5" style={{ color: "red" }}>
              {parseFloat(lesson?.avgRating).toFixed(1)}
            </p>
            <StarRatings
              starRatedColor="red"
              starDimension={"15px"}
              starSpacing={"1px"}
              rating={lesson?.avgRating}
            />
            <p className="ml-2 mt-1 text-sm font-light">
              ({lesson?.reviews?.length + " " + t("general_ratings")})
            </p>
          </>
        )}

        {/* <p className="ml-2 mt-1 text-sm font-light">
          {lesson?.totalStudents + " " + t("general_students")}
        </p> */}
      </div>
      <div className="flex mt-2">
        <p className="text-sm font-light capitalize">
          {`${t("lesson_detail_type")}: `}{" "}
          <span className="font-bold">{lesson?.type}</span>
        </p>
        <p className="text-sm font-light ml-2 capitalize">
          {`${t("lesson_detail_delivery")}:`}{" "}
          <span className="font-bold"> {lesson?.classType}</span>
        </p>
      </div>
      <p className="font-light pt-2">
        {t("lesson_detail_created_by")}:
        <span
          className="underline cursor-pointer capitalize ml-1"
          onClick={() => {
            const teacherId = lesson?.teacherId?._id;
            history.push("/teacherPublicProfile/" + teacherId);
          }}
        >
          {" " + lesson?.teacherId?.name + " " + lesson?.teacherId?.surname}
        </span>
      </p>
      <div className="flex items-center pt-2">
        <div className="w-4">
          <Icon type="calendarIcon" />
        </div>
        <p className="font-light pl-1">
          {`${t("lesson_detail_last_updated")}: ${
            lesson?.updatedAt && formatDate(lesson?.updatedAt)
          }`}
        </p>
        <div className="ml-2 w-4">
          <Icon type="languageIcon" />
        </div>
        <p className="font-light pl-1">{lesson.language}</p>
      </div>
      {lesson?.type === "group" && (
        <div className="flex items-center pt-2">
          <div className="w-4">
            <Icon type="calendarIcon" />
          </div>
          <p className="font-light pl-1">
            {`${t("lesson_detail_next_lesson")}: ${newClassDate} - ${
              lesson?.startTime
            }`}
          </p>
        </div>
      )}
    </div>
  );
}

export default LessonTitleCard;
