import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Hidden } from "@material-ui/core";
import { useParams } from "react-router-dom";

import { getTeacherPublicProfileDetails } from "@store/auth/AuthActions";
import {
  Footer,
  HeaderContainer,
  LessonCard,
  Loader,
  WhatsappLink,
} from "@components";
import TeacherInfo from "./components/TeacherInfo";
import ProfileInfo from "./components/ProfileInfo";
import { pageAnalytics } from "@helpers/utils";

function TeacherPublicProfile(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { teacherId } = useParams();

  const { teacherProfile, loading } = useSelector(
    ({ EnglishCafe }) => EnglishCafe.auth
  );

  useEffect(() => {
    dispatch(getTeacherPublicProfileDetails(teacherId));
  }, [dispatch, teacherId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [teacherId]);

  useEffect(() => {
    pageAnalytics(window);
  }, []);

  return (
    <>
      <Loader loading={loading} />
      <HeaderContainer />
      <div className="flex flex-col mt-10 px-6 md:px-40">
        <Hidden only={["md", "lg", "xl"]}>
          <ProfileInfo teacherProfile={teacherProfile} />
        </Hidden>
        <div className="flex justify-between w-full">
          <TeacherInfo teacherProfile={teacherProfile} />
          <Hidden smDown>
            <ProfileInfo teacherProfile={teacherProfile} />
          </Hidden>
        </div>
        <div className="flex flex-col mt-6 md:mt-20">
          <p className="font-bold text-center">
            {t("teacher_profile_lessons_from_teacher")}
          </p>
          <div className="grid md:grid-cols-3 grid-cols-1 gap-2 md:mt-8">
            {teacherProfile?.lessons?.length > 0 &&
              teacherProfile?.lessons.map((lesson, index) => {
                return (
                  <LessonCard
                    key={lesson._id}
                    classNames="mt-4 md:mr-6 md:mt-10 md:mr-3"
                    lesson={lesson}
                    isTeacherProfile={true}
                  />
                );
              })}
          </div>
        </div>
      </div>
      <div className="mt-10">
        <WhatsappLink />
        <Footer />
      </div>
    </>
  );
}

export default TeacherPublicProfile;
