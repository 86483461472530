import { HeaderActionTypes } from "../redux/actionTypes";

export const setActiveTab = (activeTab) => {
  return (dispatch) => {
    dispatch({
      type: HeaderActionTypes.SET_ACTIVE_TAB,
      payload: activeTab,
    });
  };
};
